import React, { Component } from "react";
import { API } from "aws-amplify";
import 'rc-slider/assets/index.css';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import "./PaymentTablePanel.css";
import {FormatNumber, GetCurrentYear} from '../utils';
import {Button} from "react-bootstrap";
import OverlayLoadingComponent from "../components/OverlayLoadingComponent";
import ErrorAlert from '../components/ErrorAlert'
import InformationAlert from '../components/InformationAlert'

export default class PaymentTablePanel extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          credit: props.credit,
          period: props.period,
          price: props.price,
          premium: props.premium,
          year: props.year,
          chargesList: props.chargesList,
          realAmount: props.realAmount,
          fixedInsurance: props.fixedInsurance,
          insuranceAmount: props.insuranceAmount,
          renderPaymentTable: this.props.renderPaymentTable,
          paymentstable : [],
          showInformationAlert : false,
          showErrorAlert : false
        };
      }


    renderTableHandler(event){
      this.setState({renderPaymentTable:true,showInformationAlert:false,showErrorAlert:false});
    }

    async sendQuote(event){
      let localFormFields = {};
      localFormFields = this.props.parentHandle.state.formFields;
      //console.log(localFormFields);
      localFormFields["paymentsTable"] = this.transformPaymentTable(this.state.paymentstable);
      
      var myJSON = JSON.stringify(localFormFields);
      console.log(myJSON);

      let sendQuoteInit = {
        body: localFormFields, // replace this with attributes you need
        headers: {} // OPTIONAL
      }
      let response = null;
      this.setState({isLoading:true});
      try {
          response = await API.post("alticaapi", "/quotes", sendQuoteInit);
          console.log(response);
          this.setState({showInformationAlert:true, description: "La cotización fue enviada con éxito al sistema de Salesforce"});
      } catch (e) {
          console.log(e);
          this.setState({showErrorAlert:true, errorDescription: "Error al enviar la cotización a Salesforce. Contacte a su administrador."});
      }
      this.setState({isLoading:false});
    }

    canCalculatePaymentTable(){
      return undefined !== this.props.credit &&
            null !== this.props.credit &&
            '' !== this.props.credit && 
            undefined !== this.props.period &&
            null !== this.props.period &&
            '' !== this.props.period &&
            undefined !== this.props.realAmount &&
            null !== this.props.realAmount &&
            '' !== this.props.realAmount &&
            0 !== this.props.realAmount
    }

    async componentDidMount() {
      if(this.state.renderPaymentTable){
        try {
          this.setState({isLoading : true, distributions: []});
          const result = await this.queryPaymentTable(
            this.props.credit, 
            this.props.period, 
            this.props.realAmount,
            this.props.premium,
            this.props.price,GetCurrentYear() - this.props.year
            );
          this.setState({ paymentstable: result });
        } catch (e) {
          alert(e);
        }
        this.setState({ isLoading: false });
      }
    }

      async shouldComponentUpdate(){
        return this.state.renderPaymentTable;
      }

      async componentDidUpdate(prevProps, prevState) {
        if(this.state.renderPaymentTable){
          if((this.state.renderPaymentTable === prevState.renderPaymentTable && this.state.renderPaymentTable)
              && (this.props.credit !== prevProps.credit 
                || this.props.period !== prevProps.period 
                || this.props.realAmount !== prevProps.realAmount 
                || this.props.chargesList !== prevProps.chargesList)) {
            this.setState({renderPaymentTable: false})
          } 
          else{
            if(this.state.renderPaymentTable !== prevState.renderPaymentTable) {
              
              this.setState({
                credit: this.props.credit, 
                period: this.props.period, 
                realAmount: this.props.realAmount, 
                chargesList: this.props.chargesList, 
                paymentstable: [],
                premium: this.props.premium,
                price: this.props.price,
                year: this.props.year
              });
              const result = await this.queryPaymentTable(
                this.props.credit, 
                this.props.period, 
                this.props.realAmount, 
                this.props.fixedInsurance, 
                this.props.insuranceAmount,
                this.props.premium,
                this.props.price,
                GetCurrentYear() - this.props.year
               );
              this.setState({paymentstable: result})
            }
          }
        }
      } 

      async queryPaymentTable(credit, period, realAmount,fixedInsurance, insuranceAmount,premium,price,age) {
        this.setState({isLoading : true});
        
        
        if(undefined !== credit 
          && "NOTSELECTED" !== credit && undefined !== period 
          && undefined !== realAmount && fixedInsurance !== undefined
          && premium !== undefined && price !== undefined && age !== undefined) {
            let response = null;
            if(fixedInsurance === 0) {
              insuranceAmount = 0;
            }
            let params = credit.concat("/")
                                .concat(period)
                                .concat("/")
                                .concat(realAmount)
                                .concat("/")
                                .concat(premium)
                                .concat("/")
                                .concat(price)
                                .concat("/")
                                .concat(age)
                                .concat("/")
                                .concat(insuranceAmount);

                                
            response = await API.get("alticaapi", "/paymentstable/".concat(params));
        
            var data = [];
            for(let key in response){
              data.push(response[key]);
            }
            this.setState({isLoading : false});
            return response;
        }
        else {
          this.setState({isLoading : false});
          return [];
        }
      }


      getRuntimeColumns(){
        let columsObject = [];

        for (let charge in this.state.chargesList){
          let object = {};
          let chargeObject = this.state.chargesList[charge];
          if(chargeObject.paymentTypeId !== 1 && chargeObject.paymentTypeId !== 2){
            object["Header"] = chargeObject.charge.description;
            object["accesor"] = 'charges'
            object["Cell"] = row => {
              if(undefined !== row.row.charges){
                let value = FormatNumber(eval("row.row.charges.".concat(chargeObject.charge.description)));
                value = isNaN(value) ? '-' : value;
                return (<div style={{ textAlign: "center" }}>{value}</div>)
              }
              else{
                return(
                <div style={{ textAlign: "center" }}>0</div>
                )
              }

            };

            columsObject.push(object);
          }
        }
        return columsObject;
      }

      getSeguro(){
        let columsObject = [];
        if(this.props.fixedInsurance === 1){
         
          let object = {};
          object["Header"] = "Seguro";
          object["accesor"] = 'Seguro'
          object["Cell"] = row => {
            if(undefined !== row.row.charges){
              let value = FormatNumber(eval("row.row.charges.Seguro"));
              value = isNaN(value) ? '-' : value;
              return (<div style={{ textAlign: "center" }}>{value}</div>)
            }
            else{
              return(
                <div style={{ textAlign: "center" }}>0</div>
              )
            }
          }

          columsObject.push(object);

        }
        return columsObject;


      }

      renderTableRows(data, columns){
      return  (undefined !== data && data.length > 0)?
                    <ReactTable
                    data={data}
                    columns={columns}
                    showPagination={true}
                    defaultPageSize={12}
                  />
                  :
                  <div>No se ha obtenido la información de la tabla de pagos</div>
      }

      transformPaymentTable(paymentstable){
        var data = [];
        let counter = 1;
        for(let key in this.state.paymentstable){
          let tempPayment = this.state.paymentstable[key];
          tempPayment["index"] = counter; 
          //data.push(this.state.paymentstable[key]);
          data.push(tempPayment);
          counter = counter + 1;
        }
        return data;
      }

      renderErrorAlert(){
          if(this.state.showErrorAlert){
              return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
          }
          return '';
      }

      renderInformationAlert(){
        if(this.state.showInformationAlert){
            return <InformationAlert showInformationAlert={this.state.showInformationAlert} description={this.state.description}></InformationAlert>;
        }
        return '';
      }

      

      render(){
        var data = this.transformPaymentTable(this.state.paymentstable)
        
          const columns = [{
            Header: 'Mes',
            accessor: 'index',
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          },{
            Header: 'Cuota',
            accessor: 'amount',
            Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
          }, {
            Header: 'Intereses',
            accessor: 'interest',
            Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
          }, {
            Header: 'Amortización',
            accessor: 'principal',
            Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
            }, {
              Header: 'Cargos',
              accessor: 'charges',
              Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>,
              show:false
            }].concat(this.getRuntimeColumns()).concat(this.getSeguro()).concat([
            {
              Header: 'Impuestos',
              accessor: 'taxes',
              Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>,
              
            },
            {
              Header: 'Cuota Total',
              accessor: 'totalAmount',
              Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
            },
            {
              Header: 'Balance',
              accessor: 'balance',
              Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
            }]
          );
          if(this.state.renderPaymentTable){
            return (

              <OverlayLoadingComponent isLoading={this.state.isLoading}>
                {this.renderErrorAlert()}
                {this.renderInformationAlert()}
                <div style={{ textAlign: "center" }}>
                  <Button onClick={this.sendQuote.bind(this)} disabled={!this.canCalculatePaymentTable()}>
                    Enviar cotización
                  </Button>
                </div>
                <hr/>
                {this.renderTableRows(data, columns)}
              </OverlayLoadingComponent>
            );
          }
          else{
            return <div style={{ textAlign: "center" }}>
                <Button onClick={this.renderTableHandler.bind(this)} disabled={!this.canCalculatePaymentTable()}>
                  Generar tabla de pagos
                </Button>
              </div>
          }
      }
}