import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./AssetConditions.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'
import {AddInvalidInput, IsThereAnInvalidInput, ValidateInput, ValidateStringIdentifier} from '../utils'

const validationsArray = {
    AssetConditionsFormName: ValidateStringIdentifier
}

export default class AssetConditions extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedAssetConditionId: null,
        assetConditionName: '',
        isEnabled: false,
        errorDescription: '',
        showErrorAlert: false,
        assetConditions: [],
        validations: []
      };

      AddInvalidInput("AssetConditionsFormName", this.state.validations, this);
    }

    //================================= Lógica de negocio =========================================

    handleAssetConditionNameChange(event){
        ValidateInput("AssetConditionsFormName", event.target.value,validationsArray, this.state.validations, this);
        this.setState({assetConditionName:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreateAssetCondition(event){
        this.createAssetCondition(this.state.assetConditionName);
        ValidateInput("AssetConditionsFormName", event.target.value,validationsArray, this.state.validations, this);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdateAssetCondition(event){
        this.updateAssetCondition(this.state.selectedAssetConditionId, this.state.assetConditionName);
        ValidateInput("AssetConditionsFormName", event.target.value,validationsArray, this.state.validations, this);
    }

    handleDeleteAssetCondition(event){
        this.deleteAssetCondition(this.state.selectedAssetConditionId);
        ValidateInput("AssetConditionsFormName", event.target.value,validationsArray, this.state.validations, this);
    }

    async refreshAssetConditions() {
        this.queryAssetConditions();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryAssetConditions();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                assetConditionName: '',
                selectedAssetConditionId: null
              });
              AddInvalidInput("AssetConditionsFormName", this.state.validations, this);
        }
        else{
        this.setState({
            selected: rowInfo.index,
            assetConditionName: rowInfo.row._original.assetConditionDescription,
            selectedAssetConditionId: rowInfo.row._original.assetConditionId
          });
          ValidateInput("AssetConditionsFormName", rowInfo.row._original.assetConditionDescription,validationsArray, this.state.validations, this);
        }
    }

    //================================= CRUD =========================================
    async queryAssetConditions() {
        try {
            this.setState({isLoading : true});
            const assetConditions = await API.get("alticaapi", "/assetcondition");
            this.setState({ assetConditions: assetConditions });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createAssetCondition(assetConditionName) {
        let assetConditionInit = {
            body: {"name":assetConditionName}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/assetcondition", assetConditionInit);
            this.setState({isLoading:false, assetConditionName:''});
            this.refreshAssetConditions();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updateAssetCondition(assetConditionId, assetConditionName) {
        let assetConditionInit = {
            body: {"assetcondition":assetConditionId, "name":assetConditionName}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/assetcondition", assetConditionInit);
            this.setState({isLoading:false, assetContidionName:'',selectedAssetConditionId: null, selected: null});
            this.refreshAssetConditions();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deleteAssetCondition(assetConditionId) {
        let assetConditionInit = {
            body: {"assetcondition":assetConditionId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/assetcondition", assetConditionInit);
            this.setState({isLoading:false, assetContidionName:'', selectedAssetConditionId: null, selected: null});
            this.refreshAssetConditions();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de rendering =========================================
    renderAssetConditionsTable(assetConditions){
        const columns = [{
            id: 'Identificador',
            Header: 'Identificador',
            accessor: 'assetConditionId'
          }, {
            id: 'AssetConditionName',
            Header: 'Descripción del estado del Activo',
            accessor: 'assetConditionDescription'
          }
        ];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== assetConditions) && (assetConditions.length > 0)?
                <ReactTable
                data={assetConditions}
                columns={columns}
                showPagination={true}
                defaultPageSize={5}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron estados de activos en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedAssetConditionId && undefined !== this.state.selectedAssetConditionId){
            return <Col sm={12} smOffset={4}>
                <Button name="updateButton" onClick={this.handleUpdateAssetCondition.bind(this)} disabled={IsThereAnInvalidInput(this.state.validations)}>Actualizar Estado</Button>
                <Button name="deleteButton" onClick={this.handleDeleteAssetCondition.bind(this)}>Eliminar Estado</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreateAssetCondition.bind(this)} disabled={IsThereAnInvalidInput(this.state.validations)}>Crear estado de activo</Button>
                </Col>;
        }
    }

    renderWarningMessage(){
        if(null !== this.state.selectedAssetConditionId && undefined !== this.state.selectedAssetConditionId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina un estado de activo se eliminarán todas las configuraciones asociadas al mismo en todas las campañas.</p>
        </div>
        }
        
    }

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }
    //================================= Métodos de validación ========================================

    validationResult(inputName){
        if(this.state.validations.indexOf(inputName) >= 0){
            return "error";
        }
        return "success";
    }
    renderAssetConditionsForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de Estado de Activos</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup controlId="AssetConditionsFormName"  validationState={this.validationResult("AssetConditionsFormName")}>
                                <Col sm={11}>
                                    <ControlLabel>Estado del activo</ControlLabel>
                                </Col>
                                <Col sm={11}>
                                    <FormControl type="text" placeholder="Estado" value={this.state.assetConditionName} onChange={this.handleAssetConditionNameChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <hr></hr>
                            <FormGroup controlId="AssetConditionsFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Estados de activos existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderAssetConditionsTable(this.state.assetConditions)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderAssetConditionsForm();
    }
}