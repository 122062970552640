import React, { Component } from "react";
import { API } from "aws-amplify";
import 'rc-slider/assets/index.css';
import ReactLoading from 'react-loading';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import CreditChargesFinance from '../components/CreditChargesFinance'
import {RenderPercentageValue, FormatNumber} from '../utils';
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';
import Tooltip from 'react-tooltip-lite';


export default class CreditCharges extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          credit: props.credit,
          period: props.period,
          price: props.price,
          premium: props.premium,
          charges : [],
          financedCharges : [],
          parentHandle: props.parentHandle
        };
      }




    async componentDidMount() {
      try {
        this.setState({isLoading : true});
        this.setState({ charges: [] });
        const result = await this.queryCreditCharges(this.props.credit, this.props.period, this.props.price, this.props.premium);
        this.props.chargesListHandler(result);
        this.setState({ charges: result });
      } catch (e) {
        alert(e);
      }
      this.setState({ isLoading: false });
    }


      async componentDidUpdate(prevProps) {
          if(this.props.credit !== prevProps.credit || this.props.period !== prevProps.period || this.props.price !== prevProps.price || this.props.premium !== prevProps.premium) {
            this.setState({credit: this.props.credit, period: this.props.period, price: this.props.price, premium: this.props.premium})
            this.setState({charges: []})
            const result = await this.queryCreditCharges(this.props.credit, this.props.period, this.props.price, this.props.premium);
            this.props.chargesListHandler(result);
            this.setState({charges: result})
          }
      } 

      async queryCreditCharges(credit, period, price, premium) {
        AddLoadingComponent(this.state.parentHandle, 'creditcharges');
        this.setState({isLoading : true});
        
        if(undefined !== credit && "NOTSELECTED" !== credit && undefined !== period && undefined !== price && undefined !== premium) {
           const response = await API.get("alticaapi", "/charges/" + credit + "/" + period + "/" + (Number(price) - (Number(price) * (premium/100))));
           
           response.forEach(async element => {
            const creditChargesFinance = await API.get("alticaapi", "/chargesfinancing/" + element.chargeId);
            if(null !== creditChargesFinance && undefined !== creditChargesFinance){
               element["chargesFinancing"] = creditChargesFinance;
            }
           });

           let localFormFields = this.props.parentHandle.state.formFields;
           localFormFields["creditCharges"] = response;
           this.props.parentHandle.setState({formFields: localFormFields});

           this.setState({isLoading : false});
           RemoveLoadingComponent(this.state.parentHandle, 'creditcharges');
           return response;
        }
        else {
          this.setState({isLoading : false});
          RemoveLoadingComponent(this.state.parentHandle, 'creditcharges');
          return [];
        }
        
      }

    handleFinanceCharge(chargeId, selected){
      if(null !== chargeId && null !== selected && undefined !== chargeId && undefined !== selected && "" !== chargeId && "" !== selected){
        let localFinancedCharges = this.state.financedCharges;
        localFinancedCharges[chargeId] = selected;
        this.setState({financedCharges : localFinancedCharges});
      }
      //this.setState({chargeId});
    }


     getFinancedChargeStatus(chargeId){
       return this.state.financedCharges[chargeId];
     }

      render(){
          const columns = [{
            id: 'ChargeDescription',
            Header: 'Cargo',
            accessor: d => d.charge.description
          }, {
            id: 'Taxes',
            Header: 'Impuestos',
            accessor: d => d.charge.taxes,
            Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
          },  {
            Header: 'Porcentaje',
            accessor: 'creditPercentage',
            Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
          },  {
            Header: 'Monto',
            accessor: 'amount',
            Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
            
          },  {
            Header: 'Tipo de Cargo',
            accessor: 'paymentTypeDescription',
            Cell: row => {
              
              return (
                <div style={{ textAlign: "center" }}>
                      <Tooltip content={ row.row.paymentTypeDescription}>
                        <span className="class-for-name">{row.row.paymentTypeDescription}</span>
                      </Tooltip>
                </div>    
              )
            }
            
          }, {
            Header: 'Distribución',
            accessor: 'chargeId',
            Cell: props => {
                          return <CreditChargesFinance id={props.row._index} chargeId={props.value} 
                                selected={this.state.financedCharges[props.value]} 
                                handler={this.handleFinanceCharge.bind(this)}
                                parentHandle={this.state.parentHandle}
                                charge={props.row._original}>    
                           </CreditChargesFinance>}

            //<a onClick={this.showMessage.bind(this,props.value)}>Financiar</a> // Custom cell components!
          }];
        
          return (
            (this.state.isLoading)?
              <ReactLoading type="bars" color="grey" height={30} width={100} />
            :
            (undefined !== this.state.charges) && (this.state.charges.length > 0)?
                <ReactTable
                data={this.state.charges}
                columns={columns}
                showPagination={false}
                defaultPageSize={this.state.charges.length}
              />
              :
              <div>No hay cargos adicionales para los valores seleccionados</div>
          );
      }
}