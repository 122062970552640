import React, { Component } from "react";
import { FormControl} from "react-bootstrap";
import { API } from "aws-amplify";
import "./ComboCurrencies.css";
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';


export default class ComboCurrencies extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          company: props.companyId,
          assetId: props.assetId,
          assetCondition: props.assetCondition,
          product: props.product, 
          currencies: [], 
          parentHandle: props.parentHandle
        };
      }

      handleChange (e) {
        this.props.handler(e);
        let index = e.nativeEvent.target.selectedIndex;
        let currencyVal = e.nativeEvent.target[index].text;
        this.props.currencyHandler(currencyVal);
    }

      async componentDidUpdate(prevProps) {
          if((this.props.companyId !== prevProps.companyId) || (this.props.assetId !== prevProps.assetId) || (this.props.assetCondition !== prevProps.assetCondition) || (this.props.product !== prevProps.product)) {
            AddLoadingComponent(this.state.parentHandle, 'currencies');
            this.setState({company: this.props.companyId, assetId: this.props.assetId, assetCondition: this.props.assetCondition, product: this.props.product})
            const result = await this.queryCurrencies(this.props.companyId, this.props.assetId, this.props.assetCondition, this.props.product);
            this.setState({currencies: result})
            RemoveLoadingComponent(this.state.parentHandle, 'currencies');
          }
      } 

      async queryCurrencies(companyId, assetId, assetCondition, product) {
        AddLoadingComponent(this.state.parentHandle, 'currencies');
        this.setState({isLoading : true});
        if(undefined !== companyId && undefined !== assetId && undefined !== assetCondition && undefined !== product) {
           const response = await API.get("alticaapi", "/currencies/" + companyId + "/" + assetId + "/" + assetCondition + "/" + product);
           this.setState({isLoading : false});
           RemoveLoadingComponent(this.state.parentHandle, 'currencies');
           return response;
        }
        else {
          RemoveLoadingComponent(this.state.parentHandle, 'currencies');
          return [];
        }
        
        
      }

      renderCurrencies(currencies) {
        if(this.state.isLoading){
            return <option value="">Cargando datos ...</option>;
        }
        else{
            return [{}].concat(currencies).map(
            (currency, i) =>
            i !== 0 ?  
            <option value={currency.currencyId}>{currency.currencyName}</option>
            :
            <option value="">Moneda</option>
            );
        }
      }

      render(){
          return (
                
                <FormControl
                    name="currency" 
                    inputRef={ el => this.inputEl=el }
                    componentClass="select" placeholder="select" onChange={this.handleChange.bind(this)}>
                    {this.renderCurrencies(this.state.currencies)}
                </FormControl>
                
          );
      }
}