import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button,InputGroup} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./Charges.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'
import {RenderPercentageValue} from '../utils';


export default class Charges extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedChargeId: null,
        chargeName: '',
        taxes: 0,
        isEnabled: false,
        errorDescription: '',
        showErrorAlert: false,
        charges: []
      };
    }

    //================================= Lógica de negocio =========================================

    handleChargeNameChange(event){
        this.setState({chargeName:event.target.value});
    }

    handleChargeTaxesChange(event){
        this.setState({taxes:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreateCharge(event){
        this.createCharge(this.state.chargeName, this.state.taxes);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdateCharge(event){
        this.updateCharge(this.state.selectedChargeId, this.state.chargeName, this.state.taxes);
    }

    handleDeleteCharge(event){
        this.deleteCharge(this.state.selectedChargeId);
    }

    async refreshCharges() {
        this.queryCharges();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryCharges();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                chargeName: '',
                taxes: 0,
                selectedChargeId: null
              });
        }
        else{
        this.setState({
            selected: rowInfo.index,
            chargeName: rowInfo.row._original.description,
            taxes: rowInfo.row._original.taxes,
            selectedChargeId: rowInfo.row._original.id
          });
        }
    }

    //================================= CRUD =========================================
    async queryCharges() {
        try {
            this.setState({isLoading : true});
            const charges = await API.get("alticaapi", "/charges");
            this.setState({ charges: charges });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createCharge(chargeName, taxes) {
        let chargeInit = {
            body: {"name":chargeName, "tax": taxes}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/charges", chargeInit);
            this.setState({isLoading:false, chargeName:'', taxes: 0});
            this.refreshCharges();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updateCharge(chargeId, chargeName, taxes) {
        let chargesInit = {
            body: {"charge":chargeId, "name":chargeName, "tax": taxes}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/charges", chargesInit);
            this.setState({isLoading:false, chargeName:'',selectedChargeId: null, taxes: 0, selected: null});
            this.refreshCharges();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deleteCharge(chargeId) {
        let chargesInit = {
            body: {"charge":chargeId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/charges", chargesInit);
            this.setState({isLoading:false, chargeName:'', selectedChargeId: null, taxes: 0, selected: null});
            this.refreshCharges();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de rendering =========================================
    renderChargesTable(charges){
        const columns = [{
            id: 'Identificador',
            Header: 'Identificador',
            accessor: 'id'
          }, {
            id: 'ChargeName',
            Header: 'Cargo',
            accessor: 'description'
          }, {
            id: 'Taxes',
            Header: 'Impuestos',
            accessor: 'taxes',
            Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
          }
        ];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== charges) && (charges.length > 0)?
                <ReactTable
                data={charges}
                columns={columns}
                showPagination={true}
                defaultPageSize={5}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron cargos en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedChargeId && undefined !== this.state.selectedChargeId){
            return <Col sm={12} smOffset={4}>
                <Button name="updateButton" onClick={this.handleUpdateCharge.bind(this)}>Actualizar Cargo</Button>
                <Button name="deleteButton" onClick={this.handleDeleteCharge.bind(this)}>Eliminar Cargo</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreateCharge.bind(this)}>Crear Cargo</Button>
                </Col>;
        }
    }

    renderWarningMessage(){
        if(null !== this.state.selectedChargeId && undefined !== this.state.selectedChargeId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina un cargo se eliminarán todas las configuraciones asociadas al mismo en todas las campañas.</p>
        </div>
        }
        
    }

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }

    renderChargesForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de Cargos</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup controlId="ChargesFormName">
                                <Col sm={11}>
                                    <ControlLabel>Cargo</ControlLabel>
                                </Col>
                                <Col sm={11}>
                                    <FormControl type="text" placeholder="Cargo" x value={this.state.chargeName} onChange={this.handleChargeNameChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="ChargesFormTax">
                                <Col sm={11}>
                                    <ControlLabel>Valor del Impuesto (0-100)</ControlLabel>
                                </Col>
                                <Col sm={11}>
                                    <InputGroup>
                                        <FormControl type="number" placeholder="Porcentaje de Impuesto" min="0" max="100" step="1" value={this.state.taxes} onChange={this.handleChargeTaxesChange.bind(this)} />
                                        <InputGroup.Addon>%</InputGroup.Addon>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                            <hr></hr>
                            <FormGroup controlId="ChargesFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Cargos existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderChargesTable(this.state.charges)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderChargesForm();
    }
}