import { Auth } from 'aws-amplify';

const awsmobile = {
  Auth: {
    // Amazon Cognito Region
    region: "us-east-1",

    // Amazon Cognito User Pool ID
    userPoolId: "us-east-1_H3gb7TEyo",

    // Amazon Cognito Web Client ID (26-char alphanumeric string)
    userPoolWebClientId: "2kqb5dl94gvhdmdiramp5i10cl"
  },
  API: {
    endpoints: [
      {
        name: "alticaapi",
        endpoint: "https://hdxnbov7s8.execute-api.us-east-1.amazonaws.com/staging",
        region: "us-east-1",
        custom_header: async () => { return { Authorization:`Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`} }
      },
    ]
  }
};

export default awsmobile;
