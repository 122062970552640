import Modal from 'react-responsive-modal';
import React, { Component } from "react";
import { ModalTitle, ModalHeader, ModalBody, ModalFooter, Button, Grid, Form, FormGroup, FormControl, Row, Col, ControlLabel, Panel, ButtonToolbar, ToggleButtonGroup, ToggleButton,OverlayTrigger, Popover,InputGroup} from "react-bootstrap";
import 'rc-slider/assets/index.css';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { API } from "aws-amplify";
import OverlayLoadingComponent from "../components/OverlayLoadingComponent"
import ChargeFinancing from "../components/ChargeFinancing"
import {FormatNumber, RenderPercentageValue} from '../utils';
import Tooltip from 'react-tooltip-lite';

const chargeTypeGenericHelp = "Seleccione un tipo de cargo para ver información...";
const chargeTypeHelp = [
  "Monto fijo o porcentaje del crédito que se le suma al monto de la prima.",
  "Monto fijo o porcentaje del crédito que se le suma al monto del crédito (su costo se diluye en el plazo del crédito).",
  "Se financia independientemente por un plazo e interés diferenciado. Require distribuir.",
  "Se calcula como un porcentaje mensual del saldo del crédito durante rangos de plazos establecidos. Require distribuir.",
  "Monto fijo durante rangos de plazos establecidos. Require distribuir.",
];

const columns = [
  {
    Header: 'Cargo',
    accessor: 'chargeId.description'
  },
  {
    Header: 'Impuestos',
    accessor: 'chargeId.taxes'
  },
  {
    Header: 'Tipo de cargo',
    accessor: 'chargesPaymentTypeId.chargesPaymentTypeDescription',
    Cell: row => {
      return (
        <div style={{ textAlign: "" }}>
              <Tooltip content={ row.row._original.chargesPaymentTypeId.chargesPaymentTypeDescription}>
                <span className="class-for-name">{row.row._original.chargesPaymentTypeId.chargesPaymentTypeDescription}</span>
              </Tooltip>
        </div>    
      )
    }},
  {
    Header: 'Porcentaje',
    accessor: 'creditPercentage',
    Cell: row => 
              <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
              
  },
  {
    Header: 'Monto Mínimo',
    accessor: 'minimumAmount',
    Cell: row => 
              <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
              
  },
  {
    Header: 'Monto',
    accessor: 'amount',
    Cell: row => 
    (undefined !== row.value)?
        <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div> 
        :
        <div style={{ textAlign: "center" }}>-</div>
  }
];


export default class PremiumsByCreditModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          show: props.show, 
          periods: props.periods, 
          premiumsByPeriod: [], 
          selectedCredit: props.selectedCredit,
          currency: props.currency,
          formFields:[],
          loadingComponents:[],
          selectedRow: null,
          selectedAge: null,
          selectedChargeType: 0,
          creditCharges: [],
          showFinance: false, 
          enableFinance: false,
          enableDelete: false,
          disabledAmount:false,
          disabledPercentage:false

        };
        this.state.formFields['percentage']='';
        this.state.formFields['amount']='';
        this.state.formFields['minimumAmount']='';
        this.changeInputEventHandler = this.changeInputEventHandler.bind(this);
        this.changePeriodEventHandler = this.changePeriodEventHandler.bind(this);
        this.handleCloseFinanceCharge = this.handleCloseFinanceCharge.bind(this);
        this.changeChargeTypeEventHandler = this.changeChargeTypeEventHandler.bind(this);
        this.changeAmountEventHandler = this.changeAmountEventHandler.bind(this);
        this.changeMinimumAmountEventHandler = this.changeMinimumAmountEventHandler.bind(this);
        this.changePercentageEventHandler = this.changePercentageEventHandler.bind(this);
      }

       

    async componentDidMount() {
      this.addLoadingComponent("charges");
      const result = await API.get("alticaapi", "/charges");
      this.setState({charges:result});
      this.removeLoadingComponent("charges");
    }


    async componentDidUpdate(prevProps, prevState) {

      if(undefined !== prevProps && prevProps.show !== this.props.show){
        if(undefined !== this.state.formFields){
          let localFormFields = this.state.formFields;
          localFormFields['period']='';
          this.setState({formFields:localFormFields});
        }

        this.setState({show:this.props.show});
        this.resetFormValues();

      }

      if(undefined !== prevState && prevState.periods !== this.props.periods){
        this.setState({periods:this.props.periods});
      }

      if(undefined !== prevState && prevState.selectedCredit !== this.props.selectedCredit){
        this.setState({selectedCredit:this.props.selectedCredit});
      }

    } 

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeInputEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = event.target.value;
      this.setState({formFields: formFieldsLocal});
    }

    changeAmountEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = event.target.value;
      formFieldsLocal['percentage'] = '';
      formFieldsLocal['minimumAmount'] = '';
      this.setState({formFields: formFieldsLocal});
    }

    changeMinimumAmountEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = event.target.value;
      formFieldsLocal['amount'] = '';
      this.setState({formFields: formFieldsLocal});
    }

    changePercentageEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = event.target.value;
      formFieldsLocal['amount'] = '';
      this.setState({formFields: formFieldsLocal});
    }

    changeChargeTypeEventHandler(event){
      
      this.setValuesAccordingToChargeType(event.target.value);

      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = event.target.value;
      this.setState({formFields: formFieldsLocal});
    }

    setValuesAccordingToChargeType(chargeTypeValue){
      if(4 === Number(chargeTypeValue) || 5 === Number(chargeTypeValue)){
        let localFormFields = this.state.formFields;
        localFormFields['amount']='';
        localFormFields['percentage']='';
        localFormFields['minimumAmount']='';
        this.setState({disabledAmount:true, disabledPercentage:true, formFields: localFormFields});
      }
      else{
        this.setState({disabledAmount:false});
        this.setState({disabledPercentage:false});
      }
    }

    async changePeriodEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal['period'] = event;
      this.setState({formFields: formFieldsLocal});
      let selectedPeriod = this.state.periods.find(obj => obj.periodId === event);
      await this.loadChargesByPeriod(this.state.selectedCredit, selectedPeriod);
    }

    setSelectedRow(rowInfo){
      let localFormFields = this.state.formFields;
      if(this.state.selectedRow === rowInfo.index){
        this.resetFormValues();
        this.setState({
          selectedRow: null,
          enableFinance: false,
          enableDelete: false,
          selectedChargeType: 0,
          formFields: localFormFields
        });
      }
      else{
          localFormFields['percentage'] = (undefined === rowInfo.row._original.creditPercentage)?'':rowInfo.row._original.creditPercentage;
          localFormFields['minimumAmount'] = (undefined === rowInfo.row._original.minimumAmount)?'':rowInfo.row._original.minimumAmount;
          localFormFields['amount'] = (undefined === rowInfo.row._original.amount)?'':rowInfo.row._original.amount;
          localFormFields['chargetype'] = rowInfo.row._original.chargesPaymentTypeId.chargesPaymentTypeId;
          localFormFields['charge'] = rowInfo.row._original.chargeId.id;
          localFormFields['creditChargesId'] = rowInfo.row._original.creditChargesId;

          let localEnableFinance = (1 !== localFormFields['chargetype'] && 2 !== localFormFields['chargetype']);
          this.setState({
              selectedRow: rowInfo.index,
              enableFinance: localEnableFinance,
              enableDelete: true,
              selectedChargeType: localFormFields['chargetype'],
              formFields: localFormFields
            });
            
          this.setValuesAccordingToChargeType(rowInfo.row._original.chargesPaymentTypeId.chargesPaymentTypeId);
      }
    }


      
    handleClose(){
      this.props.hideChargesByCreditModalHandler();
    }

    handleShow(){
      this.setState({show : true})
    }

    async handleFinanceCharge(){
      if(
        undefined !== this.state.formFields['creditChargesId'] && 
        '' !== this.state.formFields['creditChargesId'] &&
        null !== this.state.selectedRow){
        this.setState({showFinance:true});
      }
      else{
        alert("Debe seleccionar el cargo a financiar");
      }
    }

    async handleCloseFinanceCharge(){
      this.setState({showFinance:false});
    }

    getSelectedPeriod(){
      return this.state.periods.find(obj => obj.periodId === this.state.formFields['period']);
    }

    async handlePremiumsUpdate(){

      let post = {};

      post["campaign"] = this.state.selectedCredit;
      post["period"] = this.state.formFields['period'];
      post["charge"] = this.state.formFields['charge'];
      post["chargetype"] = this.state.formFields['chargetype'];
      if(undefined !== this.state.formFields['amount'] && '' !== this.state.formFields['amount']){
        post["amount"] = this.state.formFields['amount'];
      }
      if(undefined !== this.state.formFields['percentage'] && '' !== this.state.formFields['percentage']){
        post["creditpercentage"] = this.state.formFields['percentage'];
      }

      if(undefined !== this.state.formFields['minimumAmount'] && '' !== this.state.formFields['minimumAmount']){
        post["minimumamount"] = this.state.formFields['minimumAmount'];
      }

      let premiumInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{
        await API.post("alticaapi", "/creditcharges", premiumInit);
        let selectedPeriod = this.getSelectedPeriod();
        
        await this.loadChargesByPeriod(this.state.selectedCredit, selectedPeriod);

        this.resetFormValues();

      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      
    }

    resetFormValues(){
      let localFormFields = this.state.formFields;
      localFormFields['charge']='';
      localFormFields['chargetype']='';
      localFormFields['amount']='';
      localFormFields['percentage']='';
      localFormFields['minimumAmount']='';
      localFormFields['creditChargesId'] = '';

      this.setState({formFields:localFormFields, selectedRow:null});
    }

    async handlePremiumRemove(){
      let post = {};
      post["creditcharge"] = this.state.formFields['creditChargesId'];

      let premiumInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{

        await API.del("alticaapi", "/creditcharges", premiumInit);
        let selectedPeriod = this.state.periods.find(obj => obj.periodId === this.state.formFields['period']);
        await this.loadChargesByPeriod(this.state.selectedCredit, selectedPeriod);
        this.resetFormValues();

      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      
    }

    comparePeriod( a, b ) {
      if ( a.periodValue < b.periodValue ){
        return -1;
      }
      if ( a.periodValue > b.periodValue ){
        return 1;
      }
      if(a.periodValue === b.periodValue){
          if ( a.age < b.age ){
              return -1;
          }
          if ( a.age > b.age ){
              return 1;
          }
      }
      return 0;
    }

    async loadChargesByPeriod(selectedCredit, period){
      this.setState({premiumsByPeriod:[]});
      this.addLoadingComponent("creditcharges" + period.periodId);
      const result = await API.get("alticaapi", "/creditcharges/" + selectedCredit + "/" + period.periodId);

      if(undefined !== result){  
        this.setState({creditCharges: result});
      }
      this.removeLoadingComponent("creditcharges" + period.periodId);

  }

    addLoadingComponent(componentName){
      let localComponents = this.state.loadingComponents;
      localComponents.push(componentName);
      this.setState({loadingComponents:localComponents});
    }

    removeLoadingComponent(componentName){
        let localComponents = this.state.loadingComponents;
        let index = localComponents.indexOf(componentName);
        if (index > -1) {
            localComponents.splice(index, 1);
        }
        this.setState({loadingComponents:localComponents});
    }

    isAComponentLoading(){
        return this.state.loadingComponents.length > 0;
    }

    isLoading(component){
        return this.state.loadingComponents.indexOf(component) > -1;
    }

      renderPeriodsList(periods) {
        return [{}].concat(periods).map(
        (period, i) =>
        i !== 0 ?
            <option value={period.periodId}>{period.periodValue}</option>
        :
        <option value="">Seleccione el periodo</option>
        );
        
      }

      
      renderPeriodsButtons(periods) {
        return periods.map(
        (period, i) =>
                    <ToggleButton key={i} value={period.periodId}>{period.periodValue}</ToggleButton>
        );
        
      }

      renderChargesOptions(charges){
        if(undefined !== charges){
          if(this.isLoading("charges")){
            return <option value="">Cargando datos ...</option>;
          }  
          else{
              return [{}].concat(charges).map(
              (charge, i) =>
              i !== 0 ?
              <option key={i} value={charge.id}>{charge.description}</option>
              :
              <option key={0} value="">Seleccione el cargo</option>
              );
          }
        }
      }

     renderPopover(chargetype){
       if(undefined !== chargetype && chargetype > 0){
        return <div><Popover 
          id="popover-basic"
          placement="right"
          positionLeft={"71%"}
          positionTop={"28%"}
          title="Como aplica el cargo:">
          {chargeTypeHelp[chargetype-1]}         
        </Popover></div>
       }else return <div><Popover 
          id="popover-basic"
          placement="right"
          positionLeft={"71%"}
          positionTop={"28%"}
          title="¿Cómo aplica el cargo?:">
          {chargeTypeGenericHelp}         
     </Popover></div>
     }

    renderChargesFinancing(){

        
      

          if (this.state.isLoading){
            return <div style={{ textAlign: "center" }}>
              Cargando información ...
            </div>;
          }
          else{
            return <div style={{ textAlign: "center" }}>
              

              <Modal open={this.state.show} onClose={this.handleClose.bind(this)} center>
                <ChargeFinancing
                  show={this.state.showFinance}
                  handleCloseFinanceCharge={this.handleCloseFinanceCharge} 
                  periods={this.state.periods}
                  selectedPeriod={this.getSelectedPeriod()}
                  selectedCredit={this.state.selectedCredit}
                  creditChargeId = {this.state.formFields['creditChargesId']}
                  selectedChargeType = {this.state.formFields['chargetype']}
                  currency = {this.props.currency}
                  />
                <OverlayLoadingComponent isLoading={this.isAComponentLoading()}>
                <ModalHeader>
                  <ModalTitle>Configuración de Cargos</ModalTitle>
                </ModalHeader>

                <ModalBody>
                <Panel>
                  <Panel.Body>
                      <Form onSubmit={this.setRedirect}>
                          <FormGroup controlId="formControlsSelect">
                              <Grid>
                              <Row className="show-grid">

                                  <Col sm={7}>
                                    <FormGroup controlId="Plazo"> 
                                      <ControlLabel>Plazo</ControlLabel>
                                      <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="options" value={this.state.formFields['period']} onChange={this.changePeriodEventHandler} justified>
                                          {this.renderPeriodsButtons(this.state.periods)}
                                        </ToggleButtonGroup>
                                      </ButtonToolbar>
                                    </FormGroup> 
                                  </Col>
                                </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <FormGroup controlId="Antiguedad"> 
                                        <ControlLabel>Cargo</ControlLabel>
                                        <FormControl 
                                            inputRef={ el => this.inputEl=el }
                                            name="charge" 
                                            componentClass="select" 
                                            placeholder="select"
                                            value={this.state.formFields['charge']}
                                            onChange={this.changeInputEventHandler}>
                                              
                                              {this.renderChargesOptions(this.state.charges)}
                                              
                                        </FormControl>
                                      </FormGroup> 
                                    </Col>
                                 </Row>
                                 <Row className="show-grid">
                                    <Col sm={7}>
                                      <FormGroup controlId="Antiguedad"> 
                                        <ControlLabel>Tipo de Cargo</ControlLabel>
                                        <OverlayTrigger trigger={['focus','hover']} container={this} placement="right" overlay={this.renderPopover(this.state.formFields['chargetype'])}>
                                          <FormControl 
                                              inputRef={ el => this.inputEl=el }
                                              name="chargetype" 
                                              componentClass="select" 
                                              placeholder="select"
                                              value={this.state.formFields['chargetype']}
                                              onChange={this.changeChargeTypeEventHandler}>
                                                <option value="">Seleccione el tipo de cargo</option>
                                                <option value={1}>Cargado a la prima</option>
                                                <option value={2}>Cargado al monto del crédito</option>
                                                <option value={3}>Financiado independientemente</option>
                                                <option value={4}>Se paga un porcentaje mensual del monto del crédito</option>
                                                <option value={5}>Se paga un monto fijo por rangos del plazo</option>
                                                
                                                
                                          </FormControl>
                                        </OverlayTrigger>
                                       
                                      </FormGroup> 
                                    </Col>
                                    

                                    
                                 </Row>
                                 <Row className="show-grid">
                                    <Col sm={3}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Porcentaje del crédito</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="percentage" 
                                            type="text" 
                                            placeholder="Porcentaje"
                                            value={this.state.formFields['percentage']}
                                            onChange={this.changePercentageEventHandler}
                                            disabled={this.state.disabledPercentage}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                    <Col sm={1}>&nbsp;</Col>
                                    <Col sm={3}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Ó Monto Fijo</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="amount" 
                                            type="text" 
                                            placeholder="Monto"
                                            value={this.state.formFields['amount']}
                                            onChange={this.changeAmountEventHandler}
                                            disabled={this.state.disabledAmount}
                                            />
                                            <InputGroup.Addon>{this.props.currency}</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>

                                    
                                  </Row>
                                  <Row className="show-grid">
                                  <Col sm={3}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Monto Mínimo</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="minimumAmount" 
                                            type="text" 
                                            placeholder="Monto Mínimo"
                                            value={this.state.formFields['minimumAmount']}
                                            onChange={this.changeMinimumAmountEventHandler}
                                            disabled={this.state.disabledAmount}
                                            />
                                            <InputGroup.Addon>{this.props.currency}</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <hr/>
                                    </Col>
                                    
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <div style={{textAlign: 'center'}}>
                                        <Button onClick={this.handlePremiumsUpdate.bind(this)}>Agregar</Button>
                                        <Button onClick={this.handlePremiumRemove.bind(this)} disabled={!this.state.enableDelete}>Eliminar</Button>
                                        <Button onClick={this.handleFinanceCharge.bind(this)} disabled={!this.state.enableFinance}>Distribuir</Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">&nbsp;</Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <ReactTable
                                        //minRows={0}
                                        defaultPageSize={5}
                                        data={this.state.creditCharges}
                                        columns={columns}
                                        //pages={0}
                                        showPagination={true}
                                        getTrProps={(state, rowInfo) => {
                                          if (rowInfo && rowInfo.row) {
                                            return {
                                              onClick: (e) => {
                                                this.setSelectedRow(rowInfo);
                                              },
                                              style: {
                                                background: rowInfo.index === this.state.selectedRow ? '#00afec' : 'white',
                                                color: rowInfo.index === this.state.selectedRow ? 'white' : 'black'
                                              }
                                            }
                                          }else{
                                            return {}
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                              </Grid>
                          </FormGroup>
                      </Form>
                    </Panel.Body>
                  </Panel>
                </ModalBody>

                <ModalFooter>
                  <Button onClick={this.handleClose.bind(this)} variant="primary">Cerrar</Button>
                </ModalFooter>
                </OverlayLoadingComponent>
              </Modal>
            </div>;
          }
      }

      render(){
          return this.renderChargesFinancing();
      }
}