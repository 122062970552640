import React, { Component } from "react";
import { FormControl} from "react-bootstrap";
import { API } from "aws-amplify";
import "./ComboCurrencies.css";
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';


export default class ComboCredits extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          company: props.companyId,
          assetId: props.assetId,
          assetCondition: props.assetCondition,
          product: props.product, 
          currency: props.currency,
          credits: [],
          parentHandle: props.parentHandle
        };
      }

      handleChange (e) {
        this.props.handler(e);
    }

    async componentDidMount() {
      try {
        this.setState({isLoading : true});
        const result = await this.queryCredits(this.props.companyId, this.props.assetId, this.props.assetCondition, this.props.product, this.props.currency);
        this.setState({ credits: result });
      } catch (e) {
        alert(e);
      }
      this.setState({ isLoading: false });
    }


      async componentDidUpdate(prevProps) {
          if((this.props.companyId !== prevProps.companyId) || (this.props.assetId !== prevProps.assetId) || (this.props.assetCondition !== prevProps.assetCondition) || (this.props.product !== prevProps.product) || (this.props.currency !== prevProps.currency)) {
            this.setState({company: this.props.companyId, assetId: this.props.assetId, assetCondition: this.props.assetCondition, product: this.props.product, currency: this.props.currency})
            const result = await this.queryCredits(this.props.companyId, this.props.assetId, this.props.assetCondition, this.props.product, this.props.currency);
            this.setState({credits: result})
          }
      } 

      async queryCredits(companyId, assetId, assetCondition, product, currency) {
        AddLoadingComponent(this.state.parentHandle, 'credits');
        this.setState({isLoading : true});
        if(undefined !== companyId && undefined !== assetId && undefined !== assetCondition && undefined !== product && undefined !== currency) {
           const response = await API.get("alticaapi", "/credit/" + companyId + "/" + assetId + "/" + assetCondition + "/" + product + "/" + currency);
           this.setState({isLoading : false});
           RemoveLoadingComponent(this.state.parentHandle, 'credits');
           return response;
        }
        else {
          RemoveLoadingComponent(this.state.parentHandle, 'credits');
          return [];
        }
        
        
      }

      renderCredits(credits) {
        if(this.state.isLoading){
            return <option value="NOTSELECTED">Cargando datos ...</option>;
        }
        else{
            return [{}].concat(credits).map(
            (credit, i) =>
            i !== 0 ?  
            <option key={i} value={credit.creditConfigurationId}>{credit.creditConfigurationName}</option>
            :
            <option key={i} value="NOTSELECTED">Campaña</option>
            );
        }
      }

      render(){
          return (
                
                <FormControl
                    name="campaign" 
                    inputRef={ el => this.inputEl=el }
                    componentClass="select" placeholder="select" onChange={this.handleChange.bind(this)}>
                    {this.renderCredits(this.state.credits)}
                </FormControl>
                
          );
      }
}