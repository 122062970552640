import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./Assets.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'
import {AddInvalidInput, IsThereAnInvalidInput, ValidateInput, ValidateStringIdentifier} from '../utils'



const validationsArray = {
    assetName: ValidateStringIdentifier
}


export default class Assets extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedAssetId: null,
        assetName: '',
        enabled:'1',
        isEnabled: false,
        errorDescription: '',
        showErrorAlert: false,
        assets: [],
        validations: []
      };

      AddInvalidInput("assetName", this.state.validations, this);
    }

    //================================= Lógica de negocio =========================================

    renderAssetState(assetStateType){
        if("1" === assetStateType){
            return 'Habilitado';
        }
        if("0" === assetStateType){
            return 'Deshabilitado';
        }
        return 'No definido';
    }

    handleAssetNameChange(event){
        ValidateInput("assetName", event.target.value,validationsArray, this.state.validations, this);
        this.setState({assetName:event.target.value});
    }

    handleAssetStateChange(event){
        this.setState({enabled:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreateAsset(event){
        this.createAsset(this.state.assetName, this.state.enabled);
        ValidateInput("assetName", event.target.value,validationsArray, this.state.validations, this);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdateAsset(event){
        this.updateAsset(this.state.selectedAssetId, this.state.assetName, this.state.enabled);
        ValidateInput("assetName", event.target.value,validationsArray, this.state.validations, this);
    }

    handleDeleteAsset(event){
        this.deleteAsset(this.state.selectedAssetId);
        ValidateInput("assetName", event.target.value,validationsArray, this.state.validations, this);
    }

    async refreshAssets() {
        this.queryAssets();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryAssets();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                assetName: '',
                enabled: '',
                selectedAssetId: null
              });
              AddInvalidInput("assetName", this.state.validations, this);
        }
        else{
        this.setState({
            selected: rowInfo.index,
            assetName: rowInfo.row._original.assetName,
            enabled: rowInfo.row._original.enabled,
            selectedAssetId: rowInfo.row._original.assetId, 
            validations: []
          });
        }
    }

    //================================= CRUD =========================================
    async queryAssets() {
        try {
            this.setState({isLoading : true});
            const assets = await API.get("alticaapi", "/assets");
            this.setState({ assets });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createAsset(assetName, enabled) {
        let assetInit = {
            body: {"name":assetName, "enabled":enabled}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/assets", assetInit);
            this.setState({isLoading:false, assetName:'', enabled:''});
            this.refreshAssets();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updateAsset(assetId, assetName, enabled) {
        let assetInit = {
            body: {"asset":assetId, "name":assetName, "enabled":enabled}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/assets", assetInit);
            this.setState({isLoading:false, assetName:'', enabled:'',selectedAssetId: null, selected: null});
            this.refreshAssets();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deleteAsset(assetId) {
        let assetInit = {
            body: {"asset":assetId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/assets", assetInit);
            this.setState({isLoading:false, assetName:'', selectedAssetId: null, selected: null});
            this.refreshAssets();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de validación ========================================

    validationResult(inputName){
        if(this.state.validations.indexOf(inputName) >= 0){
            return "error";
        }
        return "success";
    }


    

    //================================= Métodos de rendering =========================================
    renderAssetsTable(assets){
        const columns = [{
            id: 'Identificador',
            Header: 'Identificador',
            accessor: 'assetId'
          }, {
            id: 'NombreCompania',
            Header: 'Nombre',
            accessor: 'assetName'
          }, {
            id: 'enabled',
            Header: 'Estado',
            accessor: 'enabled',
            Cell: row => <div style={{ textAlign: "center" }}>{this.renderAssetState(row.value)}</div>
          }
        ];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== assets) && (assets.length > 0)?
                <ReactTable
                data={assets}
                columns={columns}
                showPagination={true}
                defaultPageSize={10}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron compañías en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedAssetId && undefined !== this.state.selectedAssetId){
            return <Col sm={12} smOffset={4}>
                <Button name="updateButton" onClick={this.handleUpdateAsset.bind(this)} disabled={IsThereAnInvalidInput(this.state.validations)}>Actualizar activo</Button> 
                <Button name="deleteButton" onClick={this.handleDeleteAsset.bind(this)}>Eliminar activo</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreateAsset.bind(this)} disabled={IsThereAnInvalidInput(this.state.validations)}>Crear activo</Button>
                </Col>;
        }
    }

    renderWarningMessage(){
        if(null !== this.state.selectedAssetId && undefined !== this.state.selectedAssetId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina un activo se eliminarán todas las campañas asociadas al mismo.</p>
        </div>
        }
        
    }

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }

    renderAssetStatesOptions() {
        let assetStates = [
            {assetStateValue:1,assetStateDescription:"Habilitado"},
            {assetStateValue:0,assetStateDescription:"Deshabilitado"}]
        return [].concat(assetStates).map(
        (assetStateKey, i) => this.renderAssetStateOption(assetStateKey)
        );
        
      }

      renderAssetStateOption(assetStateKey){
        
        if(undefined !== this.state.enabled && assetStateKey.assetStateValue === this.state.enabled){
            return <option value={assetStateKey.assetStateValue} selected>{assetStateKey.assetStateDescription}</option>;
        }
        else{
            return <option value={assetStateKey.assetStateValue}>{assetStateKey.assetStateDescription}</option>;
        }
    }

    renderAssetsForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de Activos</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup controlId="assetsName" validationState={this.validationResult("assetName")}>
                                <Col sm={12}>
                                    <ControlLabel>Nombre</ControlLabel>
                                </Col>
                                <Col sm={12}>
                                    <FormControl type="text" placeholder="Activo" value={this.state.assetName} onChange={this.handleAssetNameChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <FormGroup controlId="assetFormState">
                                    <Col sm={12}>
                                        <ControlLabel>Estado</ControlLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FormControl
                                            name="enabled" 
                                            inputRef={ el => this.inputEl=el }
                                            componentClass="select" placeholder="select" onChange={this.handleAssetStateChange.bind(this)}
                                            value={this.state.enabled}>
                                            {this.renderAssetStatesOptions()}
                                            
                                        </FormControl> 
                                    </Col>
                                </FormGroup>

                            <hr></hr>
                            <FormGroup controlId="assetsFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Activos Existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderAssetsTable(this.state.assets)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderAssetsForm();
    }
}