import React from 'react'
import { Greetings,  NavBar, Nav, NavRight } from 'aws-amplify-react'
import { Auth } from 'aws-amplify';
import '../login.css'

class MyGreetings extends Greetings {
    constructor(props) {
        super(props);
    }

    signOut(){
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err));

        // By doing this, you are revoking all the auth tokens(id token, access token and refresh token)
        // which means the user is signed out from all the devices
        // Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
        Auth.signOut({ global: true })
            .then(data => console.log(data))
            .catch(err => console.log(err));
        
        
    }

    renderUserName(props){

        if(undefined !== props && undefined !== props.authData && undefined !== props.authData.attributes && undefined !== props.authData.attributes.name){
            return props.authData.attributes.name;
        }
        else {
            return "";
        }


    }

    


    render() {
        const authState  = this.props.authState || this.state.authState;
        const signedIn = (authState === 'signedIn');
        if (!signedIn) { return null; }

        return (
            <NavBar>
                <Nav>
                    <NavRight>
                    <span  style={{ 'color':'white', 'textAlign':'right', 'display': 'inline-block'}}>Bienvenid@ {this.renderUserName(this.props)} &nbsp;</span>
                        <span style={{ 'textAlign':'right', 'display': 'inline-block'}}>
                            <button
                                className="submitButton"
                                type="button"
                                onClick={() => this.signOut()}
                            >
                                Salir
                            </button>
                        </span>
                    </NavRight>
                </Nav>
            </NavBar>
        );
    }
}

export default MyGreetings;