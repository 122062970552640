import React, { Component } from 'react';
import './App.css';
import Amplify from 'aws-amplify';
import awsmobile from './aws-exports';


import { Link, Route} from "react-router-dom";
import { Nav, Navbar, NavItem, NavDropdown, MenuItem } from "react-bootstrap";
import Routes from "./Routes";
import { LinkContainer } from "react-router-bootstrap";



Amplify.configure(awsmobile);

class App extends Component {
  render() {
    if (this.props.authState === "signedIn") {

      return (
        <div className="App container">
          
          <Navbar fluid collapseOnSelect>
            <Navbar.Header>
              <Navbar.Brand>
                <Link to="/">Cotizador Altica</Link>
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
            <Nav pullRight>
              <LinkContainer to="/">
                <NavItem>Cotizar</NavItem>
              </LinkContainer>
              <NavDropdown eventKey={3} title="Mantenimiento de Parametros" id="basic-nav-dropdown">
                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/companies') }}>Compañías</MenuItem>
                  
                )} />
                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/assets') }}>Activos</MenuItem>
                )} />
                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/assetConditions') }}>Estado de Activos</MenuItem>
                )} />

                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/products') }}>Productos</MenuItem>
                )} />

                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/periods') }}>Periodos</MenuItem>
                )} />

                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/currencies') }}>Monedas</MenuItem>
                )} />

                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/charges') }}>Cargos</MenuItem>
                )} />

                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/baserates') }}>Tasas Base</MenuItem>
                )} />
                <MenuItem divider />
                <Route render={({ history}) => (
                  <MenuItem eventKey={3.2} onClick={() => { history.push('/campaignmaintenance') }}>Campañas</MenuItem>
                )} />
              </NavDropdown>
              
            </Nav>
          </Navbar.Collapse>
          
          </Navbar>
          <Routes />
        </div>
      );
    }
    else{
      return null;
    }
  }
}

export default App;
