import React from "react";
import { SignIn } from "aws-amplify-react";
import '../login.css'
import logo from '../logo-altica.png'

export class CustomSignIn extends SignIn {
  constructor(props) {
    super(props);
    this._validAuthStates = ["signIn", "signedOut", "signedUp"];
  }

  showComponent(theme) {
    return (
      <div className="main">
        <div className="loginContainer">
          <center>
          <div class="logo"><img src={logo} alt="Logo" width="100px"/>
  
              <div class="clearfix"></div>
          </div>
          <div className="middle">
                <div id="login">
                      <form>
                        <fieldset className="clearfix">
                          
                            <p>
                              <span className="fa fa-user"></span>
                              <input
                                id="username"
                                key="username"
                                name="username"
                                onChange={this.handleInputChange}
                                type="text"
                                placeholder="Username"
                              />
                            </p>
                            <p><span className="fa fa-lock"></span>
                              <input
                                id="password"
                                key="password"
                                name="password"
                                onChange={this.handleInputChange}
                                type="password"
                                placeholder="******************"
                              />
                            </p>

                            <div>
                            <span style={{'width':'55%', 'textAlign':'left', 'display': 'inline-block'}}>
                              <a
                                className="small-text"
                                onClick={
                                  () => super.changeState("forgotPassword")
                                }
                              >Olvidó su contraseña?</a>
                              </span>

                              <span style={{'width':'42%', 'textAlign':'right', 'display': 'inline-block'}}>
                                <button
                                  className="submitButton"
                                  type="button"
                                  onClick={() => super.signIn()}
                                >
                                  Login
                                </button>
                              </span>
                            </div>
                            
                        </fieldset>
                      </form>
                      <div class="clearfix"></div>
                  </div>
                  




                </div>
              </center>
          </div>
      </div>
    );
  }
}