import Modal from 'react-responsive-modal';
import React, { Component } from "react";
import { ModalTitle, ModalHeader, ModalBody, ModalFooter, Button, Grid, Form, FormGroup, FormControl, Row, Col, ControlLabel, Panel,InputGroup} from "react-bootstrap";
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { API } from "aws-amplify";
import OverlayLoadingComponent from "../components/OverlayLoadingComponent"
import {FormatNumber, RenderPercentageValue,RenderEmptyOrUndefinedValue} from '../utils';
import Slider from 'rc-slider';



const columns = [
  {
    Header: 'Mes inicial',
    accessor: 'initialMonth'
  },
  {
    Header: 'Mes Final',
    accessor: 'finalMonth'
  },
  {
    Header: 'Porcentaje',
    accessor: 'percentage',
    Cell: row => 
              <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
  },
  {
    Header: 'Monto Mínimo',
    accessor: 'minimumAmount',
    Cell: row => 
              <div style={{ textAlign: "center" }}>{RenderEmptyOrUndefinedValue(FormatNumber(row.value))}</div>
  },
  {
    Header: 'Interés',
    accessor: 'interest',
    Cell: row => 
              <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
              
  },
  {
    Header: 'Monto Fijo',
    accessor: 'fixedAmount',
    Cell: row => 
              <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
              
  }
];


export default class ChargeFinancing extends Component{

    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          show: props.show, 
          periods: props.periods, 
          selectedCredit: props.selectedCredit,
          formFields:[],
          loadingComponents:[],
          selectedRow: null,
          selectedAge: null,
          creditCharges: [],
          currency: props.currency,
          selectedPeriod: props.selectedPeriod,
          creditChargeId: props.creditChargeId,
          chargesFinancing: [], 
          enableDelete: false
        };
        this.state.formFields['percentage']=0;
        this.state.formFields['minimumAmount']=0;
        this.state.formFields['interest']=0;
        this.state.formFields['fixedAmount']=0;
        this.changeInputEventHandler = this.changeInputEventHandler.bind(this);
        this.changeSliderHandler = this.changeSliderHandler.bind(this);
        this.addFinancePeriod = this.addFinancePeriod.bind(this);
        this.removeFinancePeriod = this.removeFinancePeriod.bind(this);
      }

     
      changeSliderHandler(value){
        let localFormFields = this.state.formFields;
        localFormFields['periodValue'] = value;
        this.setState({formFields:localFormFields});
      }
    
      changeInputEventHandler(event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal[event.target.name] = event.target.value;
        this.setState({formFields: formFieldsLocal});
      }
      

    async addFinancePeriod (event) {
      this.addLoadingComponent("addFinancePeriod");
      let post = {};

      post["creditcharge"] = this.state.creditChargeId;
      post["initialmonth"] = this.getMaxMonthConfigured();
      post["finalmonth"] = this.state.formFields['periodValue'];
      post["percentage"] = this.state.formFields['percentage'];
      post["minimumamount"] = this.state.formFields['minimumAmount'];

      post["interest"] = this.state.formFields['interest'];
      post["fixedamount"] = this.state.formFields['fixedAmount'];


      let premiumInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{
        await API.post("alticaapi", "/chargesfinancing", premiumInit);
        
        let localFormFields = this.state.formFields;
        localFormFields['minimumAmount']=0;
        localFormFields['percentage']=0;
        localFormFields['interest']=0;
        localFormFields['fixedAmount']=0;

        this.queryChargeFinancing(this.state.creditChargeId);
        this.setState({formFields:localFormFields});

      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      this.removeLoadingComponent("addFinancePeriod");
    }

    async removeFinancePeriod(event){
      this.addLoadingComponent("removeFinancePeriod");
      let post = {};

      post["creditcharge"] = this.state.creditChargeId;
      post["initialmonth"] = this.state.formFields['selectedInitialMonth'];
      post["finalmonth"] = this.state.formFields['selectedFinalMonth'];


      let premiumInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{
        await API.del("alticaapi", "/chargesfinancing", premiumInit);
        
        let localFormFields = this.state.formFields;
        localFormFields['percentage']=0;
        localFormFields['minimumAmount']=0;
        localFormFields['interest']=0;
        localFormFields['fixedAmount']=0;
        localFormFields['selectedInitialMonth'] = '';
        localFormFields['selectedFinalMonth'] = '';

        this.queryChargeFinancing(this.state.creditChargeId);
        this.setState({formFields:localFormFields});

      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      this.removeLoadingComponent("removeFinancePeriod");
    }
      
    componentDidUpdate(prevProps){
      if(this.props.show !== prevProps.show){
        let localFormFields = this.state.formFields;
        localFormFields['percentage']=0;
        localFormFields['minimumAmount']=0;
        localFormFields['interest']=0;
        localFormFields['fixedAmount']=0;
        localFormFields['selectedInitialMonth'] = '';
        localFormFields['selectedFinalMonth'] = '';
        this.setState({show:this.props.show, selectedRow:null, formFields:localFormFields});
      }

      if(this.props.periods !== prevProps.periods){
        this.setState({periods:this.props.periods});
      }

      if(this.props.selectedPeriod !== prevProps.selectedPeriod){
        if(undefined !== this.state.formFields && this.props.selectedPeriod){
          let localFormFields = this.state.formFields;
          localFormFields['periodValue'] = this.props.selectedPeriod.periodValue;
          this.setState({formFields:localFormFields});
        }
        this.setState({selectedPeriod:this.props.selectedPeriod});
      }

      if(this.props.creditChargeId !== prevProps.creditChargeId){
        this.queryChargeFinancing(this.props.creditChargeId);
      }
    }

    async queryChargeFinancing(creditChargeId){
      this.addLoadingComponent("queryChargeFinancing");
      const chargesFinancing = await API.get("alticaapi", "/chargesfinancing/" + creditChargeId );
      this.setState({chargesFinancing, creditChargeId});
      this.removeLoadingComponent("queryChargeFinancing");
    }

    getMaxMonthConfigured(){
      let maxValue = 0;
      for(const chargeFinance of this.state.chargesFinancing){
        if(chargeFinance.finalMonth > maxValue){
          maxValue = chargeFinance.finalMonth;
        }
      }
      if(undefined !== this.state.selectedPeriod && maxValue === this.state.selectedPeriod.periodValue){
        return maxValue;
      }
      else{
        return maxValue + 1;
      }
      
    }

    getInitialPeriodForSlider(localMarks){
      let maxValue = this.getMaxMonthConfigured();
      let result = 0;
    
      let temporalOrderArray = [];
      
      Object.keys(localMarks).forEach(e => temporalOrderArray.push(localMarks[e]));

      temporalOrderArray.sort((a,b) => {return a - b});

      let actualIndex = temporalOrderArray.indexOf(maxValue);


      if(actualIndex < temporalOrderArray.length){
        result = temporalOrderArray[actualIndex + 1];
      }
      else{
        result = temporalOrderArray[actualIndex];
      }

      return Number(result);
    }


    renderSliderLabel(disableSlider){
      if(disableSlider){
        return 'Distribución del cargo completa';
      }else {
        let initialPeriod = this.state.formFields['initialPeriodValue'];
        if(undefined === initialPeriod || '' === initialPeriod){
          initialPeriod = this.getMaxMonthConfigured();
        }
        return initialPeriod + " - " + this.state.formFields['periodValue'];
      }
    }

    setSelectedRow(rowInfo){
      let localFormFields = this.state.formFields;
      if(this.state.selectedRow === rowInfo.index){
        localFormFields['selectedInitialMonth'] = '';
        localFormFields['selectedFinalMonth'] = '';
        localFormFields['percentage'] = 0;
        localFormFields['minimumAmount'] = 0;
        localFormFields['interest'] = 0;
        localFormFields['fixedAmount'] = 0;
        this.setState({
          selectedRow: null,
          enableDelete: false,
          formFields: localFormFields
        });
      }
      else{
          localFormFields['selectedInitialMonth'] = (undefined === rowInfo.row._original.initialMonth)?'':rowInfo.row._original.initialMonth;
          localFormFields['selectedFinalMonth'] = (undefined === rowInfo.row._original.finalMonth)?'':rowInfo.row._original.finalMonth;
          localFormFields['percentage'] = (undefined === rowInfo.row._original.percentage)?'':rowInfo.row._original.percentage;
          localFormFields['minimumAmount'] = (undefined === rowInfo.row._original.minimumAmount)?'':rowInfo.row._original.minimumAmount;
          localFormFields['interest'] = (undefined === rowInfo.row._original.interest)?'':rowInfo.row._original.interest;
          localFormFields['fixedAmount'] = (undefined === rowInfo.row._original.interest)?'':rowInfo.row._original.fixedAmount;
          
          this.setState({
              enableDelete: true,
              selectedRow: rowInfo.index,
              formFields: localFormFields
            });

          }
    }

    getSelectedPeriodValue(){
      return (undefined !== this.state.selectedPeriod)?this.state.selectedPeriod.periodValue:1;
    }

    addLoadingComponent(componentName){
      let localComponents = this.state.loadingComponents;
      localComponents.push(componentName);
      this.setState({loadingComponents:localComponents});
    }

    removeLoadingComponent(componentName){
        let localComponents = this.state.loadingComponents;
        let index = localComponents.indexOf(componentName);
        if (index > -1) {
            localComponents.splice(index, 1);
        }
        this.setState({loadingComponents:localComponents});
    }

    isAComponentLoading(){
        return this.state.loadingComponents.length > 0;
    }

    isLoading(component){
        return this.state.loadingComponents.indexOf(component) > -1;
    }
 
    renderChargesFinancing(){ 
      let disableSlider = false;
      let disableInsert = false;
      let localMarks = {};
      let localChargeType = this.props.selectedChargeType;
      const finalPeriod = this.getSelectedPeriodValue();
      if(undefined !== this.state.selectedPeriod && this.getMaxMonthConfigured() === this.state.selectedPeriod.periodValue){
        disableSlider = true;
        disableInsert = true;
      } else{
        localMarks[this.getMaxMonthConfigured() + 1] = this.getMaxMonthConfigured() + 1;
        localMarks[this.getSelectedPeriodValue()] = this.getSelectedPeriodValue();
      }

          return <Modal open={this.state.show} onClose={this.props.handleCloseFinanceCharge} center>
                <OverlayLoadingComponent isLoading={this.isAComponentLoading()}>
                <ModalHeader>
                  <ModalTitle>Distribución del Cargo</ModalTitle>
                </ModalHeader>

                <ModalBody>
                <Panel>
                  <Panel.Body>
                      <Form onSubmit={this.setRedirect}>
                          <FormGroup controlId="formControlsSelect">
                              <Grid>
                              <Row className="show-grid">
                               
                                <FormGroup controlId="Plazo"> 
                                
                                    <Col sm={7}> 
                                    <ControlLabel>
                                      Período en meses
                                    </ControlLabel>
                                      <div>
                                        {this.renderSliderLabel(disableSlider)}
                                      </div>
                                      <Slider 
                                        onChange={this.changeSliderHandler}
                                        min={this.getMaxMonthConfigured() + 1}
                                        max={finalPeriod}
                                        defaultValue={finalPeriod}
                                        disabled={disableSlider}
                                        marks={localMarks}/>
                                        
                                    </Col>
                                  </FormGroup> 
                                </Row>
                                &nbsp;
                                <Row className="show-grid">
                                    <Col sm={7}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Monto Fijo</ControlLabel>
                                          <InputGroup>
                                            <FormControl 
                                              name="fixedAmount" 
                                              type="text" 
                                              placeholder="Monto Fijo"
                                              value={this.state.formFields['fixedAmount']}
                                              onChange={this.changeInputEventHandler}
                                              disabled={disableSlider || 3 === localChargeType || 4 === localChargeType}
                                            />
                                            <InputGroup.Addon>{this.state.currency}</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                 <Row className="show-grid">
                                    <Col sm={3}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Porcentaje del Saldo</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="percentage" 
                                            type="text" 
                                            placeholder="Porcentaje Mensual"
                                            value={this.state.formFields['percentage']}
                                            onChange={this.changeInputEventHandler}
                                            disabled={disableSlider || 3 === localChargeType || 5 === localChargeType}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                    <Col sm={4}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Monto Mínimo</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="minimumAmount" 
                                            type="text" 
                                            placeholder="Monto Mínimo"
                                            value={this.state.formFields['minimumAmount']}
                                            onChange={this.changeInputEventHandler}
                                            disabled={disableSlider || 3 === localChargeType || 5 === localChargeType}
                                            />
                                            <InputGroup.Addon>{this.state.currency}</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Interés</ControlLabel>
                                          <InputGroup>
                                            <FormControl 
                                              name="interest" 
                                              type="text" 
                                              placeholder="Interés"
                                              value={this.state.formFields['interest']}
                                              onChange={this.changeInputEventHandler}
                                              disabled={disableSlider || 4 === localChargeType || 5 === localChargeType}
                                              />
                                              <InputGroup.Addon>%</InputGroup.Addon>
                                            </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <hr/>
                                    </Col>
                                    
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <div style={{textAlign: 'center'}}>
                                        <Button onClick={this.addFinancePeriod} disabled={disableInsert}>Agregar</Button>
                                        <Button onClick={this.removeFinancePeriod} disabled={!this.state.enableDelete}>Eliminar</Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">&nbsp;</Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <ReactTable
                                      
                                        //minRows={0}
                                        defaultPageSize={5}
                                        data={this.state.chargesFinancing}
                                        columns={columns}
                                        //pages={0}
                                        showPagination={true}
                                        getTrProps={(state, rowInfo) => {
                                          if (rowInfo && rowInfo.row && rowInfo.index === this.state.chargesFinancing.length - 1) {
                                            return {
                                              onClick: (e) => {
                                                this.setSelectedRow(rowInfo);
                                              },
                                              style: {
                                                background: rowInfo.index === this.state.selectedRow ? '#00afec' : 'white',
                                                color: rowInfo.index === this.state.selectedRow ? 'white' : 'black'
                                              }
                                            }
                                          }else{
                                            return {}
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                              </Grid>
                          </FormGroup>
                      </Form>
                    </Panel.Body>
                  </Panel>
                </ModalBody>

                <ModalFooter>
                  <Button onClick={this.props.handleCloseFinanceCharge} variant="primary">Cerrar</Button>
                </ModalFooter>
                </OverlayLoadingComponent>
              </Modal>
              ;
      }

      render(){
          return this.renderChargesFinancing();
      }
}