import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button,InputGroup} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./Periods.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'


export default class Periods extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedPeriodId: null,
        periodValue: '',
        isEnabled: false,
        errorDescription: '',
        showErrorAlert: false,
        periods: []
      };
    }

    //================================= Lógica de negocio =========================================

    handlePeriodValueChange(event){
        this.setState({periodValue:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreatePeriod(event){
        this.createPeriod(this.state.periodValue);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdatePeriod(event){
        this.updatePeriod(this.state.selectedPeriodId, this.state.periodValue);
    }

    handleDeletePeriod(event){
        this.deletePeriod(this.state.selectedPeriodId);
    }

    async refreshPeriods() {
        this.queryPeriods();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryPeriods();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                periodValue: '',
                selectedPeriodId: null
              });
        }
        else{
        this.setState({
            selected: rowInfo.index,
            periodValue: rowInfo.row._original.periodValue,
            selectedPeriodId: rowInfo.row._original.periodId
          });
        }
    }

    //================================= CRUD =========================================
    async queryPeriods() {
        try {
            this.setState({isLoading : true});
            const periods = await API.get("alticaapi", "/periods");
            this.setState({ periods: periods });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createPeriod(periodValue) {
        let periodInit = {
            body: {"value":periodValue}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/periods", periodInit);
            this.setState({isLoading:false, periodValue:''});
            this.refreshPeriods();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updatePeriod(periodId, periodValue) {
        let periodInit = {
            body: {"period":periodId, "value":periodValue}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/periods", periodInit);
            this.setState({isLoading:false, periodValue:'',selectedPeriodId: null, selected: null});
            this.refreshPeriods();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deletePeriod(periodId) {
        let periodInit = {
            body: {"period":periodId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/periods", periodInit);
            this.setState({isLoading:false, periodValue:'', selectedPeriodId: null, selected: null});
            this.refreshPeriods();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de rendering =========================================
    renderPeriodsTable(periods){
        const columns = [{
            id: 'Identificador',
            Header: 'Identificador',
            accessor: 'periodId'
          }, {
            id: 'ValorPeriodo',
            Header: 'Valor del Periodo (meses)',
            accessor: 'periodValue'
          }
        ];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== periods) && (periods.length > 0)?
                <ReactTable
                data={periods}
                columns={columns}
                showPagination={true}
                defaultPageSize={10}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {    
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron periodos en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedPeriodId && undefined !== this.state.selectedPeriodId){
            return <Col sm={12} smOffset={5}>
                <Button name="deleteButton" onClick={this.handleDeletePeriod.bind(this)}>Eliminar periodo</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreatePeriod.bind(this)}>Crear periodo</Button>
                </Col>;
        }
    }

    renderWarningMessage(){
        if(null !== this.state.selectedPeriodId && undefined !== this.state.selectedPeriodId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina un periodo se eliminarán todas las configuraciones asociadas al mismo en todas las campañas.</p>
        </div>
        }
        
    }

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }

    renderPeriodsForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de Periodos</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup controlId="periodsFormValue">
                                <Col sm={11}>
                                    <ControlLabel>Valor</ControlLabel>
                                </Col>
                                <Col sm={11}>
                                    <InputGroup>
                                        <FormControl type="text" placeholder="Periodo" value={this.state.periodValue} onChange={this.handlePeriodValueChange.bind(this)} />
                                        <InputGroup.Addon>Meses</InputGroup.Addon>
                                    </InputGroup>
                                </Col>
                            </FormGroup>
                            <hr></hr>
                            <FormGroup controlId="PeriodsFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Periodos Existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderPeriodsTable(this.state.periods)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderPeriodsForm();
    }
}