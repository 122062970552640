import React, { Component } from "react";
import {Alert} from "react-bootstrap";

export default class InformationAlert extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showInformationAlert: this.props.showInformationAlert, 
        description: this.props.description
      };
    }

    renderErrorAlert(){
        if(this.state.showInformationAlert){
            return <Alert bsStyle="success">
                <strong>{this.state.description}</strong>
            </Alert>;
        }
        return '';
    }

    render(){
        return this.renderErrorAlert();
    }
}

