import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./Products.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'


export default class Products extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedProductId: null,
        productDescription: '',
        errorDescription: '',
        showErrorAlert: false,
        products: []
      };
    }

    //================================= Lógica de negocio =========================================

    handleProductDescriptionChange(event){
        this.setState({productDescription:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreateProduct(event){
        this.createProduct(this.state.productDescription);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdateProduct(event){
        this.updateProduct(this.state.selectedProductId, this.state.productDescription);
    }

    handleDeleteProduct(event){
        this.deleteProduct(this.state.selectedProductId);
    }

    async refreshProducts() {
        this.queryProducts();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryProducts();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                productDescription: '',
                selectedProductId: null
              });
        }
        else{
        this.setState({
            selected: rowInfo.index,
            productDescription: rowInfo.row._original.productDescription,
            selectedProductId: rowInfo.row._original.productId
          });
        }
    }

    //================================= CRUD =========================================
    async queryProducts() {
        try {
            this.setState({isLoading : true});
            const products = await API.get("alticaapi", "/products");
            this.setState({ products });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createProduct(productDescription) {
        let productInit = {
            body: {"name":productDescription}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/products", productInit);
            this.setState({isLoading:false, productDescription:''});
            this.refreshProducts();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updateProduct(productId, productDescription) {
        let productInit = {
            body: {"product":productId, "name":productDescription}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/products", productInit);
            this.setState({isLoading:false, productDescription:'', selectedProductId: null, selected: null});
            this.refreshProducts();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deleteProduct(productId) {
        let productInit = {
            body: {"product":productId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/products", productInit);
            this.setState({isLoading:false, productDescription:'', selectedProductId: null, selected: null});
            this.refreshProducts();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de rendering =========================================
    renderProductsTable(products){
        const columns = [{
            id: 'Identificador',
            Header: 'Identificador',
            accessor: 'productId'
          }, {
            id: 'NombreCompania',
            Header: 'Nombre',
            accessor: 'productDescription'
          }];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== products) && (products.length > 0)?
                <ReactTable
                data={products}
                columns={columns}
                showPagination={true}
                defaultPageSize={5}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron compañías en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedProductId && undefined !== this.state.selectedProductId){
            return <Col sm={12} smOffset={4}>
                <Button name="updateButton" onClick={this.handleUpdateProduct.bind(this)}>Actualizar producto</Button> 
                <Button name="deleteButton" onClick={this.handleDeleteProduct.bind(this)}>Eliminar producto</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreateProduct.bind(this)}>Crear producto</Button>
                </Col>;
        }
    }

    renderWarningMessage(){
        if(null !== this.state.selectedProductId && undefined !== this.state.selectedProductId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina un producto se eliminarán todas las campañas asociadas al mismo.</p>
        </div>
        }
        
    }

    

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }

    renderProductsForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de Productos</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup controlId="productsFormName">
                                <Col sm={1}>
                                    <ControlLabel>Nombre</ControlLabel>
                                </Col>
                                <Col sm={11}>
                                    <FormControl type="text" placeholder="Producto" value={this.state.productDescription} onChange={this.handleProductDescriptionChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <hr></hr>
                            <FormGroup controlId="productsFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Productos Existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderProductsTable(this.state.products)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderProductsForm();
    }
}