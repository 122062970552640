import React, { Component } from "react";
import { FormControl} from "react-bootstrap";
import { API } from "aws-amplify";
import "./ComboAssetCondition.css";
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';


export default class ComboAssetCondition extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          company: props.companyId,
          assetId: props.assetId,
          assetConditions: [], 
          parentHandle: props.parentHandle
        };
      }

      handleChange (e) {
        this.props.handler(e);
    }

      async componentDidUpdate(prevProps) {
          if((this.props.companyId !== prevProps.companyId) || (this.props.assetId !== prevProps.assetId)) {
            AddLoadingComponent(this.state.parentHandle, 'assetsCondition');
            this.setState({company: this.props.companyId, assetId: this.props.assetId})
            const result = await this.queryAssetConditionsByCompanyAndAsset(this.props.companyId, this.props.assetId);
            this.setState({assetConditions: result})
            RemoveLoadingComponent(this.state.parentHandle, 'assetsCondition');
          }
      } 

      async queryAssetConditionsByCompanyAndAsset(companyId, assetId) {
        AddLoadingComponent(this.state.parentHandle, 'assetsCondition');
        this.setState({isLoading : true});
        if(undefined !== companyId && undefined !== assetId) {
          const response = await API.get("alticaapi", "/assetcondition/" + companyId + "/" + assetId);
          this.setState({isLoading : false});
          RemoveLoadingComponent(this.state.parentHandle, 'assetsCondition');
          return response;
        }
        else {
          RemoveLoadingComponent(this.state.parentHandle, 'assetsCondition');
          return [];
        }
      }

      renderAssetConditionsList(assetConditions) {
        if(this.state.isLoading){
            return <option value="">Cargando datos ...</option>;
        }
        else{
            return [{}].concat(assetConditions).map(
            (condition, i) =>
            i !== 0 ?  
              <option value={condition.assetConditionId}>{condition.assetConditionDescription}</option>
            :
            <option value="">Seleccione la condición del activo</option>
            );
        }
      }

      render(){
          return (
                
                <FormControl
                    name="assetCondition" 
                    inputRef={ el => this.inputEl=el }
                    componentClass="select" placeholder="select" onChange={this.handleChange.bind(this)}>
                    {this.renderAssetConditionsList(this.state.assetConditions)}
                </FormControl>
                
          );
      }
}