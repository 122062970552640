import Modal from 'react-responsive-modal';
import React, { Component } from "react";
import { ModalTitle, ModalHeader, ModalBody, ModalFooter, Button, Grid, Form, FormGroup, FormControl, Row, Col, ControlLabel, Panel, ButtonToolbar, ToggleButtonGroup, ToggleButton, Checkbox,InputGroup} from "react-bootstrap";
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { API } from "aws-amplify";
import {RenderPercentageValue, RenderEmptyOrUndefinedValue} from '../utils';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import OverlayLoadingComponent from "../components/OverlayLoadingComponent"
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';


const SliderWithTooltip = createSliderWithTooltip(Slider);



const columns = [
  {
    Header: 'Desde Mes',
    accessor: 'initialMonth'
  },
  {
    Header: 'Hasta Mes',
    accessor: 'finalMonth'
  },
  {
    Header: 'Tasa Base',
    accessor: 'baseRateDescription',
    Cell: row => <div style={{ textAlign: "center" }}>{RenderEmptyOrUndefinedValue(row.value)}</div>
  },
  {
    Header: 'Spread',
    accessor: 'spread',
    Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>  
  },
  {
    Header: 'Amortiza',
    accessor: 'amortize',
    Cell: row => <div style={{ textAlign: "center" }}>{renderAmortize(row.value)}</div>
  }
];

function renderAmortize(amortizeType){
  if(1 === amortizeType){
      return 'SI';
  }
  if(0 === amortizeType){
      return 'NO';
  }
  return 'No definido';
};



export default class hideChargesByCreditModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          show: props.show, 
          periods: props.periods, 
          creditDistributionByPeriod: [], 
          baserates: [],
          selectedCredit: props.selectedCredit,
          formFields:[],
          loadingComponents:[],
          selectedRow: null,
          minRangeValue: 1,
          maxRangeValue: 1,
          selectedFinalMonth: 0,
          disableAdding: false,
          disableDelete: true,
          marks:{},
          parentHandle: props.parentHandle
          
        };
        this.state.formFields['initialMonth']=0;
        this.state.formFields['finalMonth']=0;
        this.state.formFields['baseRate']='';
        this.state.formFields['spread']=0;
        this.state.formFields['noAmortize']=0;
        this.changeInputEventHandler = this.changeInputEventHandler.bind(this);
        this.changeCheckEventHandler = this.changeCheckEventHandler.bind(this);
        this.changePeriodEventHandler = this.changePeriodEventHandler.bind(this);
      }

       

    async componentDidMount() {
      try {
        let baseRatesLocal = await this.loadData("baserates");
        //Al montar el componente, selecciono la primera tasa base para que quede inicializada
        if(undefined !== baseRatesLocal && baseRatesLocal.length > 0){
          let formFieldsLocal = this.state.formFields;
          formFieldsLocal['baseRate'] = baseRatesLocal[0].baseRateId;
          this.setState({formFields:formFieldsLocal});
        }
      } catch (e) {
          alert(e);
      }
    }
    
    
    

    handleFinalMonthChange(event) {
      this.setState({selectedFinalMonth: event});
      
    }

    async componentDidUpdate(prevProps, prevState) {

      if(undefined !== prevProps && prevProps.show !== this.props.show){
        this.setState({show:this.props.show});
      }

      if(undefined !== prevState && prevState.periods !== this.props.periods){
        this.setState({periods:this.props.periods});
      }

      if(undefined !== prevState && prevState.selectedCredit !== this.props.selectedCredit){
        this.setState({selectedCredit:this.props.selectedCredit});
      }
    } 

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeInputEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = event.target.value;
      this.setState({formFields: formFieldsLocal});
    }

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeCheckEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = this.state.formFields[event.target.name] === 1 ? 0 : 1;
      this.setState({formFields: formFieldsLocal});
      }

    async changePeriodEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      
      formFieldsLocal['period'] = event;
      let selectedPeriod = this.state.periods.find(obj => obj.periodId === event);
      this.setState({formFields: formFieldsLocal, selectedFinalMonth: selectedPeriod.periodValue, selectedRow: null, disableDelete: true});
      await this.loadCreditDistributionByPeriod(this.state.selectedCredit, selectedPeriod);
      
      await this.updateMonthsRange(selectedPeriod);
      
      
    }

    async updateMonthsRange(selectedPeriod){
      let localMarks = {};
      if(0 !== this.state.creditDistributionByPeriod.length){
        let finalMonth = this.state.creditDistributionByPeriod[this.state.creditDistributionByPeriod.length-1].finalMonth;
        selectedPeriod.periodValue === finalMonth ? this.setState({minRangeValue:  finalMonth, disableAdding: true}) : this.setState({minRangeValue:  finalMonth+ 1, disableAdding: false});    
      } else{
        this.setState({minRangeValue: 1, disableAdding: false});
      }
      this.setState({maxRangeValue: selectedPeriod.periodValue}); 
      localMarks[this.state.minRangeValue] = this.state.minRangeValue;
      localMarks[selectedPeriod.periodValue] = selectedPeriod.periodValue;
      this.setState({marks : localMarks});
    }

    setSelectedRow(rowInfo){
      let localFormFields = this.state.formFields;
      if(this.state.selectedRow === rowInfo.index){
        //localFormFields['period'] = '';
        localFormFields['initialMonth'] = 0;
        localFormFields['finalMonth'] = 0;
        localFormFields['baseRate'] = '';
        localFormFields['spread'] = 0;
        localFormFields['noAmortize'] = 0;
        this.setState({
          selectedRow: null,
          formFields: localFormFields,
          disableDelete: true
        });
      }
      else{
          localFormFields['period'] = rowInfo.row._original.periodId;
          localFormFields['initialMonth'] = rowInfo.row._original.initialMonth;
          localFormFields['finalMonth'] = rowInfo.row._original.finalMonth;
          localFormFields['baseRate'] = rowInfo.row._original.baseRateId;
          localFormFields['spread'] = rowInfo.row._original.spread;
          localFormFields['noAmortize'] = rowInfo.row._original.amortize === 0 ? 1 : 0;
          this.setState({
              selectedRow: rowInfo.index,
              formFields: localFormFields
            });

          }
      (rowInfo.index === this.state.creditDistributionByPeriod.length-1)  ? this.setState({disableDelete: false}) : this.setState({disableDelete: true});
          
      
    }

      
    handleClose(){
      this.props.hideCreditDistributionModalHandler();
    }

    handleShow(){
      this.setState({show : true})
    }

    async handleDistributionCreate(){

      let post = {};

      post["campaign"] = this.state.selectedCredit;
      post["period"] = this.state.formFields['period'];
      post["initialmonth"] = this.state.minRangeValue;
      post["finalmonth"] = this.state.selectedFinalMonth;
     
      post["amortize"] = this.state.formFields['noAmortize'] === 0 ? 1 : 0;
      if(post["amortize"]){
        post["baserate"] = this.state.formFields['baseRate'];
        post["spread"] = this.state.formFields['spread'];
      }

      
      let creditDistributionInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{
        await API.post("alticaapi", "/creditdistribution", creditDistributionInit);
        let selectedPeriod = this.state.periods.find(obj => obj.periodId === this.state.formFields['period']);
        

        let localFormFields = this.state.formFields;
       // localFormFields['period'] = '';
        localFormFields['initialMonth'] = 0;
        localFormFields['finalMonth'] = 0;
        localFormFields['baseRate'] = '';
        localFormFields['spread'] = 0;
        localFormFields['noAmortize'] = 0;

        this.setState({formFields:localFormFields});

        await this.loadCreditDistributionByPeriod(this.state.selectedCredit, selectedPeriod);
        await this.updateMonthsRange(selectedPeriod);

      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      
    }

    async handleCreditDistributionRemove(){
      let post = {};
      post["campaign"] = this.state.selectedCredit;
      post["period"] = this.state.formFields['period'];
      post["initialmonth"] = this.state.formFields['initialMonth'];
      post["finalmonth"] = this.state.formFields['finalMonth'];
      let creditDistributionInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{
        await API.del("alticaapi", "/creditdistribution", creditDistributionInit);
        let selectedPeriod = this.state.periods.find(obj => obj.periodId === this.state.formFields['period']);
        
        let localFormFields = this.state.formFields;
        //localFormFields['period'] = '';
        localFormFields['initialMonth'] = 0;
        localFormFields['finalMonth'] = 0;
        localFormFields['baseRate'] = '';
        localFormFields['spread'] = 0;
        localFormFields['noAmortize'] = 0;
        this.setState({formFields:localFormFields});
        await this.loadCreditDistributionByPeriod(this.state.selectedCredit, selectedPeriod);
        await this.updateMonthsRange(selectedPeriod);


      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      
    }

    comparePeriod( a, b ) {
      if ( a.periodValue < b.periodValue ){
        return -1;
      }
      if ( a.periodValue > b.periodValue ){
        return 1;
      }
      if(a.periodValue === b.periodValue){
          if ( a.age < b.age ){
              return -1;
          }
          if ( a.age > b.age ){
              return 1;
          }
      }
      return 0;
    }

    
    async loadCreditDistributionByPeriod(selectedCredit, period){
      let thisHandle = this;
      this.setState({premiumsByPeriod:[]});
      let localCreditDistribution = [];
      AddLoadingComponent(this, 'creditDistribution');
      const result = await API.get("alticaapi", "/creditdistribution/" + selectedCredit + "/" + period.periodId);
      if(undefined !== result && result.length > 0){
          result.forEach(element => {
              let localValue = {};
              localValue['periodId'] = period.periodId;
              localValue['periodValue'] = period.periodValue;
              localValue['initialMonth'] = element.primaryKey.initialMonth;
              localValue['finalMonth'] = element.primaryKey.finalMonth;
              localValue['baseRateId'] = undefined !== element.baseRateId ? element.baseRateId.baseRateId : '-' ;
              localValue['baseRateDescription'] = undefined !== element.baseRateId ? element.baseRateId.baseRateDescription : '';
              localValue['baseRatePercentage'] = undefined !== element.baseRateId ? element.baseRateId.baseRatePercentage : ''  ;
              localValue['baseRateVariable'] = undefined !== element.baseRateId ? element.baseRateId.baseRateVariable : '' ;
              localValue['spread'] = undefined !== element.spread ? element.spread : '';
              localValue['amortize'] = element.amortize;
              localCreditDistribution.push(localValue);
          });
          localCreditDistribution.sort(thisHandle.comparePeriod);
          thisHandle.setState({creditDistributionByPeriod: localCreditDistribution});
          this.state.formFields['baseRate'] = this.state.baserates[0].baseRateId;
          
        }else{
          thisHandle.setState({creditDistributionByPeriod: []});
        }
        RemoveLoadingComponent(this, 'creditDistribution');
  }

    addLoadingComponent(componentName){
      let localComponents = this.state.loadingComponents;
      localComponents.push(componentName);
      this.setState({loadingComponents:localComponents});
    }

    removeLoadingComponent(componentName){
        let localComponents = this.state.loadingComponents;
        let index = localComponents.indexOf(componentName);
        if (index > -1) {
            localComponents.splice(index, 1);
        }
        this.setState({loadingComponents:localComponents});
    }

    isAComponentLoading(){
        return this.state.loadingComponents.length > 0;
    }

    isLoading(component){
        return this.state.loadingComponents.indexOf(component) > -1;
    }

      renderPeriodsList(periods) {
        return [{}].concat(periods).map(
        (period, i) =>
        i !== 0 ?
            <option value={period.periodId}>{period.periodValue}</option>
        :
        <option value="">Seleccione el periodo</option>
        );
        
      }

      
      renderPeriodsButtons(periods) {
        return periods.map(
        (period, i) =>
                    <ToggleButton key={i} value={period.periodId}>{period.periodValue}</ToggleButton>
        );
        
      }

     renderMonthRangeSlider(){
      return <SliderWithTooltip
          min={this.state.minRangeValue}
          max={this.state.maxRangeValue}
          step={1}
          marks={this.state.marks}
          value={this.state.selectedFinalMonth}
          disabled={this.state.disableAdding}
          onChange={this.handleFinalMonthChange.bind(this)}
    />
     }

     async loadData(resource) {
      this.addLoadingComponent(resource);
      const result = await API.get("alticaapi", "/" + resource);
      this.setState({ [resource]: result });
      this.removeLoadingComponent(resource);
      return result;
    }

    renderBaseRateList(baseRates) {
      if(this.isLoading("baserate")){
          return <option value="">Cargando datos ...</option>;
      }  
      else{
          return [].concat(baseRates).map(
          (baserate, i) =>  <option key={i} value={baserate.baseRateId}>{baserate.baseRateDescription}</option>);
      }
      
    }

    renderCreditDistribution(){
          if (this.state.isLoading){
            return <div style={{ textAlign: "center" }}>
              Cargando información ...
            </div>;
          }
          else{
            return <div style={{ textAlign: "center" }}>
              <Modal open={this.state.show} onClose={this.handleClose.bind(this)} center>
                <OverlayLoadingComponent isLoading={this.isAComponentLoading()}>
                <ModalHeader>
                  <ModalTitle>Configuración Distribución del Crédito</ModalTitle>
                </ModalHeader>

                <ModalBody>
                <Panel>
                  <Panel.Body>
                      <Form onSubmit={this.setRedirect}>
                          <FormGroup controlId="formControlsSelect">
                              <Grid>
                              <Row className="show-grid">

                                  <Col sm={7}>
                                    <FormGroup controlId="Plazo"> 
                                      <ControlLabel>Plazo</ControlLabel>
                                      <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="options" value={this.state.formFields['period']} onChange={this.changePeriodEventHandler} justified>
                                          {this.renderPeriodsButtons(this.state.periods)}
                                        </ToggleButtonGroup>
                                      </ButtonToolbar>
                                    </FormGroup> 
                                  </Col>
                                </Row>
                                <Row className="show-grid">
                                  <Col sm={7}>
                                    <FormGroup controlId="RangoMeses">
                                        <ControlLabel>Rango de Meses</ControlLabel>
                                          <div>{this.state.minRangeValue} - {this.state.selectedFinalMonth}</div>
                                          <div>
                                            {this.renderMonthRangeSlider()}
                                          </div>
                                      </FormGroup>

                                  </Col>

                                </Row>
                                <Row className="show-grid">
                                    <Col sm={7}> 
                                      <FormGroup controlId="Amortiza"> 
                                        <Checkbox
                                          name="noAmortize"
                                          checked={this.state.formFields['noAmortize']}
                                          onChange={this.changeCheckEventHandler}>
                                            Opción de no amortizar
                                        </Checkbox>
                                       </FormGroup> 
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}> 
                                      <FormGroup controlId="Tasa"> 
                                        <ControlLabel>Tasa Base</ControlLabel>
                                        <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="baseRate" 
                                                componentClass="select" 
                                                placeholder="select" 
                                                disabled={this.state.formFields["noAmortize"]}
                                                value={this.state.formFields['baseRate']}
                                                onChange={this.changeInputEventHandler}>
                                                {this.renderBaseRateList(this.state.baserates)}
                                                
                                            </FormControl>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}> 
                                      <FormGroup controlId="Spread"> 
                                        <ControlLabel>Spread (0-100)</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="spread" 
                                            type="text"
                                            disabled={this.state.formFields["noAmortize"]}
                                            placeholder="Digite el spread"
                                            value={this.state.formFields['spread']}
                                            onChange={this.changeInputEventHandler} 
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                  
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <hr/>
                                    </Col>
                                    
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <div style={{textAlign: 'center'}}>
                                        <Button disabled={this.state.disableAdding} onClick={this.handleDistributionCreate.bind(this)}>Agregar</Button>
                                        <Button disabled={this.state.disableDelete} onClick={this.handleCreditDistributionRemove.bind(this)}>Eliminar</Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">&nbsp;</Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <ReactTable
                                        noDataText="No hay datos."
                                        defaultPageSize={5}
                                        data={this.state.creditDistributionByPeriod}
                                        columns={columns}
                                        //pages={0}
                                        showPagination={true}
                                        getTrProps={(state, rowInfo) => {
                                          if (rowInfo && rowInfo.row) {
                                            return {
                                              onClick: (e) => {
                                                  this.setSelectedRow(rowInfo);
                                              },
                                              style: {
                                                background: rowInfo.index === this.state.selectedRow ? '#00afec' : 'white',
                                                color: rowInfo.index === this.state.selectedRow ? 'white' : 'black'
                                              }
                                            }
                                          }else{
                                            return {}
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                              </Grid>
                          </FormGroup>
                      </Form>
                    </Panel.Body>
                  </Panel>
                </ModalBody>

                <ModalFooter>
                  <Button onClick={this.handleClose.bind(this)} variant="primary">Cerrar</Button>
                </ModalFooter>
                </OverlayLoadingComponent>
              </Modal>
            </div>;
          }
      }

      render(){
          return this.renderCreditDistribution();
      }
}