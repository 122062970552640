import React, { Component } from "react";
import { Modal, ModalTitle, ModalHeader, ModalBody, ModalFooter, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import 'rc-slider/assets/index.css';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { MdInfo } from 'react-icons/md';
import { IoIosCheckmarkCircleOutline, IoIosCheckmarkCircle } from "react-icons/io";
import {RenderPercentageValue, FormatNumber} from '../utils';
import ReactLoading from 'react-loading';

export default class CreditChargesFinance extends Component{

    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          show: false,
          chargeId: props.chargeId,
          chargeFinancing : []
        };
      }

       

    async componentDidMount() {
      try {
        this.setState({chargeFinancing : []});
        const result = await this.queryChargeFinance(this.props.chargeId);
        this.setState({ chargeFinancing: result });
      } catch (e) {
        console.log(e);
      }
    }


      async componentDidUpdate(prevProps) {
          if(this.props.chargeId !== prevProps.chargeId && this.props.selected !== prevProps.selected) {
            this.setState({chargeId: this.props.chargeId})
            this.setState({chargeFinancing : []});
            const result = await this.queryChargeFinance(this.props.chargeId);
            this.setState({chargeFinancing: result})
          }
      } 

      async queryChargeFinance(chargeId) {
        
        this.setState({isLoading : true});
        if(undefined !== chargeId && "NOTSELECTED" !== chargeId) {
           let response = [];
           try{
             response = await API.get("alticaapi", "/chargesfinancing/" + chargeId);
           } catch(exception){
             console.log(exception);
           }
           this.setState({isLoading : false});
           return response;
        }
        else {
          this.setState({isLoading : false});
          return [];
        }
        
      }

      togleSelected(chargeId){
        let selected = this.state.selected;
        this.setState({selected:!selected});
        this.props.handler(chargeId, !selected);
      }

      handleClose(){
        this.setState({show : false});
      }

      handleShow(){
        this.setState({show : true})
      }

      renderSelectedIcon(){
        return this.state.selected?
          <IoIosCheckmarkCircle/>
            :
          <IoIosCheckmarkCircleOutline/>
      }

      renderChargesFinancing(){
            const columns = [{
              Header: 'Mes inicial',
              accessor: "initialMonth",
              Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
            }, {
              Header: 'Mes final',
              accessor: 'finalMonth',
              Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
            }, {
              Header: 'Porcentaje del Saldo',
              accessor: 'percentage',
              Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
            }, {
              Header: 'Monto Mínimo',
              accessor: 'minimumAmount',
              Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
            }, {
              Header: 'Interés',
              accessor: 'interest',
              Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
            },{
              Header: 'Monto Fijo',
              accessor: 'fixedAmount',
              Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
            }];

            if (this.state.isLoading){
              return <div style={{ textAlign: "center" }}>
                Cargando información ...
              </div>;
            }
            else{
              return <div style={{ textAlign: "center" }}>
                <a id={"finance" + this.state.chargeId} onClick={this.handleShow.bind(this)}>
                  <MdInfo/>
                </a>
                <Modal show={this.state.show} onHide={this.handleClose.bind(this)}>
                  <ModalHeader closeButton>
                    <ModalTitle>Distribucion del cargo</ModalTitle>
                  </ModalHeader>

                  <ModalBody>
                    <ReactTable
                      data={this.state.chargeFinancing}
                      columns={columns}
                      showPagination={false}
                      defaultPageSize={this.state.chargeFinancing.length}
                    />
                  </ModalBody>

                  <ModalFooter>
                    <Button onClick={this.handleClose.bind(this)} variant="primary">Cerrar</Button>
                  </ModalFooter>
                </Modal>
              </div>;
            }

      }

      render(){
        if(!this.state.isLoading){
          if(undefined !== this.state.chargeFinancing && this.state.chargeFinancing.length > 0){
            return this.renderChargesFinancing();
          }
          else {
            return <div></div>
          }
        }
        else{
          return <ReactLoading type="bars" color="grey" height={5} width={20} />;
        }
        
      }
}