import React, { Component } from "react";
import { Grid, Form, FormGroup, FormControl, Row, Col, ControlLabel, Panel, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import "./Home.css";
import { Redirect } from 'react-router-dom'
import Picky from "react-picky";
import "react-picky/dist/picky.css";
import OverlayLoadingComponent from "../components/OverlayLoadingComponent"
import PremiumsByCreditModal from "../components/PremiumsByCreditModal"
import CreditDistributionModal from "../components/CreditDistributionModal"
import ChargesByCreditModal from "../components/ChargesByCreditModal"
import 'rc-slider/assets/index.css';
import 'rc-tooltip/assets/bootstrap.css';
import {AddLoadingComponent, RemoveLoadingComponent, IsAComponentLoading, IsLoading} from '../loadingutils';

const COMPANIES = "companies";
const PRODUCTS = "products";
const ASSETS = "assets";
const ASSETCONDITION = "assetcondition";
const CURRENCIES = "currencies";
const CREDIT = "credit";
const PERIODS = "periods";


export default class Home extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        companies: [],
        products: [],
        assets: [],
        assetcondition: [],
        currencies: [],
        credit:[],
        periods:[],
        loadingComponents:[],
        formFields:[],
        redirect: false,
        value: null,
        arrayValue: [], 
        showPremiumsByCreditModal: false,
        showCreditDistributionModal: false, 
        showChargesByCreditModal: false, 
        existentCampaign: false, 
        creditDistributions: [],
        premiumsByPeriod: [], 
        hasCharges:false,
        creditCharges:[]
      };

      this.state.formFields["assetYear"] = this.getCurrentYear();
      this.state.formFields["campaignName"] = '';
      this.state.formFields["periods"] = [];
      this.changeInputEventHandler = this.changeInputEventHandler.bind(this);
      this.changeCampaignHandler = this.changeCampaignHandler.bind(this);
      this.changeCurrencyValue = this.changeCurrencyValue.bind(this);
      this.changeCampaignNameHandler = this.changeCampaignNameHandler.bind(this);
      this.selectMultipleOption = this.selectMultipleOption.bind(this);
      this.selectSingleOption = this.selectSingleOption.bind(this);
      this.showPremiumsByCreditModalHandler = this.showPremiumsByCreditModalHandler.bind(this);
      this.showCreditDistributionModalHandler = this.showCreditDistributionModalHandler.bind(this);
      this.hideCreditDistributionModalHandler = this.hideCreditDistributionModalHandler.bind(this);
      this.hidePremiumsByCreditModalHandler = this.hidePremiumsByCreditModalHandler.bind(this);
      this.showChargesByCreditModalHandler = this.showChargesByCreditModalHandler.bind(this);
      this.hideChargesByCreditModalHandler = this.hideChargesByCreditModalHandler.bind(this);
      this.loadPremiumsByPeriods = this.loadPremiumsByPeriods.bind(this);
      this.loadCreditDistributions = this.loadCreditDistributions.bind(this);
    }

    //******************************* CARGA DE DATOS *************************************


    async showPremiumsByCreditModalHandler(){
        this.setState({showPremiumsByCreditModal:true});
    }

    async showCreditDistributionModalHandler(){
        this.setState({showCreditDistributionModal:true});
    }
    
    async hideCreditDistributionModalHandler(){
        this.setState({showCreditDistributionModal:false});
    }

    async hidePremiumsByCreditModalHandler(){
        this.setState({showPremiumsByCreditModal:false});
    }

    async showChargesByCreditModalHandler(){
        this.setState({showChargesByCreditModal:true});
    }

    async hideChargesByCreditModalHandler(){
        this.setState({showChargesByCreditModal:false});
    }

    async loadData(resource) {
        AddLoadingComponent(this, resource);
        const result = await API.get("alticaapi", "/" + resource);
        this.setState({ [resource]: result });
        RemoveLoadingComponent(this, resource);
    }

    async loadCampaignData(resource) {
        AddLoadingComponent(this, resource);
        const result = await API.get("alticaapi", "/" + resource);
        this.setState({ [resource]: result });
        RemoveLoadingComponent(this, resource);
    }

    async loadPeriods(){
        await this.loadData(PERIODS);
        let localPeriods = [];
        this.state.periods.forEach(obj => {
            localPeriods.push({ id: obj.periodId, name: obj.periodValue });
        });
        
        this.setState({periodsList:localPeriods});
    }

    async loadCreditPeriods(selectedCredit){
        AddLoadingComponent(this, "creditPeriods");
        const result = await API.get("alticaapi", "/creditperiods/" + selectedCredit.creditConfigurationId);
        let localPeriods = [];
        let localDefaultPeriod = undefined;
        result.forEach(obj => {
            localPeriods.push(obj.period);
            if(obj.periodDefault === 1){
                localDefaultPeriod = obj.period;
            }
        });
        let localFormFields = this.state.formFields;
        localFormFields["periods"] = localPeriods;

        if(undefined !== localDefaultPeriod){
            localFormFields["defaultPeriod"] = localDefaultPeriod;
        }
    
        this.setState({formFields:localFormFields, premiumsByPeriod:[]});

        this.loadPremiumsByPeriods(selectedCredit, result);


        this.loadCreditDistributions(selectedCredit, result);

        this.loadChargesByCredit(selectedCredit);


        RemoveLoadingComponent(this, "creditPeriods");
    }

    comparePeriod( a, b ) {
        if ( a.periodValue < b.periodValue ){
          return -1;
        }
        if ( a.periodValue > b.periodValue ){
          return 1;
        }
        if(a.periodValue === b.periodValue){
            if ( a.age < b.age ){
                return -1;
            }
            if ( a.age > b.age ){
                return 1;
            }
        }
        return 0;
      }
      

    async loadCreditDistributions(selectedCredit, periods){
        let thisHandle = this;
        AddLoadingComponent(this, "periodsByCredit");
        this.setState({creditDistributions:[], hasCreditDistributions:false});
        const result = await API.get("alticaapi", "/creditdistribution/" + selectedCredit.creditConfigurationId);
        if(undefined !== result && result.length > 0){
            thisHandle.setState({hasCreditDistributions:true});
        }
        RemoveLoadingComponent(this, "periodsByCredit");

    }

      async loadChargesByCredit(selectedCredit){
        AddLoadingComponent(this, "loadChargesByCredit");
        const result = await API.get("alticaapi", "/creditcharges/" + selectedCredit.creditConfigurationId);
        if(undefined !== result && result.length > 0){
            this.setState({hasCharges:true, creditCharges:result});
        }
        RemoveLoadingComponent(this, "loadChargesByCredit");
      }


    async loadPremiumsByPeriods(selectedCredit, periods){
        AddLoadingComponent(this, "premiumsByCredit");
        this.setState({premiumsByPeriod:[], hasPremiums:false});
        let localPremiums = [];
        const result = await API.get("alticaapi", "/premiums/" + selectedCredit.creditConfigurationId);
        if(undefined !== result && result.length > 0){
            result.forEach(element => {
                let localValue = {};
                localValue['periodId'] = element.creditPremiumsEntityPK.creditPeriods.creditPeriodsEntityPK.periodId.periodId;
                localValue['periodValue'] = element.creditPremiumsEntityPK.creditPeriods.creditPeriodsEntityPK.periodId.periodValue;
                localValue['premium'] = element.premium;
                localValue['age'] = element.creditPremiumsEntityPK.age;
                localValue['combination'] = element.combination;
                localValue['residualValue'] = element.residualValue;
                localPremiums.push(localValue);
            });
            localPremiums.sort(this.comparePeriod);
            this.setState({hasPremiums:true, premiumsByPeriod: localPremiums});
            
        }
        RemoveLoadingComponent(this, "premiumsByCredit");
    }


    async postCreateCredit(clientInit){
        AddLoadingComponent(this, "create");
        let response = await API.post("alticaapi", "/credit", clientInit);
        RemoveLoadingComponent(this, "create");
        return response;
    }

    async postDeleteCredit(deleteInit){
        AddLoadingComponent(this, "delete");
        let response = await API.del("alticaapi", "/credit", deleteInit);
        RemoveLoadingComponent(this, "delete");
        return response;
    }

    async postUpdateCredit(creditInit){
        AddLoadingComponent(this, "updateCredit");
        let response = null;
        try{
            response = await API.patch("alticaapi", "/credit", creditInit);
        }
        catch(error){
            console.log(error);
            if(undefined !== error.response){
                console.log("Codigo: " + error.response.status);
                console.log("Detalle: " + error.response.data);
            }
        }
        RemoveLoadingComponent(this, "updateCredit");
        return response;
    }

    async postCreatePeriod(periodInit){
        AddLoadingComponent(this, "createPeriod");
        let response = await API.post("alticaapi", "/creditperiods", periodInit);
        RemoveLoadingComponent(this, "createPeriod");
        return response;
    }

    async resetView(){
        let formFieldsLocal = this.state.formFields;
        this.setFormValues(formFieldsLocal, undefined);
        formFieldsLocal["credit"] = '';
        formFieldsLocal["campaignName"] = '';
        this.setState({formFields: formFieldsLocal});
        this.loadData(CREDIT);
    }
    

    //******************************* HANDLERS DE EVENTOS ************************************
    selectMultipleOption(value) {
        value.sort((a,b) => (a.periodValue > b.periodValue) ? 1 : ((b.periodValue > a.periodValue) ? -1 : 0));
        let localFormFields = this.state.formFields;
        let previousPeriods = localFormFields['periods'];
        if(undefined !== previousPeriods && previousPeriods.length > value.length){
            //Busco los elemendos que no estan en el arreglo previo a ver si uno de ellos es el default seleccionado
            let missingPeriods = previousPeriods.filter(item=>value.indexOf(item)===-1);
            let defaultPeriod = localFormFields['defaultPeriod'];
            if(missingPeriods.indexOf(defaultPeriod) > -1){
                //Si se eliminaron los valores del arreglo que incluyen el default seleccionado, se resetea este último
                localFormFields['defaultPeriod'] = undefined;
            }
        }
        localFormFields['periods'] = value;
        this.setState({ formFields: localFormFields });
    }

    selectSingleOption(value) {
        let localFormFields = this.state.formFields;
        localFormFields['defaultPeriod'] = value;
        this.setState({ formFields: localFormFields });
    }

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeInputEventHandler(event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal[event.target.name] = event.target.value;
        this.setState({formFields: formFieldsLocal, showPremiumsByCreditModal:false, showCreditDistributionModal:false});
    }

    
    changeCampaignNameHandler(event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal[event.target.name] = event.target.value;
        //Si digita texto en el nombre de la campaña, se debe limpiar la selección del combo de la campaña
        formFieldsLocal['credit']='';
        this.setFormValues(formFieldsLocal, undefined);
        this.setState({formFields: formFieldsLocal});
    }

    changeCampaignHandler(event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal[event.target.name] = event.target.value;
        this.setState({formFields: formFieldsLocal, showPremiumsByCreditModal:false, showCreditDistributionModal:false});

        if('' !== event.target.value && undefined !== event.target.value && null !== event.target.value){
            //Quiere decir que se seleccionó una campaña existente, debo eliminar lo que se haya seleccionado en el input de nombre de campaña
            formFieldsLocal['campaignName']='';

            //y seleccionar los valores de cada componente
            let selectedCredit = this.state.credit.find(obj => obj.creditConfigurationId === Number(event.target.value));

            this.setFormValues(formFieldsLocal, selectedCredit);

            //estableciendo el estado al final de la selección
            this.setState({formFields: formFieldsLocal});

        }
        else{
            this.setFormValues(formFieldsLocal, undefined);
            //estableciendo el estado al final de la selección
            this.setState({formFields: formFieldsLocal});
        }

    }

    async submitCampaignCreation(){

        let post = {
            "name": this.state.formFields['campaignName'],
            "company": this.state.formFields['company'],
            "asset": this.state.formFields['asset'],
            "assetcondition": this.state.formFields['assetcondition'],
            "product": this.state.formFields['product'],
            "currency": this.state.formFields['currency']
          }

          let clientInit = {
            body: post, // replace this with attributes you need
            headers: {} // OPTIONAL
          }

          if('' !== this.state.formFields['campaignName'] && '' === this.state.formFields['credit']){
            //Si el nombre de la campaña no está vacío y el id del crédito si, implica que se está creando la campaña
            let creationResponse = await this.postCreateCredit(clientInit);
            
            if(undefined !== creationResponse && 
                undefined !== this.state.formFields['periods'] && 
                this.state.formFields['periods'].length > 0){

                this.state.formFields['periods'].forEach(period => {
                    let localPeriod = {};
                    localPeriod["period"] = period.periodId;
                    localPeriod["campaign"] = creationResponse.creditId;
                    if(this.state.formFields['defaultPeriod'].periodId === period.periodId){
                        localPeriod["default"] = 1;
                    }
                    else{
                        localPeriod["default"] = 0;
                    }

                    let periodInit = {
                        body: localPeriod, // replace this with attributes you need
                        headers: {} // OPTIONAL
                      }

                    try{
                        this.postCreatePeriod(periodInit);
                    }
                    catch(error){
                        console.log(error);
                        if(undefined !== error.response){
                            console.log("Estado: " + error.response.status);
                            console.log("Error: " + error.response.data);

                        }
                    }
                });

                this.resetView();

            }


          }
    }

    async submitCampaignUpdate(){

        let post = {
            "campaign":this.state.formFields['credit'],
            //"name": this.state.formFields['campaignName'],
            "company": this.state.formFields['company'],
            "asset": this.state.formFields['asset'],
            "assetcondition": this.state.formFields['assetcondition'],
            "product": this.state.formFields['product'],
            "currency": this.state.formFields['currency']
          }

          let creditInit = {
            body: post, // replace this with attributes you need
            headers: {} // OPTIONAL
          }
          await this.postUpdateCredit(creditInit);
          this.resetView();
    }

    async submitCampaignDeletion() {
        let post = {
            "campaign":this.state.formFields['credit']
          }

          let deleteInit = {
            body: post, // replace this with attributes you need
            headers: {} // OPTIONAL
          }
          await this.postDeleteCredit(deleteInit);
          this.resetView();
    }

    //******************************* CICLO DE VIDA ************************************

    async componentDidMount() {
        try {
            this.loadData(COMPANIES);
            this.loadData(PRODUCTS);
            this.loadData(ASSETS);
            this.loadData(ASSETCONDITION);
            this.loadData(CURRENCIES);
            this.loadCampaignData(CREDIT);
            this.loadPeriods();
            
        } catch (e) {
            alert(e);
        }
    }


    //****************************** UTILITARIOS *************************************

    changeCurrencyValue(currencyValue){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal["currencyValue"] = currencyValue;
        this.setState({formFields: formFieldsLocal});
    }

    setFormValues(formFieldsLocal, selectedCredit){
        if(undefined === selectedCredit){
            formFieldsLocal['company']='';
            formFieldsLocal['product']='';
            formFieldsLocal['asset']='';
            formFieldsLocal['assetcondition']='';
            formFieldsLocal['currency']='';
            formFieldsLocal["periods"] = [];
            formFieldsLocal["defaultPeriod"] = undefined;
            
        }
        else{
            formFieldsLocal['company']=selectedCredit.company.companyId;
            formFieldsLocal['product']=selectedCredit.product.productId;
            formFieldsLocal['asset']=selectedCredit.asset.assetId;
            formFieldsLocal['assetcondition']=selectedCredit.assetCondition.assetConditionId;
            formFieldsLocal['currency']=selectedCredit.currency.currencyId;
            this.loadCreditPeriods(selectedCredit);
        }
    }
    
    getPeriodsList(){
        if(undefined !== this.state.periodsList && this.state.periodsList.length>0){
            return this.state.periodsList;
        }
        else{
            return [];
        }
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
    }

    
    
    getCurrentYear(){
        let d = new Date();
        return d.getFullYear();
    }

    getYearList(){
        var yearList = [];
        var yearCounter = this.getCurrentYear();
        var negativeYearCounter = this.getCurrentYear();
        var i;
        yearList.push(this.getCurrentYear());
        for (i = 0; i < 10; i++) { 
            yearList.push(++yearCounter);
            yearList.unshift(--negativeYearCounter);
        }
        return yearList;
    }

    //********************************** RENDERS *****************************************
      renderCompaniesList(companies) {
        if(IsLoading(this, COMPANIES)){
            return <option value="">Cargando datos ...</option>;
        }  
        else{
            return [{}].concat(companies).map(
            (company, i) =>
            i !== 0 ?
            <option value={company.companyId}>{company.companyName}</option>
            :
            <option value="">Seleccione la compañía</option>
            );
        }
      }

      renderProductsList(products) {
        if(IsLoading(this, PRODUCTS)){
            return <option value="">Cargando datos ...</option>;
        }  
        else{
            return [{}].concat(products).map(
            (product, i) =>
            i !== 0 ?
            <option value={product.productId}>{product.productDescription}</option>
            :
            <option value="">Seleccione el producto financiero</option>
            );
        }
      }

      renderAssetsList(assets) {
        if(IsLoading(this, ASSETS)){
            return <option value="">Cargando datos ...</option>;
        }  
        else{
            return [{}].concat(assets).map(
            (asset, i) =>
            i !== 0 ?
                asset.enabled === '1'?
                    <option value={asset.assetId}>{asset.assetName}</option>
                :
                    ''
            :
            <option value="">Seleccione el activo a financiar</option>
            );
        }
      }

      renderAssetConditionList(assetcondition) {
        if(IsLoading(this, ASSETCONDITION)){
            return <option value="">Cargando datos ...</option>;
        }  
        else{
            return [{}].concat(assetcondition).map(
            (assetcond, i) =>
            i !== 0 ?
                <option value={assetcond.assetConditionId}>{assetcond.assetConditionDescription}</option>
            :
            <option value="">Seleccione la condición del activo</option>
            );
        }
      }

      renderCurrenciesList(currencies) {
        if(IsLoading(this, CURRENCIES)){
            return <option value="">Cargando datos ...</option>;
        }  
        else{
            return [{}].concat(currencies).map(
            (currency, i) =>
            i !== 0 ?
                <option value={currency.currencyId}>{currency.currencyName}</option>
            :
            <option value="">Seleccione la moneda</option>
            );
        }
      }

      renderCreditsList(credits) {
        if(IsLoading(this, CURRENCIES)){
            return <option value="">Cargando datos ...</option>;
        }  
        else{
            return [{}].concat(credits).map(
            (credit, i) =>
            i !== 0 ?
                <option value={credit.creditConfigurationId}>{credit.creditConfigurationName}</option>
            :
            <option value="">Seleccione la campaña</option>
            );
        }
      }


      renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to={{
            pathname: '/campaign',
            state: { formFields: this.state.formFields }
        }} />
        }
      }

      

      renderYearList(){
        return this.state.yearList.map(
            (year, i) =>
                year === this.getCurrentYear() 
                ?
                    <option value={year} selected>{year}</option>
                :
                    <option value={year}>{year}</option>
            
            );
      }

    renderSaveCampaignButton(){
        if(this.state.formFields["campaignName"]  !== ""){
            return <Button onClick={this.submitCampaignCreation.bind(this)}>Crear</Button>;
        }
        else{
            if(this.state.formFields["credit"] !== undefined && this.state.formFields["credit"] !== ""){
                return <div>
                        <Button onClick={this.submitCampaignUpdate.bind(this)}>Actualizar</Button>
                        <Button onClick={this.submitCampaignDeletion.bind(this)}>Eliminar</Button>
                    </div>;
            }
            else{
                return '';
            }
        }
         
    }
  
    renderCompanies() {
      return (
        <div className="campaings">
            <OverlayLoadingComponent isLoading={IsAComponentLoading(this)}>
                <Form onSubmit={this.setRedirect}>
                    <FormGroup controlId="formControlsSelect">

                        <Grid>
                            <Panel>
                                <Panel.Heading>Configuración de la campaña</Panel.Heading>
                                <Panel.Body>
                                    <Row className="show-grid">
                                        <Col sm={6}> 
                                            <ControlLabel>Seleccione la campaña</ControlLabel>
                                            <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="credit" 
                                                componentClass="select" 
                                                placeholder="select" 
                                                value={this.state.formFields['credit']}
                                                onChange={this.changeCampaignHandler}>
                                                {this.renderCreditsList(this.state.credit)}
                                            </FormControl>
                                        </Col>
                                        <Col sm={6}> 
                                            <ControlLabel>O digite un nombre de campaña para crearla</ControlLabel>
                                            <FormControl 
                                                name="campaignName" 
                                                type="text" 
                                                placeholder="Campaña" 
                                                value={this.state.formFields['campaignName']}
                                                onChange={this.changeCampaignNameHandler}/>
                                        </Col>
                                    </Row>
                                    <Row className="show-grid">
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Compañía</ControlLabel>
                                            <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="company" 
                                                componentClass="select" 
                                                placeholder="select" 
                                                value={this.state.formFields['company']}
                                                onChange={this.changeInputEventHandler}>
                                                {this.renderCompaniesList(this.state.companies)}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row className="show-grid">
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Producto financiero</ControlLabel>
                                            <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="product" 
                                                componentClass="select" 
                                                placeholder="select" 
                                                value={this.state.formFields['product']}
                                                onChange={this.changeInputEventHandler}>
                                                {this.renderProductsList(this.state.products)}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row className="show-grid">
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Activo a financiar</ControlLabel>
                                            <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="asset" 
                                                componentClass="select" 
                                                placeholder="select" 
                                                value={this.state.formFields['asset']}
                                                onChange={this.changeInputEventHandler}>
                                                {this.renderAssetsList(this.state.assets)}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row className="show-grid">
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Estado del activo</ControlLabel>
                                            <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="assetcondition" 
                                                componentClass="select" 
                                                placeholder="select" 
                                                value={this.state.formFields['assetcondition']}
                                                onChange={this.changeInputEventHandler}>
                                                {this.renderAssetConditionList(this.state.assetcondition)}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row className="show-grid">
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Moneda</ControlLabel>
                                            <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="currency" 
                                                componentClass="select" 
                                                placeholder="select" 
                                                value={this.state.formFields['currency']}
                                                onChange={this.changeInputEventHandler}>
                                                {this.renderCurrenciesList(this.state.currencies)}
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    <Row className="show-grid">
                                        <Col sm={6}>
                                            <ControlLabel>Períodos</ControlLabel>
                                            <Picky
                                                placeholder="No seleccionado"
                                                manySelectedPlaceholder="%s seleccionados"
                                                allSelectedPlaceholder="%s seleccionados"
                                                selectAllText="Todos"
                                                value={this.state.formFields['periods']}
                                                options={this.state.periods}
                                                onChange={this.selectMultipleOption}
                                                open={false}
                                                valueKey="periodId"
                                                labelKey="periodValue"
                                                multiple={true}
                                                includeSelectAll={true}
                                                includeFilter={false}
                                                dropdownHeight={200}
                                                disabled={false}
                                                />
                                        </Col>
                                        <Col sm={6}>
                                            <ControlLabel>Período por defecto</ControlLabel>
                                            <Picky
                                                placeholder="No seleccionado"
                                                manySelectedPlaceholder="%s seleccionados"
                                                allSelectedPlaceholder="%s seleccionados"
                                                selectAllText="Todos"
                                                value={this.state.formFields['defaultPeriod']}
                                                options={this.state.formFields['periods']}
                                                onChange={this.selectSingleOption}
                                                open={false}
                                                valueKey="periodId"
                                                labelKey="periodValue"
                                                multiple={false}
                                                includeSelectAll={true}
                                                includeFilter={false}
                                                dropdownHeight={200}
                                                />
                                        </Col>
                                    </Row>
                                    
                                    <Row className="show-grid">
                                        <hr></hr>

                                        <div style={{textAlign: 'center'}}>
                                            <p><strong>NOTA: </strong> Los períodos y el nombre de la campaña seleccionados durante la creación no son actualizables posteriormente</p>
                                        </div>

                                        <hr></hr>
                                        <Col xs={12} md={12}>
                                            <div style={{textAlign:"center"}}>
                                                {this.renderSaveCampaignButton()}
                                            </div>
                                        </Col>
                                    </Row>
                                </Panel.Body>
                                {this.renderCampaignDetails()}
                            </Panel>
                            
                        </Grid>
                    </FormGroup>
                </Form>
                
                {this.renderPremiumsByCreditModal()}
                {this.renderCreditDistributionModal()}
                {this.renderChargesByCreditModal()}
            </OverlayLoadingComponent>
        </div>
      );
    }

    renderPremiumsOption(){
        return (this.state.hasPremiums)?<Col sm={4}>
                    <Panel>
                        <Panel.Heading><div style={{textAlign:'center'}}>Configurar primas</div></Panel.Heading>
                        <Panel.Body>
                        <Grid fluid={true}>
                            <Row className="show-grid">
                                <div style={{textAlign:'center'}}><Button onClick={this.showPremiumsByCreditModalHandler} block>Configurar</Button></div>
                            </Row>
                        </Grid>
                        </Panel.Body>
                    </Panel>
                </Col>:
                <Col sm={4}>
                <Panel>
                    <Panel.Heading><div style={{textAlign:'center'}}>Configurar primas</div></Panel.Heading>
                    <Panel.Body>
                    <Grid fluid={true}>
                        <Row className="show-grid">
                            <div style={{textAlign:'center'}}><Button onClick={this.showPremiumsByCreditModalHandler} block>Crear</Button></div>
                        </Row>
                    </Grid>
                    </Panel.Body>
                </Panel>
            </Col>;
    }

    renderCreditDistributionOption(){
        return (this.state.hasCreditDistributions)?<Col sm={4}>
        <Panel>
            <Panel.Heading><div style={{textAlign:'center'}}>Configurar Distribución</div></Panel.Heading>
            <Panel.Body>
            <Grid fluid={true}>
                <Row className="show-grid">
                    <div style={{textAlign:'center'}}><Button onClick={this.showCreditDistributionModalHandler} block>Configurar</Button></div>
                </Row>
            </Grid>
            </Panel.Body>
        </Panel>
        </Col>:
        <Col sm={4}>
            <Panel>
                <Panel.Heading><div style={{textAlign:'center'}}>Configurar Distribución</div></Panel.Heading>
                <Panel.Body>
                    <Grid fluid={true}>
                        <Row className="show-grid">
                            <div style={{textAlign:'center'}}><Button onClick={this.showCreditDistributionModalHandler} block>Crear</Button></div>
                        </Row>
                    </Grid>
                </Panel.Body>
            </Panel>
        </Col>;
    }

    renderChargesOption(){
        return (this.state.hasCharges)?<Col sm={4}>
                    <Panel>
                        <Panel.Heading><div style={{textAlign:'center'}}>Configurar cargos</div></Panel.Heading>
                        <Panel.Body>
                        <Grid fluid={true}>
                            <Row className="show-grid">
                                <div style={{textAlign:'center'}}><Button onClick={this.showChargesByCreditModalHandler} block>Configurar</Button></div>
                            </Row>
                        </Grid>
                        </Panel.Body>
                    </Panel>
                </Col>:
                <Col sm={4}>
                <Panel>
                    <Panel.Heading><div style={{textAlign:'center'}}>Configurar cargos</div></Panel.Heading>
                    <Panel.Body>
                    <Grid fluid={true}>
                        <Row className="show-grid">
                            <div style={{textAlign:'center'}}><Button onClick={this.showChargesByCreditModalHandler} block>Crear</Button></div>
                        </Row>
                    </Grid>
                    </Panel.Body>
                </Panel>
            </Col>;
    }

    renderCampaignDetails(){

       return ( undefined !== this.state.formFields['credit'] && 
                null !== this.state.formFields['credit'] &&
                '' !== this.state.formFields['credit']
              )?
              <Grid fluid={true}>
                <Row className="show-grid">
                    {this.renderPremiumsOption()}
                    {this.renderCreditDistributionOption()}
                    {this.renderChargesOption()}

                </Row>
            </Grid>:'';
    }

    renderPremiumsByCreditModal(){
        return <PremiumsByCreditModal 
                    show={this.state.showPremiumsByCreditModal}
                    periods={this.state.formFields['periods']}
                    premiumsByPeriod={this.state.premiumsByPeriod}
                    selectedCredit={this.state.formFields['credit']} 
                    hidePremiumsByCreditModalHandler={this.hidePremiumsByCreditModalHandler}/>;
    }

    renderChargesByCreditModal(){
        var currencyName;
        let cur = this.state.currencies.find(e => e.currencyId === this.state.formFields['currency']);
        if(cur){
            currencyName = cur.currencyName;
        }
        
        return <ChargesByCreditModal 
                    show={this.state.showChargesByCreditModal}
                    periods={this.state.formFields['periods']}
                    premiumsByPeriod={this.state.premiumsByPeriod}
                    selectedCredit={this.state.formFields['credit']} 
                    currency={currencyName}
                    creditCharges={this.state.creditCharges}
                    hideChargesByCreditModalHandler={this.hideChargesByCreditModalHandler}/>;
    }

    renderCreditDistributionModal(){//TODO
        return <CreditDistributionModal 
                    show={this.state.showCreditDistributionModal}
                    periods={this.state.formFields['periods']}
                    creditDistributions={this.state.creditDistributions}
                    selectedCredit={this.state.formFields['credit']} 
                    hideCreditDistributionModalHandler={this.hideCreditDistributionModalHandler}
                    parentHandle={this}/>;
    }

  
    render() {
        
        return (
            <div className="Home">
                {this.renderRedirect()}
                {this.renderCompanies()}
            </div>
        );
        
    }


  }