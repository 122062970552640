import React, { Component } from "react";
import { FormControl} from "react-bootstrap";
import { API } from "aws-amplify";
import "./ComboAssets.css";
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';


export default class ComboAssets extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          company: props.companyId,
          assets: [], 
          parentHandle: props.parentHandle
        };
      }

      handleChange (e) {
          this.props.handler(e);
      }

      async componentDidUpdate(prevProps) {
          if(this.props.companyId !== prevProps.companyId){
            AddLoadingComponent(this.state.parentHandle, 'assets');
            this.setState({company: this.props.companyId})
            const result = await this.queryAssetsByCompany(this.props.companyId);
            this.setState({assets: result})
            RemoveLoadingComponent(this.state.parentHandle, 'assets');
          }
      } 

      async queryAssetsByCompany(companyId) {
        AddLoadingComponent(this.state.parentHandle, 'assets');
        this.setState({isLoading : true});
        if(undefined !== companyId) {
          const response = await API.get("alticaapi", "/assets/" + companyId);
          this.setState({isLoading : false});
          RemoveLoadingComponent(this.state.parentHandle, 'assets');
          return response;
        }
        else{
          RemoveLoadingComponent(this.state.parentHandle, 'assets');
          return [];
        }
      }

      renderAssetsList(assets) {

        if(this.state.isLoading){
            return <option value="">Cargando datos ...</option>;
        }
        else{
            return [{}].concat(assets).map(
            (asset, i) =>
            i !== 0 ?
                (asset.enabled === "1")?
                <option value={asset.assetId}>{asset.assetName}</option>
                :
                ""
            :
            <option value="">Seleccione el tipo de activo</option>
            );
        }
      }

      render(){
          return (
                
                <FormControl
                    name="asset"
                    inputRef={ el => this.inputEl=el }
                    componentClass="select" placeholder="select" onChange={this.handleChange.bind(this)}>
                    {this.renderAssetsList(this.state.assets)}
                </FormControl>
                
          );
      }
}