import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import AppWithAuth from './containers/AppWithAuth';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from "react-router-dom";

//ReactDOM.render(<App />, document.getElementById('root'));
ReactDOM.render(
    <Router>
      <AppWithAuth />
    </Router>,
    document.getElementById("root")
  );

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
