import React from "react";
import { SignIn, ConfirmSignIn, ForgotPassword,RequireNewPassword, ConfirmSignUp, SignUp, VerifyContact, TOTPSetup, Loading} from "aws-amplify-react";
import config from "../aws-exports";
import { CustomSignIn } from "../containers/CustomSignIn";
import App from "../App";
import { Authenticator } from "aws-amplify-react/dist/Auth";
import MyGreetings from '../containers/MyGreetings';


const MyTheme = {
  Button: { 'backgroundColor': 'red', 'borderColor': 'red'  }
}

class AppWithAuth extends React.Component {
  constructor(props, context) {
    super(props, context);
  }

 

  render() {
    return (
      <div>
        <Authenticator hide={[SignIn]} amplifyConfig={config} hideDefault={true}>
        <MyGreetings
              inGreeting={(username) => 'Hola ' + username}
              outGreeting="Por favor ingrese su usuario y contraseña..."
              theme={MyTheme}
          />
          <CustomSignIn />
          <ConfirmSignIn/>
          <RequireNewPassword/>
          <SignUp/>
          <ConfirmSignUp/>
          <VerifyContact/>
          <ForgotPassword/>
          <TOTPSetup/>
          <Loading/>
          <App />
        </Authenticator>
      </div>
    );
  }
}

export default AppWithAuth;