import React, { Component } from "react";
import { Grid, Form, FormGroup, Row, Col, ControlLabel,InputGroup,FormControl, Panel, Checkbox, Collapse} from "react-bootstrap";
import { Redirect } from 'react-router-dom'
import ComboCredits from "../components/ComboCredits.js";
import SliderPeriods from "../components/SliderPeriods";
import PremiumInput from "../components/PremiumInput";
import CreditDistribution from "../components/CreditDistribution";
import CreditCharges from "../components/CreditCharges";
import PaymentTablePanel from "../components/PaymentTablePanel";
import OverlayLoadingComponent from "../components/OverlayLoadingComponent";
import "./Campaign.css";
import {IsAComponentLoading} from '../loadingutils';


export default class Campaign extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        formFields: props.location.state.formFields, 
        show : false,
        chargesList: [], 
        showPaymentTable: false, 
        loadingComponents: []
      };

      this.state.formFields['fixedInsurance']=0;
      this.state.formFields['insuranceAmount']=0;

      this.changeInputEventHandler = this.changeInputEventHandler.bind(this);
      this.changePeriodEventHandler = this.changePeriodEventHandler.bind(this);
      this.changePremiumEventHandler = this.changePremiumEventHandler.bind(this);
      this.chargesListHandler = this.chargesListHandler.bind(this);
      this.changeRealAmountHandler = this.changeRealAmountHandler.bind(this); 
      this.changeCheckEventHandler = this.changeCheckEventHandler.bind(this);     
    }

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeCheckEventHandler(event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal[event.target.name] = this.state.formFields[event.target.name] === 1 ? 0 : 1;
        this.setState({formFields: formFieldsLocal});
    }

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeInputEventHandler(event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal[event.target.name] = event.target.value;
        if('select-one' === event.target.type){
            formFieldsLocal[event.target.name + "Name"] = this.getSelectText(event);
        }
        this.setState({formFields: formFieldsLocal});
    }

    getSelectText(event){
        var index = event.nativeEvent.target.selectedIndex;
        return event.nativeEvent.target[index].text;
    }


    chargesListHandler(charges){
        this.setState({chargesList:charges});
    }

    changePeriodEventHandler(period, event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal["period"] = event;
        if(undefined !== period && undefined !== period.period  && null !== period){
            formFieldsLocal["periodValue"] = period.period.periodValue;
        }
        this.setState({formFields: formFieldsLocal});
    }

    changePremiumEventHandler(event){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal["premium"] = event;
        this.setState({formFields: formFieldsLocal});
    }

    changeRealAmountHandler(realAmount){
        if(undefined !== realAmount && null !== realAmount){
            let formFieldsLocal = this.state.formFields;
            formFieldsLocal["realAmount"] = realAmount;
            this.setState({formFields: formFieldsLocal});
        }
    }

    setRedirect = () => {
        this.setState({
          redirect: true
        })
      }

      renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to={{
            pathname: '/campaign',
            state: { formFields: this.state.formFields }
        }} />
        }
      }

      handleClose(){
        this.setState({show : false})
      }

      handleShow(){
        this.setState({show : true})
      }


    renderForm(){
        return (
            <OverlayLoadingComponent isLoading={IsAComponentLoading(this)}>
                <div className="companies">
                    <Form onSubmit={this.setRedirect}>
                        <FormGroup controlId="formControlsSelect">
        
                            <Grid>
                                <Panel>
                                    <Panel.Heading>Información del crédito</Panel.Heading>
                                    <Panel.Body>
                                        <Row className="show-grid">
                                            <Col xs={12} md={12}> 
                                                <ControlLabel>Campaña</ControlLabel>
                                                <ComboCredits 
                                                    name="asset" 
                                                    companyId={this.state.formFields["company"]} 
                                                    assetId={this.state.formFields["asset"]} 
                                                    assetCondition={this.state.formFields["assetCondition"]} 
                                                    product={this.state.formFields["product"]} 
                                                    currency={this.state.formFields["currency"]} 
                                                    handler={this.changeInputEventHandler}
                                                    parentHandle={this}>
                                                </ComboCredits>
                                            </Col>
                                        </Row>
                                        <Row className="show-grid">
                                        <hr/>
                                            <Col xs={12} md={12}> 
                                               
                                                <ControlLabel>Seleccione Plazo</ControlLabel>
                                                <SliderPeriods 
                                                    credit={this.state.formFields["campaign"]}
                                                    handler={this.changePeriodEventHandler}
                                                    parentHandle={this}>
                                                </SliderPeriods>
                                                <span>&nbsp;</span>
                                               
                                            </Col>
                                        </Row>
                                        
                                        <Row className="show-grid">
                                        <hr/>
                                                <PremiumInput 
                                                    credit={this.state.formFields["campaign"]}
                                                    period={this.state.formFields["period"]}
                                                    year={this.state.formFields["assetYear"]}
                                                    price={this.state.formFields["price"]}
                                                    currency={this.state.formFields["currencyValue"]} 
                                                    chargesList={this.state.chargesList}
                                                    handler={this.changePremiumEventHandler}
                                                    realAmountHandler={this.changeRealAmountHandler}
                                                    parentHandle={this}>
                                                </PremiumInput>
                                            
                                        </Row>

                                        <Row className="show-grid">
                                        <hr/>
                                            <Col xs={12} md={12}> 
                                               
                                                
                                                <Checkbox inline
                                                        name="fixedInsurance"
                                                        checked={this.state.formFields['fixedInsurance']}
                                                        onChange={this.changeCheckEventHandler}>
                                                            <ControlLabel>Seguro Monto Fijo</ControlLabel>
                                                            
                                                </Checkbox>
                                                <Collapse in={this.state.formFields["fixedInsurance"]}>
                                                    <Row className="show-grid">
                                                        
                                                        <Col xs={3} md={3}>
                                                            <ControlLabel>Digite Monto Fijo Seguro</ControlLabel>
                                                            <InputGroup>
                                                                <FormControl 
                                                                    name="insuranceAmount" 
                                                                    type="number" 
                                                                    value={this.state.formFields["insuranceAmount"]}
                                                                    onChange={this.changeInputEventHandler}
                                                                />
                                                                <InputGroup.Addon>{this.state.formFields["currencyValue"]}</InputGroup.Addon>
                                                            </InputGroup>
                                                        </Col>
                                                    </Row>
                                                </Collapse>
                                               
                                                <span>&nbsp;</span>
                                               
                                            </Col>
                                        </Row>

                                        <Row className="show-grid">
                                            <hr></hr>
                                            <Col xs={12} md={12}>
                                                <ControlLabel>Distribución del crédito</ControlLabel>
                                                <CreditDistribution
                                                    credit={this.state.formFields["campaign"]}
                                                    period={this.state.formFields["period"]}
                                                    price={this.state.formFields["price"]}
                                                    premium={this.state.formFields["premium"]}
                                                    handler={this.changeInputEventHandler}
                                                    parentHandle={this}>
                                                ></CreditDistribution>
                                            </Col>
                                        </Row>

                                        <Row className="show-grid">
                                            <hr></hr>
                                            <Col xs={12} md={12}>
                                                <ControlLabel>Cargos asociados</ControlLabel>
                                                <CreditCharges
                                                credit={this.state.formFields["campaign"]}
                                                period={this.state.formFields["period"]}
                                                price={this.state.formFields["price"]}
                                                premium={this.state.formFields["premium"]}
                                                chargesListHandler={this.chargesListHandler}
                                                parentHandle={this}
                                                ></CreditCharges>
                                            </Col>
                                        </Row>
                                        
                                    </Panel.Body>
                                </Panel>
                                <Panel>
                                    <Panel.Heading>Tabla de pagos</Panel.Heading>
                                    <Panel.Body>
                                        <PaymentTablePanel
                                            formFields={this.state.formFields}
                                            credit={this.state.formFields["campaign"]}
                                            period={this.state.formFields["period"]}
                                            price={this.state.formFields["price"]}
                                            premium={this.state.formFields["premium"]}
                                            realAmount={this.state.formFields["realAmount"]}
                                            year={this.state.formFields["assetYear"]}
                                            fixedInsurance={this.state.formFields["fixedInsurance"]}
                                            insuranceAmount={this.state.formFields["insuranceAmount"]}
                                            renderPaymentTable={this.state.showPaymentTable}
                                            chargesList={this.state.chargesList}
                                            handler={this.changeInputEventHandler}
                                            parentHandle={this}> 
                                        </PaymentTablePanel>
                                        </Panel.Body>
                                </Panel>
                            </Grid>
                        </FormGroup>
                    </Form>
                </div>
            </OverlayLoadingComponent>
        )
    }

    render() {
        return (
            <div className="Home">
                {this.renderRedirect()}
                {this.renderForm()}
            </div>
        );
        
    }
}