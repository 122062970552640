export function AddLoadingComponent(thisHandle, componentName){
    let localComponents = thisHandle.state.loadingComponents;
    localComponents.push(componentName);
    thisHandle.setState({loadingComponents:localComponents});
}

export function RemoveLoadingComponent(thisHandle, componentName){
    let localComponents = thisHandle.state.loadingComponents;
    let index = localComponents.indexOf(componentName);
    if (index > -1) {
        localComponents.splice(index, 1);
    }
    thisHandle.setState({loadingComponents:localComponents});
}

export function IsAComponentLoading(thisHandle){
    return thisHandle.state.loadingComponents.length > 0;
}

export function IsLoading(thisHandle, component){
    return thisHandle.state.loadingComponents.indexOf(component) > -1;
}