import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button,InputGroup} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./BaseRates.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'
import {RenderPercentageValue} from '../utils';


export default class BaseRates extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedBaseRateId: null,
        baseRateDescription: '',
        baseRatePercentage: 0,
        baseRateVariable: 1,
        isEnabled: false,
        errorDescription: '',
        showErrorAlert: false,
        baseRates: []
      };
    }

    //================================= Lógica de negocio =========================================

    renderBaseRateType(baseRateType){
        if(1 === baseRateType){
            return 'Variable';
        }
        if(0 === baseRateType){
            return 'Fija';
        }
        return 'No definido';
    }

    handleBaseRatePercentageChange(event){
        this.setState({baseRatePercentage:event.target.value});
    }

    handleBaseRateDescriptionChange(event){
        this.setState({baseRateDescription:event.target.value});
    }

    handleBaseRateVariableChange(event){
        this.setState({baseRateVariable:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreateBaseRate(event){
        this.createBaseRate(this.state.baseRateDescription, this.state.baseRatePercentage, this.state.baseRateVariable);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdateBaseRate(event){
        this.updateBaseRate(this.state.selectedBaseRateId, this.state.baseRateDescription, this.state.baseRatePercentage, this.state.baseRateVariable);
    }

    handleDeleteBaseRate(event){
        this.deleteBaseRate(this.state.selectedBaseRateId);
    }

    async refreshBaseRates() {
        this.queryBaseRates();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryBaseRates();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                baseRateDescription: '',
                baseRatePercentage: '',
                baseRateVariable: '',
                selectedBaseRateId: null
              });
        }
        else{
        this.setState({
            selected: rowInfo.index,
            baseRateDescription: rowInfo.row._original.baseRateDescription,
            baseRatePercentage: rowInfo.row._original.baseRatePercentage,
            baseRateVariable: rowInfo.row._original.baseRateVariable,
            selectedBaseRateId: rowInfo.row._original.baseRateId
          });
        }
    }

    //================================= CRUD =========================================
    async queryBaseRates() {
        try {
            this.setState({isLoading : true});
            const baseRates = await API.get("alticaapi", "/baserates");
            this.setState({ baseRates });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createBaseRate(baseRateDescription, baseRatePercentage, baseRateVariable) {
        let baseRateInit = {
            body: {"name":baseRateDescription, "percentage": baseRatePercentage, "isvariable": baseRateVariable}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/baserates", baseRateInit);
            this.setState({isLoading:false, baseRateDescription:'', baseRatePercentage:'', baseRateVariable: ''});
            this.refreshBaseRates();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updateBaseRate(baseRateId, baseRateDescription, baseRatePercentage, baseRateVariable) {
        let baseRateInit = {
            body: {"baserate":baseRateId, "name":baseRateDescription, "percentage": baseRatePercentage, "isvariable": baseRateVariable}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/baserates", baseRateInit);
            this.setState({isLoading:false, baseRateDescription:'', baseRatePercentage:'', baseRateVariable: '', selectedAssetId: null, selected: null});
            this.refreshBaseRates();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deleteBaseRate(baseRateId) {
        let baseRateInit = {
            body: {"baserate":baseRateId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/baserates", baseRateInit);
            this.setState({isLoading:false, baseRateDescription:'', baseRatePercentage:'', baseRateVariable: '', selectedAssetId: null, selected: null, selectedBaseRateId: null});
            this.refreshBaseRates();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de rendering =========================================
    renderBaseRatesTable(baseRates){
        const columns = [{
            id: 'identification',
            Header: 'Identificador',
            accessor: 'baseRateId', 
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          }, {
            id: 'description',
            Header: 'Descripción',
            accessor: 'baseRateDescription'
          }, {
            id: 'percentage',
            Header: 'Porcentaje',
            accessor: 'baseRatePercentage', 
            Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
          }, {
            id: 'rateType',
            Header: 'Tipo de tasa',
            accessor: 'baseRateVariable',
            Cell: row => <div style={{ textAlign: "center" }}>{this.renderBaseRateType(row.value)}</div>
          }

        ];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== baseRates) && (baseRates.length > 0)?
                <ReactTable
                data={baseRates}
                noDataText="No hay datos."
                columns={columns}
                showPagination={true}
                defaultPageSize={5}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron tasas base en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedBaseRateId && undefined !== this.state.selectedBaseRateId){
            return <Col sm={12} smOffset={4}>
                <Button name="updateButton" onClick={this.handleUpdateBaseRate.bind(this)}>Actualizar tasa base</Button> 
                <Button name="deleteButton" onClick={this.handleDeleteBaseRate.bind(this)}>Eliminar tasa base</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreateBaseRate.bind(this)}>Crear tasa base</Button>
                </Col>;
        }
    }

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }

    renderBaseRateTypesOptions() {
        let baseRateTypes = [
            {baseRateType:1,baseRateDescription:"Variable"},
            {baseRateType:0,baseRateDescription:"Fija"}]
        return [].concat(baseRateTypes).map(
        (baseRate, i) => this.renderBaseRateOption(baseRate));
        
      }

    renderBaseRateOption(baseRate){
        if(undefined !== this.state.baseRateVariable && baseRate.baseRateType === this.state.baseRateVariable){
            return <option value={baseRate.baseRateType} selected>{baseRate.baseRateDescription}</option>;
        }
        else{
            return <option value={baseRate.baseRateType}>{baseRate.baseRateDescription}</option>;
        }
    }

    renderWarningMessage(){
        if(null !== this.state.selectedBaseRateId && undefined !== this.state.selectedBaseRateId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina una tasa base se eliminarán todas las configuraciones asociadas a la misma en todas las campañas.</p>
        </div>
        }
        
    }

    renderBaseRatesForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de Tasas Base</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                                <FormGroup controlId="baseRatesFormDescription">
                                    <Col sm={12}>
                                        <ControlLabel>Descripción de tasa</ControlLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FormControl 
                                            type="text" 
                                            placeholder="Descripción" 
                                            value={this.state.baseRateDescription} 
                                            onChange={this.handleBaseRateDescriptionChange.bind(this)} />
                                    </Col>
                                </FormGroup>
                                <FormGroup controlId="baseRatesFormPercentage">
                                    <Col sm={12}>
                                        <ControlLabel>Porcentaje</ControlLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <InputGroup>
                                            <FormControl 
                                                type="text" 
                                                placeholder="Porcentaje" 
                                                value={this.state.baseRatePercentage} 
                                                onChange={this.handleBaseRatePercentageChange.bind(this)} />
                                                <InputGroup.Addon>%</InputGroup.Addon>
                                        </InputGroup>
                                    </Col>
                                </FormGroup>
                                <FormGroup controlId="baseRatesFormPercentage">
                                    <Col sm={12}>
                                        <ControlLabel>Tipo de Tasa</ControlLabel>
                                    </Col>
                                    <Col sm={12}>
                                        <FormControl
                                            name="baseRateType" 
                                            inputRef={ el => this.inputEl=el }
                                            componentClass="select" placeholder="select" onChange={this.handleBaseRateVariableChange.bind(this)}>
                                            {this.renderBaseRateTypesOptions()}
                                        </FormControl> 
                                    </Col>
                                </FormGroup>
                            <hr></hr>
                            <FormGroup controlId="assetsFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Tasas Base Existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderBaseRatesTable(this.state.baseRates)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderBaseRatesForm();
    }
}