import React, { Component } from "react";
import {Alert} from "react-bootstrap";

export default class ErrorAlert extends Component {
    constructor(props) {
      super(props);
      this.state = {
        showErrorAlert: this.props.showErrorAlert, 
        errorDescription: this.props.errorDescription
      };
    }

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <Alert bsStyle="danger">
                <strong>Ha ocurrido un error: {this.state.errorDescription}</strong>
            </Alert>;
        }
        return '';
    }

    render(){
        return this.renderErrorAlert();
    }
}

