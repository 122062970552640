import React, { Component } from "react";
import { Grid, Form, FormGroup, FormControl, Col, ControlLabel, Panel, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import "./Home.css";
import ComboAssets from "../components/ComboAssets.js";
import ComboAssetCondition from "../components/ComboAssetCondition.js";
import ComboProducts from "../components/ComboProducts.js";
import ComboCurrencies from "../components/ComboCurrencies.js";
import { Redirect } from 'react-router-dom'
import OverlayLoadingComponent from "../components/OverlayLoadingComponent"
import {AddLoadingComponent, RemoveLoadingComponent, IsAComponentLoading} from '../loadingutils';
import {AddInvalidInput, IsThereAnInvalidInput, ValidateInput, ValidateStringIdentifier, ValidateEmail, ValidatePhoneNumber, NonEmptyValue, ValidateAmount} from '../utils'


const validationsArray = {
    clientName: ValidateStringIdentifier,
    clientLastName: ValidateStringIdentifier,
    clientEmail: ValidateEmail, 
    clientPhoneNumber:ValidatePhoneNumber,
    price: ValidateAmount,
    company: NonEmptyValue,
    asset: NonEmptyValue,
    assetCondition: NonEmptyValue,
    product: NonEmptyValue,
    currency: NonEmptyValue
}

export default class Home extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        companies: [],
        formFields:{},
        selectedCompany: null,
        selectedAssetId: null,
        selectedAssetCondition: null,
        yearList: this.getYearList(), 
        redirect: false, 
        loadingComponents: [],
        validations: []
      };

      this.state.formFields["assetYear"] = this.getCurrentYear();
      this.changeInputEventHandler = this.changeInputEventHandler.bind(this);
      this.changeCurrencyValue = this.changeCurrencyValue.bind(this);
      AddInvalidInput("clientName", this.state.validations, this);
      AddInvalidInput("clientLastName", this.state.validations, this);
      AddInvalidInput("clientEmail", this.state.validations, this);
      AddInvalidInput("clientPhoneNumber", this.state.validations, this);
      AddInvalidInput("price", this.state.validations, this);
      AddInvalidInput("company", this.state.validations, this);
      AddInvalidInput("asset", this.state.validations, this);
      AddInvalidInput("assetCondition", this.state.validations, this);
      AddInvalidInput("product", this.state.validations, this);
      AddInvalidInput("currency", this.state.validations, this);
      
    }

    //================================= Métodos de validación ========================================

    validationResult(inputName){
        if(this.state.validations.indexOf(inputName) >= 0){
            return "error";
        }
        return "success";
    }
    

    setRedirect = () => {
        this.setState({
          redirect: true
        })
      }

      renderRedirect = () => {
        if (this.state.redirect) {
          return <Redirect to={{
            pathname: '/campaign',
            state: { formFields: this.state.formFields }
        }} />
        }
      }
    

    changeCurrencyValue(currencyValue){
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal["currencyValue"] = currencyValue;
        this.setState({formFields: formFieldsLocal});
    }

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeInputEventHandler(event){
        ValidateInput(event.target.name, event.target.value,validationsArray, this.state.validations, this);
        let formFieldsLocal = this.state.formFields;
        formFieldsLocal[event.target.name] = event.target.value;
        if('select-one' === event.target.type){
            formFieldsLocal[event.target.name + "Name"] = this.getSelectText(event);
        }
        
        this.setState({formFields: formFieldsLocal});
    }

    getSelectText(event){
        var index = event.nativeEvent.target.selectedIndex;
        return event.nativeEvent.target[index].text;
    }
    
    async componentDidMount() {
        try {
          AddLoadingComponent(this, 'companies');
          this.setState({isLoading : true});
          const companies = await this.companies();
          this.setState({ companies });
        } catch (e) {
          alert(e);
        }
        this.setState({ isLoading: false });
        RemoveLoadingComponent(this, 'companies');
      }

      renderCompaniesList(companies) {
        if(this.state.isLoading){
            return <option value="">Cargando datos ...</option>;
        }  
        else{
            return [{}].concat(companies).map(
            (company, i) =>
            i !== 0 ?
            <option value={company.companyId}>{company.companyName}</option>
            :
            <option value="">Seleccione la compañía</option>
            );
        }
      }

      renderYearList(){
        return this.state.yearList.map(
            (year, i) =>
                year === this.getCurrentYear() 
                ?
                    <option value={year} selected>{year}</option>
                :
                    <option value={year}>{year}</option>
            
            );
      }


    getCurrentYear(){
        let d = new Date();
        return d.getFullYear();
    }

    getYearList(){
        var yearList = [];
        var yearCounter = this.getCurrentYear();
        var negativeYearCounter = this.getCurrentYear();
        var i;
        yearList.push(this.getCurrentYear());
        for (i = 0; i < 4; i++) {
            yearList.unshift(--negativeYearCounter);
        }
        yearList.push(++yearCounter);
        return yearList;
    }


    companies() {
        return API.get("alticaapi", "/companies");
    }
  
    renderCompanies() {
      return (
        <OverlayLoadingComponent isLoading={IsAComponentLoading(this)}>
            <div className="companies">
                <Form>
                    <FormGroup controlId="formControlsSelect">

                        <Grid>
                            <Panel>
                                <Panel.Heading>Información del cliente</Panel.Heading>
                                <Panel.Body>
                                    <FormGroup controlId="clientNameFG" validationState={this.validationResult("clientName")}>
                                        <Col xs={12} md={12}> 
                                            <ControlLabel>Nombre del cliente</ControlLabel>
                                            <FormControl name="clientName" type="text" placeholder="Nombre" onChange={this.changeInputEventHandler}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="clientLastNameFG" validationState={this.validationResult("clientLastName")}>
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Apellidos del cliente</ControlLabel>
                                            <FormControl name="clientLastName" type="text" placeholder="Apellidos"  onChange={this.changeInputEventHandler}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="emailFG" validationState={this.validationResult("clientEmail")}>
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Correo electrónico</ControlLabel>
                                            <FormControl name="clientEmail" type="email" placeholder="Correo"  onChange={this.changeInputEventHandler}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="clientPhoneNumberFG" validationState={this.validationResult("clientPhoneNumber")}>
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Teléfono del cliente</ControlLabel>
                                            <FormControl name="clientPhoneNumber" type="number" placeholder="Telefono"  onChange={this.changeInputEventHandler}/>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="clientEmploymentFG">
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Tipo de empleo</ControlLabel>
                                            <FormControl
                                                name="clientEmployment"
                                                inputRef={ el => this.inputEl=el }
                                                componentClass="select" placeholder="select"
                                                onChange={this.changeInputEventHandler}>
                                                <option value='Asalariado' selected>Asalariado</option>
                                                <option value='Independiente'>Independiente</option>
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                </Panel.Body>
                            </Panel>
                            <Panel>
                                <Panel.Heading>Configuración del préstamo</Panel.Heading>
                                <Panel.Body>
                                    <FormGroup controlId="companyFG" validationState={this.validationResult("company")}>
                                       <Col xs={12} md={12}>
                                            <ControlLabel>Compañía</ControlLabel>
                                            <FormControl 
                                                inputRef={ el => this.inputEl=el }
                                                name="company" componentClass="select" placeholder="select" onChange={this.changeInputEventHandler}>
                                                {this.renderCompaniesList(this.state.companies)}
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="assetFG" validationState={this.validationResult("asset")}>
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Bien a financiar</ControlLabel>
                                            <ComboAssets 
                                                name="asset" 
                                                companyId={this.state.formFields["company"]} 
                                                handler={this.changeInputEventHandler}
                                                parentHandle={this}>
                                            </ComboAssets>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="assetConditionFG" validationState={this.validationResult("assetCondition")}>
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Estado del bien</ControlLabel>
                                            <ComboAssetCondition 
                                                companyId={this.state.formFields["company"]} 
                                                assetId={this.state.formFields["asset"]} 
                                                handler={this.changeInputEventHandler}
                                                parentHandle={this}>
                                            </ComboAssetCondition>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="yearFG">
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Año</ControlLabel>
                                            <FormControl
                                                name="assetYear"
                                                inputRef={ el => this.inputEl=el }
                                                componentClass="select" placeholder="select"
                                                onChange={this.changeInputEventHandler}>
                                                {this.renderYearList()}
                                            </FormControl>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="productFG" validationState={this.validationResult("product")}>
                                        <Col xs={12} md={12}>
                                            <ControlLabel>Producto financiero</ControlLabel>
                                            <ComboProducts 
                                                companyId={this.state.formFields["company"]} 
                                                assetId={this.state.formFields["asset"]} 
                                                assetCondition={this.state.formFields["assetCondition"]}
                                                handler={this.changeInputEventHandler}
                                                parentHandle={this}>
                                            </ComboProducts>
                                        </Col>
                                    </FormGroup>
                                    <FormGroup controlId="amount">
                                        <Col xs={9} md={9}>
                                            <FormGroup controlId="priceFG" validationState={this.validationResult("price")}>
                                                <ControlLabel>Precio</ControlLabel>
                                                <FormControl name="price" type="number" placeholder="Precio"  onChange={this.changeInputEventHandler}/>
                                            </FormGroup>
                                        </Col>
                                    
                                    
                                        <Col xs={3} md={3}>
                                            <FormGroup controlId="currency" validationState={this.validationResult("currency")}>
                                                <ControlLabel>Moneda</ControlLabel>
                                                <ComboCurrencies
                                                    companyId={this.state.formFields["company"]} 
                                                    assetId={this.state.formFields["asset"]} 
                                                    assetCondition={this.state.formFields["assetCondition"]}
                                                    product={this.state.formFields["product"]}
                                                    handler={this.changeInputEventHandler}
                                                    currencyHandler={this.changeCurrencyValue}
                                                    parentHandle={this}>
                                                </ComboCurrencies>
                                            </FormGroup>
                                        </Col>
                                    </FormGroup>
                                    
                                    <FormGroup>
                                        <Col xs={12} md={12}>
                                            <Button onClick={this.setRedirect} disabled={IsThereAnInvalidInput(this.state.validations)}>Cotizar</Button>
                                        </Col>
                                    </FormGroup>
                                </Panel.Body>
                            </Panel>
                        </Grid>
                    </FormGroup>
                </Form>
            </div>
        </OverlayLoadingComponent>
      );
    }

  
    render() {
        
        return (
            <div className="Home">
                {this.renderRedirect()}
                {this.renderCompanies()}
            </div>
        );
        
    }


  }