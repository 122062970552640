import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./Currencies.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'


export default class Currencies extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedCurrencyId: null,
        currencyName: '',
        isEnabled: false,
        errorDescription: '',
        showErrorAlert: false,
        currencies: []
      };
    }

    //================================= Lógica de negocio =========================================

    handleCurrencyNameChange(event){
        this.setState({currencyName:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreateCurrency(event){
        this.createCurrency(this.state.currencyName);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdateCurrency(event){
        this.updateCurrency(this.state.selectedCurrencyId, this.state.currencyName);
    }

    handleDeleteCurrency(event){
        this.deleteCurrency(this.state.selectedCurrencyId);
    }

    async refreshCurrencies() {
        this.queryCurrencies();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryCurrencies();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                currencyName: '',
                selectedCurrencyId: null
              });
        }
        else{
        this.setState({
            selected: rowInfo.index,
            currencyName: rowInfo.row._original.currencyName,
            selectedCurrencyId: rowInfo.row._original.currencyId
          });
        }
    }

    //================================= CRUD =========================================
    async queryCurrencies() {
        try {
            this.setState({isLoading : true});
            const currencies = await API.get("alticaapi", "/currencies");
            this.setState({ currencies: currencies });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createCurrency(currencyName) {
        let currencyInit = {
            body: {"name":currencyName}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/currencies", currencyInit);
            this.setState({isLoading:false, currencyName:''});
            this.refreshCurrencies();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updateCurrency(currencyId, currencyName) {
        let currencyInit = {
            body: {"currency":currencyId, "name":currencyName}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/currencies", currencyInit);
            this.setState({isLoading:false, currencyName:'',selectedCurrencyId: null, selected: null});
            this.refreshCurrencies();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deleteCurrency(currencyId) {
        let currencyInit = {
            body: {"currency":currencyId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/currencies", currencyInit);
            this.setState({isLoading:false, currencyName:'', selectedCurrencyId: null, selected: null});
            this.refreshCurrencies();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de rendering =========================================
    renderCurrenciesTable(currencies){
        const columns = [{
            id: 'Identificador',
            Header: 'Identificador',
            accessor: 'currencyId'
          }, {
            id: 'CurrencyName',
            Header: 'Moneda',
            accessor: 'currencyName'
          }
        ];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== currencies) && (currencies.length > 0)?
                <ReactTable
                data={currencies}
                columns={columns}
                showPagination={true}
                defaultPageSize={5}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron monedas en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedCurrencyId && undefined !== this.state.selectedCurrencyId){
            return <Col sm={12} smOffset={4}>
                <Button name="updateButton" onClick={this.handleUpdateCurrency.bind(this)}>Actualizar Moneda</Button>
                <Button name="deleteButton" onClick={this.handleDeleteCurrency.bind(this)}>Eliminar Moneda</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreateCurrency.bind(this)}>Crear Moneda</Button>
                </Col>;
        }
    }

    renderWarningMessage(){
        if(null !== this.state.selectedCurrencyId && undefined !== this.state.selectedCurrencyId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina una moneda se eliminarán todas las configuraciones asociadas al mismo en todas las campañas.</p>
        </div>
        }
        
    }

    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }

    renderCurrenciesForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de Monedas</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup controlId="CurrenciesFormName">
                                <Col sm={11}>
                                    <ControlLabel>Moneda</ControlLabel>
                                </Col>
                                <Col sm={11}>
                                    <FormControl type="text" placeholder="Moneda" maxLength="3" value={this.state.currencyName} onChange={this.handleCurrencyNameChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <hr></hr>
                            <FormGroup controlId="CurrenciesFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Monedas existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderCurrenciesTable(this.state.currencies)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderCurrenciesForm();
    }
}