export function RenderPercentageValue(value){
    if(undefined !== value && value > 0){
     return value + "%";
    }
    else{
     return "-";
    }
  }


  export function GetCurrentYear() {
    let d = new Date();
    return d.getFullYear();
  }

export function FormatNumber(number) {
    
    let num = Number(number);
    if(undefined !== num && !isNaN(num)){
      var num_parts = num.toFixed(2).toString().split(".");
      num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return num_parts.join(".");
    }
    else {
      return '-';
    }
  }

  export function AddInvalidInput(inputName, validations, thisHandle){
    if(validations.indexOf(inputName) < 0){
        validations.push(inputName);
        thisHandle.setState({validations});
    }
  }

  export function RemoveInvalidInput(inputName, validations, thisHandle){
      if(undefined !== validations){
        let index = validations.indexOf(inputName);
        if (index > -1) {
            validations.splice(index, 1);
        }
        thisHandle.setState({validations});
      }
  }

  export function IsThereAnInvalidInput(validations){
    return validations.length > 0;
  }

  export function ValidateInput(inputName, value, validationsArray, validations, thisHandle){
    if(undefined !== validationsArray[inputName]){
      if(validationsArray[inputName](value)){
          RemoveInvalidInput(inputName, validations, thisHandle);
      }
      else{
          AddInvalidInput(inputName, validations, thisHandle);
      }
    }
  }

  export function ValidateEmail(string){
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(string);
  }

  export function ValidateStringIdentifier(string){
    let re = /^[a-zA-Z0-9][ a-zA-Z0-9]*$/;
    return re.test(string);
  }

  export function ValidatePhoneNumber(string){
    let re = /^[0-9]{8,11}$/;
    return re.test(string);
  }

  export function ValidateAmount(string){
    return !isNaN(parseFloat(string)) && isFinite(string);
  }

  export function NonEmptyValue(string){
    return undefined !== string && null !== string && '' !== string;
  }
  
  export function RenderEmptyOrUndefinedValue(value){
    if(undefined === value || "" === value){
     return "-";
    }
    else{
     return value;
    }
  }