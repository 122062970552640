import React, { Component } from "react";
import { FormControl, Form, FormGroup, Col, ControlLabel, Panel, Button} from "react-bootstrap";
import { API } from "aws-amplify";
import ReactTable from 'react-table'
import "./Companies.css";
import OverlayLoadingComponent from '../components/OverlayLoadingComponent';
import ErrorAlert from '../components/ErrorAlert'
import {AddInvalidInput, IsThereAnInvalidInput, ValidateInput, ValidateStringIdentifier} from '../utils'


const validationsArray = {
    companiesFormName: ValidateStringIdentifier
}

export default class Companies extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isLoading: false,
        selected: null,
        selectedCompanyId: null,
        companyName: '',
        errorDescription: '',
        showErrorAlert: false,
        companies: [],
        validations: []
      };

      AddInvalidInput("companiesFormName", this.state.validations, this);
    }

    //================================= Lógica de negocio =========================================

    handleCompanyNameChange(event){
        ValidateInput("companiesFormName", event.target.value,validationsArray, this.state.validations, this);
        this.setState({companyName:event.target.value});
    }

    handleSubmit(event) {
        //No quiero que se pueda submitear el formulario
        event.preventDefault();
      }

    handleCreateCompany(event){
        this.createCompany(this.state.companyName);
        ValidateInput("companiesFormName", event.target.value,validationsArray, this.state.validations, this);
        //this.setState({isLoading:!this.state.isLoading});
    }

    handleUpdateCompany(event){
        this.updateCompany(this.state.selectedCompanyId, this.state.companyName);
        ValidateInput("companiesFormName", event.target.value,validationsArray, this.state.validations, this);
    }

    handleDeleteCompany(event){
        this.deleteCompany(this.state.selectedCompanyId);
        ValidateInput("companiesFormName", event.target.value,validationsArray, this.state.validations, this);
    }

    async refreshCompanies() {
        this.queryCompanies();
    }

    //Metodo propio del ciclo de vida del componente, invocado cuando se monta
    async componentDidMount() {
        this.queryCompanies();
    }

    setSelectedRow(rowInfo){
        if(this.state.selected === rowInfo.index){
            this.setState({
                selected: null,
                companyName: '',
                selectedCompanyId: null
              });
              AddInvalidInput("companiesFormName", this.state.validations, this);
        }
        else{
        this.setState({
            selected: rowInfo.index,
            companyName: rowInfo.row._original.companyName,
            selectedCompanyId: rowInfo.row._original.companyId
          });
          ValidateInput("companiesFormName", rowInfo.row._original.companyName,validationsArray, this.state.validations, this);
        }
    }

    //================================= CRUD =========================================
    async queryCompanies() {
        try {
            this.setState({isLoading : true});
            const companies = await API.get("alticaapi", "/companies");
            this.setState({ companies });
          } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
          }
          this.setState({ isLoading: false });
    }

    async createCompany(companyName) {
        let companyInit = {
            body: {"name":companyName}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.post("alticaapi", "/companies", companyInit);
            this.setState({isLoading:false, companyName:''});
            this.refreshCompanies();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async updateCompany(companyId, companyName) {
        let companyInit = {
            body: {"company":companyId, "name":companyName}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.patch("alticaapi", "/companies", companyInit);
            this.setState({isLoading:false, companyName:'', selectedCompanyId: null, selected: null});
            this.refreshCompanies();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    async deleteCompany(companyId) {
        let companyInit = {
            body: {"company":companyId}, // replace this with attributes you need
            headers: {} // OPTIONAL
        }
        let response = null;
        try {
            this.setState({isLoading:true});
            response = await API.del("alticaapi", "/companies", companyInit);
            this.setState({isLoading:false, companyName:'', selectedCompanyId: null, selected: null});
            this.refreshCompanies();
        } catch (e) {
            console.log(e);
            this.setState({showErrorAlert:true, errorDescription: String(e)});
        }
        return response;
    }

    //================================= Métodos de rendering =========================================
    renderCompaniesTable(companies){
        const columns = [{
            id: 'Identificador',
            Header: 'Identificador',
            accessor: 'companyId'
          }, {
            id: 'NombreCompania',
            Header: 'Nombre',
            accessor: 'companyName'
          }];

        return (this.state.isLoading)?
            <div>Cargando el contenido...</div>  
            //<ReactLoading type="spinningBubbles" color="grey" height={30} width={100} />
            :
            (undefined !== companies) && (companies.length > 0)?
                <ReactTable
                data={companies}
                columns={columns}
                showPagination={true}
                defaultPageSize={5}
                getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setSelectedRow(rowInfo);
                        },
                        style: {
                          background: rowInfo.index === this.state.selected ? '#00afec' : 'white',
                          color: rowInfo.index === this.state.selected ? 'white' : 'black'
                        }
                      }
                    }else{
                      return {}
                    }
                  }}
              />
              :
              <div>No se encontraron compañías en la base de datos</div>;
    }


    renderActionButton(){
        if(null !== this.state.selectedCompanyId && undefined !== this.state.selectedCompanyId){
            return <Col sm={12} smOffset={4}>
                <Button name="updateButton" onClick={this.handleUpdateCompany.bind(this)} disabled={IsThereAnInvalidInput(this.state.validations)}>Actualizar compañía</Button> 
                <Button name="deleteButton" onClick={this.handleDeleteCompany.bind(this)}>Eliminar compañía</Button>
                </Col>
        }
        else {
            return <Col sm={12} smOffset={5}>
                <Button name="createButton" onClick={this.handleCreateCompany.bind(this)} disabled={IsThereAnInvalidInput(this.state.validations)} >Crear compañía</Button>
                </Col>;
        }
    }
    renderWarningMessage(){
        if(null !== this.state.selectedCompanyId && undefined !== this.state.selectedCompanyId){
            return  <div style={{textAlign: 'center', color: 'red'}}>
            <p><strong>ADVERTENCIA: </strong> Si elimina una compañía se eliminarán todas las campañas asociadas a la misma.</p>
        </div>
        }
        
    }
    renderErrorAlert(){
        if(this.state.showErrorAlert){
            return <ErrorAlert showErrorAlert={this.state.showErrorAlert} errorDescription={this.state.errorDescription}></ErrorAlert>;
        }
        return '';
    }
     //================================= Métodos de validación ========================================

     validationResult(inputName){
        if(this.state.validations.indexOf(inputName) >= 0){
            return "error";
        }
        return "success";
    }

    renderCompaniesForm(){
        return <div>

            {this.renderErrorAlert()}
            <OverlayLoadingComponent isLoading={this.state.isLoading}>
            
                <Panel>
                    <Panel.Heading>Mantenimiento de compañías</Panel.Heading>
                    <Panel.Body>
                        <Form horizontal onSubmit={this.handleSubmit.bind(this)}>
                            <FormGroup controlId="companiesFormName" validationState={this.validationResult("companiesFormName")}>
                                <Col sm={1}>
                                    <ControlLabel>Nombre</ControlLabel>
                                </Col>
                                <Col sm={11}>
                                    <FormControl type="text" placeholder="Compañía" value={this.state.companyName} onChange={this.handleCompanyNameChange.bind(this)} />
                                </Col>
                            </FormGroup>
                            <hr></hr>
                            <FormGroup controlId="companiesFormActions">
                                {this.renderWarningMessage()}
                                {this.renderActionButton()}
                            </FormGroup>
                        </Form>
            </Panel.Body>
            </Panel>
            <Panel>
                <Panel.Heading>Compañías Existentes</Panel.Heading>
                <Panel.Body>
                    {this.renderCompaniesTable(this.state.companies)}
                </Panel.Body>
            </Panel>
        </OverlayLoadingComponent>
    </div>
    }

    render(){
        return this.renderCompaniesForm();
    }
}