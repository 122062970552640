import React, { Component } from "react";
import LoadingOverlay from 'react-loading-overlay';

export default class OverlayLoadingComponent extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: props.isLoading
        };
      }

      componentDidUpdate(prevProps){
        if(this.props.isLoading !== prevProps.isLoading) {
            this.setState({isLoading: this.props.isLoading});
        }
      }

      render(){
        return <LoadingOverlay
        active={this.state.isLoading}
        spinner
        text='Por favor espere...'
        styles={{
            overlay: (base) => ({
              ...base,
              background: 'rgba(2, 6, 8, 0.3)'
            })
          }}
        >
            {this.props.children}
        </LoadingOverlay>
      }
}