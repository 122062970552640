import React, { Component } from "react";
import { API } from "aws-amplify";
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import ReactLoading from 'react-loading';
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';


export default class SliderPeriods extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          credit: props.credit,
          periods : [],
          parentHandle: props.parentHandle
        };
      }

      handleChange (e) {
        this.props.handler(e);
    }


    getInitialPeriod(periods){ 
      let filtered = [];
      if(undefined !== periods){
        for (const period of periods) {
          if(period.periodDefault === 1){
            filtered.push(period);
          }
        }

        
        if(filtered.length === 0 && periods.length >= 1){
          filtered.push(periods[0]);
        }
        return (1 === filtered.length?filtered[0].period.periodId:null);
      } 
    }

    async componentDidMount() {
      try {
        this.setState({isLoading : true});
        const result = await this.queryPeriods(this.props.credit);
        this.setState({ periods: result });

        let initialPeriod = this.getInitialPeriod(result);

        if (undefined !== initialPeriod && null !== initialPeriod){
          this.props.handler(this.findPeriodById(initialPeriod), initialPeriod);
        }

      } catch (e) {
        alert(e);
      }
      this.setState({ isLoading: false });
    }


      async componentDidUpdate(prevProps, prevState) {
          
          if(this.props.credit !== prevProps.credit && this.props.credit !== prevState.credit) {
            this.setState({credit: this.props.credit})
            const result = await this.queryPeriods(this.props.credit);
            this.setState({periods: result})
            
            let initialPeriod = this.getInitialPeriod(result);

            if (undefined !== initialPeriod && null !== initialPeriod){
              this.props.handler(this.findPeriodById(initialPeriod), initialPeriod);
            }

          }
      } 

      async queryPeriods(credit) {
        AddLoadingComponent(this.state.parentHandle, 'periods');
        this.setState({isLoading : true});
        if(undefined !== credit && "NOTSELECTED" !== credit ) {
           const response = await API.get("alticaapi", "/creditperiods/" + credit);
           this.setState({isLoading : false});
           RemoveLoadingComponent(this.state.parentHandle, 'periods');
           return response;
        }
        else {
          this.setState({isLoading : false});
          RemoveLoadingComponent(this.state.parentHandle, 'periods');
          return [];
        }
        
      }

      findPeriodById(periodId){
        var filtered = []
        for (const period of this.state.periods) {
          if(period.period.periodId === periodId){
            filtered.push(period);
          }
        }
        return (1 === filtered.length?filtered[0]:0);
      }
     

      render(){

        let localMarks = {};
        let minValue = 0;
        let maxValue = 0;
        let receivedDefaultValue = 0;


        for (const period of this.state.periods) {
          if(0 === minValue){
            minValue = period.period.periodId;
          }

          localMarks[0] = 0;
          if(period.period.periodId >= maxValue){
            maxValue = period.period.periodId;
          }
          if(period.periodDefault === 1){
            receivedDefaultValue = period.period.periodId;
          }
          localMarks[period.period.periodId] = period.period.periodValue;
        }

          return (
            (this.state.isLoading)?
              <ReactLoading type="bars" color="grey" height={30} width={70} />
            :
            (undefined !== this.state.periods) && (this.state.periods.length > 0)?
              <Slider
                name="period" 
                min={minValue} 
                max={maxValue} 
                defaultValue={receivedDefaultValue}
                marks={localMarks} 
                step={null}
                
                onAfterChange={(e) => this.props.handler(this.findPeriodById(e), e)} />
              :
              <div>No hay periodos configurados</div>
          );
      }
}