import Modal from 'react-responsive-modal';
import React, { Component } from "react";
import { ModalTitle, ModalHeader, ModalBody, ModalFooter, Button, Grid, Form, FormGroup, FormControl, Row, Col, ControlLabel, Panel, ButtonToolbar, ToggleButtonGroup, ToggleButton,InputGroup} from "react-bootstrap";
import 'rc-slider/assets/index.css';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import { API } from "aws-amplify";
import OverlayLoadingComponent from "../components/OverlayLoadingComponent"
import {RenderPercentageValue} from '../utils';



const columns = [
  {
    Header: 'Periodo',
    accessor: 'periodValue'
  },
  {
    Header: 'Antigüedad',
    accessor: 'age'
  },
  {
    Header: 'Prima',
    accessor: 'premium',
    Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
  },
  {
    Header: 'Combinación',
    accessor: 'combination',
    Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
  }
];


export default class PremiumsByCreditModal extends Component{

    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          show: props.show, 
          periods: props.periods, 
          premiumsByPeriod: [], 
          selectedCredit: props.selectedCredit,
          formFields:[],
          loadingComponents:[],
          selectedRow: null,
          selectedAge: null
        };
        this.state.formFields['premium']=0;
        this.state.formFields['combination']=0;
        
        this.changeInputEventHandler = this.changeInputEventHandler.bind(this);
        this.changePeriodEventHandler = this.changePeriodEventHandler.bind(this);
      }

       

    async componentDidMount() {
    }


    async componentDidUpdate(prevProps, prevState) {

      if(undefined !== prevProps && prevProps.show !== this.props.show){
        this.setState({show:this.props.show});
      }

      if(undefined !== prevState && prevState.periods !== this.props.periods){
        this.setState({periods:this.props.periods});
      }

      if(undefined !== prevState && prevState.selectedCredit !== this.props.selectedCredit){
        this.setState({selectedCredit:this.props.selectedCredit});
      }
    } 

    /**
     * Metodo encargado de actualizar el estado con los datos seleccionados del formulario
     * @param {*} event 
     */
    changeInputEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal[event.target.name] = event.target.value;
      this.setState({formFields: formFieldsLocal});
    }

    async changePeriodEventHandler(event){
      let formFieldsLocal = this.state.formFields;
      formFieldsLocal['period'] = event;
      this.setState({formFields: formFieldsLocal});
      let selectedPeriod = this.state.periods.find(obj => obj.periodId === event);
      await this.loadPremiumsByPeriod(this.state.selectedCredit, selectedPeriod);
    }

    setSelectedRow(rowInfo){
      let localFormFields = this.state.formFields;
      if(this.state.selectedRow === rowInfo.index){
        localFormFields['period'] = '';
        localFormFields['age'] = '';
        localFormFields['premium'] = 0;
        localFormFields['combination'] = 0;
        
        this.setState({
          selectedRow: null,
          formFields: localFormFields
        });
      }
      else{
          localFormFields['period'] = rowInfo.row._original.periodId;
          localFormFields['age'] = rowInfo.row._original.age;
          localFormFields['premium'] = rowInfo.row._original.premium;
          localFormFields['combination'] = rowInfo.row._original.combination;
          
          this.setState({
              selectedRow: rowInfo.index,
              formFields: localFormFields
            });

          }
    }

      
    handleClose(){
      this.props.hidePremiumsByCreditModalHandler();
    }

    handleShow(){
      this.setState({show : true})
    }

    async handlePremiumsUpdate(){

      let post = {};

      post["campaign"] = this.state.selectedCredit;
      post["period"] = this.state.formFields['period'];
      post["age"] = this.state.formFields['age'];
      post["premium"] = this.state.formFields['premium'];
      post["combination"] = this.state.formFields['combination'];
      


      let premiumInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{
        await API.post("alticaapi", "/premiums", premiumInit);
        let selectedPeriod = this.state.periods.find(obj => obj.periodId === this.state.formFields['period']);
        await this.loadPremiumsByPeriod(this.state.selectedCredit, selectedPeriod);

        let localFormFields = this.state.formFields;
        localFormFields['age']='';
        localFormFields['premium']=0;
        localFormFields['combination']=0;
        

        this.setState({formFields:localFormFields});

      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      
    }

    async handlePremiumRemove(){
      let post = {};
      post["campaign"] = this.state.selectedCredit;
      post["period"] = this.state.formFields['period'];
      post["age"] = this.state.formFields['age'];
      let premiumInit = {
        body: post, // replace this with attributes you need
        headers: {} // OPTIONAL
      }

      try{
        await API.del("alticaapi", "/premiums", premiumInit);
        let selectedPeriod = this.state.periods.find(obj => obj.periodId === this.state.formFields['period']);
        await this.loadPremiumsByPeriod(this.state.selectedCredit, selectedPeriod);
        let localFormFields = this.state.formFields;
        localFormFields['period']='';
        localFormFields['age']='';
        localFormFields['premium']=0;
        localFormFields['combination']=0;
        
        this.setState({formFields:localFormFields});

      }
      catch(error){
        if(undefined !== error.response){
          console.log(error.response.data);
          console.log(error.response.status);
        }
      }
      
    }





    comparePeriod( a, b ) {
      if ( a.periodValue < b.periodValue ){
        return -1;
      }
      if ( a.periodValue > b.periodValue ){
        return 1;
      }
      if(a.periodValue === b.periodValue){
          if ( a.age < b.age ){
              return -1;
          }
          if ( a.age > b.age ){
              return 1;
          }
      }
      return 0;
    }

    async loadPremiumsByPeriod(selectedCredit, period){
      let thisHandle = this;
      this.setState({premiumsByPeriod:[]});
      let localPremiums = [];
      thisHandle.addLoadingComponent("periodsByCredit" + period.periodId);
      const result = await API.get("alticaapi", "/premiums/" + selectedCredit + "/" + period.periodId);
      if(undefined !== result && result.length > 0){
          result.forEach(element => {
              let localValue = {};
              localValue['periodId'] = period.periodId;
              localValue['periodValue'] = period.periodValue;
              localValue['premium'] = element.premium;
              localValue['age'] = element.age;
              localValue['combination'] = element.combination;
             
              localPremiums.push(localValue);
          });
          localPremiums.sort(thisHandle.comparePeriod);
          thisHandle.setState({premiumsByPeriod: localPremiums});
        }
        thisHandle.removeLoadingComponent("periodsByCredit" + period.periodId);

  }

    addLoadingComponent(componentName){
      let localComponents = this.state.loadingComponents;
      localComponents.push(componentName);
      this.setState({loadingComponents:localComponents});
    }

    removeLoadingComponent(componentName){
        let localComponents = this.state.loadingComponents;
        let index = localComponents.indexOf(componentName);
        if (index > -1) {
            localComponents.splice(index, 1);
        }
        this.setState({loadingComponents:localComponents});
    }

    isAComponentLoading(){
        return this.state.loadingComponents.length > 0;
    }

    isLoading(component){
        return this.state.loadingComponents.indexOf(component) > -1;
    }

      renderPeriodsList(periods) {
        return [{}].concat(periods).map(
        (period, i) =>
        i !== 0 ?
            <option value={period.periodId}>{period.periodValue}</option>
        :
        <option value="">Seleccione el periodo</option>
        );
        
      }

      renderPeriodsButtons(periods) {
        return periods.map(
        (period, i) =>
                    <ToggleButton key={i} value={period.periodId}>{period.periodValue}</ToggleButton>
        );
        
      }


    renderChargesFinancing(){
          if (this.state.isLoading){
            return <div style={{ textAlign: "center" }}>
              Cargando información ...
            </div>;
          }
          else{
            return <div style={{ textAlign: "center" }}>
              <Modal open={this.state.show} onClose={this.handleClose.bind(this)} center>
                <OverlayLoadingComponent isLoading={this.isAComponentLoading()}>
                <ModalHeader>
                  <ModalTitle>Configuración de primas</ModalTitle>
                </ModalHeader>

                <ModalBody>
                <Panel>
                  <Panel.Body>
                      <Form onSubmit={this.setRedirect}>
                          <FormGroup controlId="formControlsSelect">
                              <Grid>
                              <Row className="show-grid">
                                  <Col sm={7}>
                                    <FormGroup controlId="Plazo"> 
                                      <ControlLabel>Plazo</ControlLabel>
                                      <ButtonToolbar>
                                        <ToggleButtonGroup type="radio" name="options" value={this.state.formFields['period']} onChange={this.changePeriodEventHandler} justified>
                                          {this.renderPeriodsButtons(this.state.periods)}
                                        </ToggleButtonGroup>
                                      </ButtonToolbar>
                                    </FormGroup> 
                                  </Col>
                                </Row>
                                <Row className="show-grid">
                                    <Col sm={7}>
                                      <FormGroup controlId="Antiguedad"> 
                                        <ControlLabel>Antigüedad</ControlLabel>
                                        <FormControl 
                                            inputRef={ el => this.inputEl=el }
                                            name="age" 
                                            componentClass="select" 
                                            placeholder="select"
                                            value={this.state.formFields['age']}
                                            onChange={this.changeInputEventHandler}>
                                              <option value="">Seleccione la Antigüedad</option>
                                              <option value="0">0</option>
                                              <option value="1">1</option>
                                              <option value="2">2</option>
                                              <option value="3">3</option>
                                              <option value="4">4</option>
                                              <option value="5">5</option>
                                        </FormControl>
                                      </FormGroup> 
                                    </Col>
                                 </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Prima</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="premium" 
                                            type="text" 
                                            placeholder="Prima"
                                            value={this.state.formFields['premium']}
                                            onChange={this.changeInputEventHandler}
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}> 
                                      <FormGroup controlId="Plazo"> 
                                        <ControlLabel>Combinación</ControlLabel>
                                        <InputGroup>
                                          <FormControl 
                                            name="combination" 
                                            type="text" 
                                            placeholder="Combinación"
                                            value={this.state.formFields['combination']}
                                            onChange={this.changeInputEventHandler} 
                                            />
                                            <InputGroup.Addon>%</InputGroup.Addon>
                                          </InputGroup>
                                        </FormGroup> 
                                    </Col>
                                  </Row>
                                 
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <hr/>
                                    </Col>
                                    
                                  </Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <div style={{textAlign: 'center'}}>
                                        <Button onClick={this.handlePremiumsUpdate.bind(this)}>Agregar</Button>
                                        <Button onClick={this.handlePremiumRemove.bind(this)}>Eliminar</Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="show-grid">&nbsp;</Row>
                                  <Row className="show-grid">
                                    <Col sm={7}>
                                      <ReactTable
                                        //minRows={0}
                                        defaultPageSize={5}
                                        data={this.state.premiumsByPeriod}
                                        columns={columns}
                                        //pages={0}
                                        showPagination={true}
                                        getTrProps={(state, rowInfo) => {
                                          if (rowInfo && rowInfo.row) {
                                            return {
                                              onClick: (e) => {
                                                this.setSelectedRow(rowInfo);
                                              },
                                              style: {
                                                background: rowInfo.index === this.state.selectedRow ? '#00afec' : 'white',
                                                color: rowInfo.index === this.state.selectedRow ? 'white' : 'black'
                                              }
                                            }
                                          }else{
                                            return {}
                                          }
                                        }}
                                      />
                                    </Col>
                                  </Row>
                              </Grid>
                          </FormGroup>
                      </Form>
                    </Panel.Body>
                  </Panel>
                </ModalBody>

                <ModalFooter>
                  <Button onClick={this.handleClose.bind(this)} variant="primary">Cerrar</Button>
                </ModalFooter>
                </OverlayLoadingComponent>
              </Modal>
            </div>;
          }
      }

      render(){
          return this.renderChargesFinancing();
      }
}