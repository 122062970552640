import React, { Component } from "react";
import { FormControl, Col, ControlLabel, InputGroup, FormGroup } from "react-bootstrap";
import { API } from "aws-amplify";
import { FormatNumber, GetCurrentYear } from '../utils';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';



const SliderWithTooltip = createSliderWithTooltip(Slider);

export default class PremiumInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      credit: props.credit,
      period: props.period,
      year: props.year,
      price: props.price,
      currency: props.currency,
      premium: {},
      premiumValue: 0,
      premiunMinValue: 0,
      residualValue: 0,
      futureValue: 0,
      totalAmount: props.price,
      chargesList: props.chargesList,
      parentHandle: props.parentHandle
    };
  

  }

  getTotalCreditAmount(percentage, baseAmount, chargesList) {
    let localAmount = Number(baseAmount) - (Number(baseAmount) * (Number(percentage) / 100));
    if (undefined !== chargesList && null !== chargesList) {
      localAmount = Number(localAmount) + this.getAplicableChargesSum(chargesList, 2)
    }
    return localAmount;
  }

  getPremiumAmount(percentage, baseAmount, chargesList) {

    let localAmount = (baseAmount * (percentage / 100));
    if (undefined !== chargesList && null !== chargesList) {
      localAmount = localAmount + this.getAplicableChargesSum(chargesList, 1);
    }
    return localAmount;
  }

  getAplicableChargesSum(chargesList, paymentTypeId) {
    let chargesTotalAmount = 0;
    for (let charge in chargesList) {
      if (paymentTypeId === chargesList[charge].paymentTypeId) {
        chargesTotalAmount = chargesTotalAmount + Number(chargesList[charge].amount);
      }
    }
    return chargesTotalAmount;
  }

  formatPercentage(num) {
    return num + '%';
  }

  handleChange(e) {
    this.props.handler(e);
  }

  handlePemiumChange(event) {
    this.setState({ premiumValue: event });
    //this.props.handler(event);
  }

  handlePemiumMouseUp(event) {
    //this.setState({ premiumValue: event });
    let localResidualValue = this.getResidualValue();
    let localFutureValue = this.getFutureValue();
    this.handleResidualValueChange(localResidualValue);
    this.handleFutureValueValueChange(localFutureValue);
    this.props.handler(event);
  }

  handleAmountChange(event, amount) {
    this.props.realAmountHandler(amount);
  }

  handleResidualValueChange(event){
    this.setState({residualValue: event});
    let localFormFields = this.props.parentHandle.state.formFields;
    localFormFields["residualValue"] = event;
    this.props.parentHandle.setState({formFields: localFormFields});
  }

  handleFutureValueValueChange(event){
    this.setState({futureValue: event});
    let localFormFields = this.props.parentHandle.state.formFields;
    localFormFields["futureValue"] = event;
    this.props.parentHandle.setState({formFields: localFormFields});
  }

  handleCombinationChange(event){
    
    let localFormFields = this.props.parentHandle.state.formFields;
    localFormFields["combination"] = event;
    this.props.parentHandle.setState({formFields: localFormFields});
  }


  async componentDidMount() {
    try {
      this.setState({ isLoading: true });
      const result = await this.queryPremiums(this.props.credit, this.props.period, this.props.year)
      this.setState({ premium: result, combination: this.getCombination(), residualValue: this.getResidualValue(), futureValue: this.getFutureValue()});
      this.handleCombinationChange(this.getCombination());
      this.handleResidualValueChange(this.getResidualValue());
      this.handleFutureValueValueChange(this.getFutureValue());
    } catch (e) {
      alert(e);
    }
    this.setState({ isLoading: false });
  }


  async componentDidUpdate(prevProps) {
    if (this.props.credit !== prevProps.credit || this.props.period !== prevProps.period || this.props.year !== prevProps.year) {
      this.setState({ credit: this.props.credit, period: this.props.period, year: this.props.year })
      const result = await this.queryPremiums(this.props.credit, this.props.period, this.props.year);
      this.setState({ premium: result, combination: this.getCombination(), residualValue: this.getResidualValue(), futureValue: this.getFutureValue()})
      this.handleCombinationChange(this.getCombination());
      this.handleResidualValueChange(this.getResidualValue());
      this.handleFutureValueValueChange(this.getFutureValue());
    }
    if (this.props.chargesList !== prevProps.chargesList) {
      this.setState({ chargesList: this.props.chargesList });
      let totalAmount = this.getTotalCreditAmount(this.state.premiumValue, this.state.price, this.props.chargesList);
      this.props.realAmountHandler(totalAmount);
    }
    
  }

  async queryPremiums(credit, period, year) {
    AddLoadingComponent(this.state.parentHandle, 'premiums');
    this.setState({ isLoading: true });
    if (undefined !== credit && "NOTSELECTED" !== credit && undefined !== period) {
      const response = await API.get("alticaapi", "/premiums/" + credit + "/" + period);
      const localPremium = this.getPremiumForSelectedYear(year, response);
      if (undefined !== localPremium && null !== localPremium) {
        this.setState({ isLoading: false, premiunMinValue: localPremium.premium, premiumValue: localPremium.premium });
        RemoveLoadingComponent(this.state.parentHandle, 'premiums');
        this.props.handler(localPremium.premium);
        return localPremium;
      }
      else {
        RemoveLoadingComponent(this.state.parentHandle, 'premiums');
        return [];
      }
    }
    else {
      RemoveLoadingComponent(this.state.parentHandle, 'premiums');
      return [];
    }
  }

 
  getPremiumForSelectedYear(year, premiums) {
    let difference = GetCurrentYear() - year;
    let premium = undefined;
    for (premium of premiums) {
      if (difference === premium.age) {
        return premium;
      }
    }
    return premium;
  }



  renderSection(premium, premiumValue, price, currency, totalAmount) {
    let localMarks = {};
    localMarks[this.state.premiunMinValue] = this.state.premiunMinValue;
    localMarks[100] = 100;
    return (
      <Col xs={12} md={12}>
        <FormGroup>
          <ControlLabel>Seleccione Prima</ControlLabel>
          <SliderWithTooltip
            min={this.state.premiunMinValue}
            max={100}
            step={0.5}
            marks={localMarks}
            value={premiumValue}
            onChange={this.handlePemiumChange.bind(this)}
            onAfterChange={this.handlePemiumMouseUp.bind(this)}
          />
        </FormGroup>
        <FormGroup>
          <FormGroup>
            <Col sm={4} md={4}>
              <ControlLabel>Prima</ControlLabel>
              <InputGroup>
                <FormControl name="premium" type="text" value={FormatNumber(premiumValue)} readOnly />
                <InputGroup.Addon>%</InputGroup.Addon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={4} md={4}>
              <ControlLabel>Monto prima</ControlLabel>
              <InputGroup>
                <FormControl name="premiumAmount" type="text" value={FormatNumber(price)} readOnly />
                <InputGroup.Addon>{currency}</InputGroup.Addon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={4} md={4}>
              <ControlLabel>Monto Crédito</ControlLabel>
              <InputGroup>
                <FormControl name="creditAmount" type="text" value={FormatNumber(totalAmount)} readOnly />
                <InputGroup.Addon>{currency}</InputGroup.Addon>
              </InputGroup>
            </Col>
          </FormGroup>

          <FormGroup>
            <Col sm={4} md={4}>
              <ControlLabel>Combinación</ControlLabel>
              <InputGroup>
                <FormControl 
                  name="combination" 
                  type="number" 
                  value={this.state.premium.combination || '0'} 
                  onChange={this.handleCombinationChange.bind(this)}
                  readOnly />
                
                <InputGroup.Addon>%</InputGroup.Addon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={4} md={4}>
              <ControlLabel>Valor Residual</ControlLabel>
              <InputGroup>
                <FormControl
                  name="residualValue" 
                  type="number" 
                  value={this.state.residualValue || '0'} 
                  onChange={this.handleResidualValueChange.bind(this)}
                  readOnly />
                
                <InputGroup.Addon>%</InputGroup.Addon>
              </InputGroup>
            </Col>
          </FormGroup>
          <FormGroup>
            <Col sm={4} md={4}>
              <ControlLabel>Valor Futuro</ControlLabel>
              <InputGroup>
                <FormControl
                  name="futureValue" 
                  type="text" 
                  value={FormatNumber(this.state.futureValue) || '0'} 
                  onChange={this.handleFutureValueValueChange.bind(this)}
                  readOnly />
                <InputGroup.Addon>{currency}</InputGroup.Addon>
              </InputGroup>
            </Col>
          </FormGroup>
        </FormGroup>
      </Col>
    );
  }

  getCombination() {
    if (undefined !== this.state.premium && undefined !== this.state.premium.combination) {
      return this.state.premium.combination;
    }
    else {
      return 0;
   }
  }

  getResidualValue() {
    let residual = 0;
    if (undefined !== this.state.premium && undefined !== this.state.premium.combination) {
      residual = (this.state.premium.combination - this.state.premiumValue) >= 0 ? (this.state.premium.combination - this.state.premiumValue) : 0;
    }
    return residual;
   
  }

  getFutureValue() {
    let residual = 0;
   
    if (undefined !== this.state.premium && undefined !== this.state.premium.combination) {
      residual = (this.state.premium.combination - this.state.premiumValue) >= 0 ? (this.state.premium.combination - this.state.premiumValue) : 0;
    }
 
    return (residual/100)*this.state.totalAmount;
    
  }

  renderPremium() {
    if (this.state.isLoading) {
      return <div>Cargando información de primas</div>;
    }
    else {
      if (undefined !== this.state.premiumValue) {
        return (
          this.renderSection(
            this.state.premium,
            this.state.premiumValue,
            this.getPremiumAmount(this.state.premiumValue, this.state.price, this.state.chargesList),
            //(this.state.price*(this.state.premiumValue/100)), 
            this.state.currency,
            this.getTotalCreditAmount(this.state.premiumValue, this.state.totalAmount, this.state.chargesList))
        );
      }
      else {
        return (
          //this.renderSection(100, this.state.price)
          <div>Seleccione una campaña para calcular la prima</div>

        );
      }
    }
  }


  render() {
    return (
      <div>{this.renderPremium()}</div>
    );
  }
}