import React from "react";
import { Route, Switch } from "react-router-dom";
import Home from "./containers/Home";
import Campaign from "./containers/Campaign";
import Companies from "./containers/Companies";
import Assets from  "./containers/Assets";
import Products from  "./containers/Products";
import Periods from  "./containers/Periods";
import AssetConditions from  "./containers/AssetConditions";
import Currencies from  "./containers/Currencies";
import Charges from  "./containers/Charges";
import BaseRates from  "./containers/BaseRates";
import NotFound from "./containers/NotFound";
import CampaingMaintenance from "./containers/CampaingMaintenance"

export default () =>
  <Switch>
    <Route path="/index.html" exact component={Home} />
    <Route path="/" exact component={Home} />
    <Route path="/campaign" exact component={Campaign} />
    <Route path="/companies" exact component={Companies} />
    <Route path="/assets" exact component={Assets} />
    <Route path="/assetConditions" exact component={AssetConditions} />
    <Route path="/currencies" exact component={Currencies} />
    <Route path="/products" exact component={Products} />
    <Route path="/periods" exact component={Periods} />
    <Route path="/charges" exact component={Charges} />
    <Route path="/baserates" exact component={BaseRates} />
    <Route path="/campaignmaintenance" exact component={CampaingMaintenance} />
    { /* Finally, catch all unmatched routes */ }
    <Route component={NotFound} />
  </Switch>;