import React, { Component } from "react";
import { FormControl} from "react-bootstrap";
import { API } from "aws-amplify";
import "./ComboProducts.css";
import {AddLoadingComponent, RemoveLoadingComponent} from '../loadingutils';


export default class ComboAssetCondition extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          company: props.companyId,
          assetId: props.assetId,
          assetCondition: props.assetCondition,
          products: [], 
          parentHandle: props.parentHandle
        };
      }

      handleChange (e) {
        this.props.handler(e);
    }

      async componentDidUpdate(prevProps) {
          if((this.props.companyId !== prevProps.companyId) || (this.props.assetId !== prevProps.assetId) || (this.props.assetCondition !== prevProps.assetCondition)) {
            AddLoadingComponent(this.state.parentHandle, 'products');
            this.setState({company: this.props.companyId, assetId: this.props.assetId, assetCondition: this.props.assetCondition})
            const result = await this.queryProducts(this.props.companyId, this.props.assetId, this.props.assetCondition);
            this.setState({products: result});
            RemoveLoadingComponent(this.state.parentHandle, 'products');
          }
      } 

      async queryProducts(companyId, assetId, assetCondition) {
        AddLoadingComponent(this.state.parentHandle, 'products');
        this.setState({isLoading : true});
        if(undefined !== companyId && undefined !== assetId && undefined !== assetCondition) {
          const response = await API.get("alticaapi", "/products/" + companyId + "/" + assetId + "/" + assetCondition);
          this.setState({isLoading : false});
          RemoveLoadingComponent(this.state.parentHandle, 'products');
          return response;
        }
        else {
          RemoveLoadingComponent(this.state.parentHandle, 'products');
          return [];
        }
      }

      renderProducts(products) {
        if(this.state.isLoading){
            return <option value="">Cargando datos ...</option>;
        }
        else{
            return [{}].concat(products).map(
            (product, i) =>
            i !== 0 ?  
              <option value={product.productId}>{product.productDescription}</option>
            :
            <option value="">Seleccione el producto</option>
            );
        }
      }

      render(){
          return (
                
                <FormControl
                    name="product"
                    inputRef={ el => this.inputEl=el }
                    componentClass="select" placeholder="select"  onChange={this.handleChange.bind(this)}>
                    {this.renderProducts(this.state.products)}
                </FormControl>
                
          );
      }
}