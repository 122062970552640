import React, { Component } from "react";
import { API } from "aws-amplify";
import 'rc-slider/assets/index.css';
import ReactLoading from 'react-loading';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Tooltip from 'react-tooltip-lite';
import "./CreditDistribution.css";
import {RenderPercentageValue, FormatNumber} from '../utils';

export default class CreditDistribution extends Component{
    constructor(props) {
        super(props);
        this.state = {
          isLoading: false,
          credit: props.credit,
          period: props.period,
          price: props.price,
          premium: props.premium,
          distributions : []
        };
      }


    async componentDidMount() {
      try {
        this.setState({isLoading : true, distributions: []});
        const result = await this.queryCreditDistributions(this.props.credit, this.props.period, this.props.price, this.props.premium);
        this.setState({ distributions: result });
      } catch (e) {
        console.log(e);
      }
      this.setState({ isLoading: false });
    }


      async componentDidUpdate(prevProps) {
          if(this.props.credit !== prevProps.credit || this.props.period !== prevProps.period || this.props.price !== prevProps.price || this.props.premium !== prevProps.premium) {
            this.setState({credit: this.props.credit, period: this.props.period, price: this.props.price, premium: this.props.premium, distributions: []})
            const result = await this.queryCreditDistributions(this.props.credit, this.props.period, this.props.price, this.props.premium);
            let localFormFields = this.props.parentHandle.state.formFields;
            localFormFields["creditDistribution"] = result;
            this.props.parentHandle.setState({formFields: localFormFields});
            this.setState({distributions: result});
          }
      } 

      async queryCreditDistributions(credit, period, price, premium) {
        this.setState({isLoading : true});
        if(undefined !== credit && "NOTSELECTED" !== credit && undefined !== period && undefined !== price && undefined !== premium) {
           const response = await API.get("alticaapi", "/creditdistribution/" + credit + "/" + period + "/" + price + "/" + premium);
           let localFormFields = this.props.parentHandle.state.formFields;
           localFormFields["creditDistribution"] = response;
           this.props.parentHandle.setState({formFields: localFormFields});
           this.setState({isLoading : false});
           return response;
        }
        else {
          this.setState({isLoading : false});
          return [];
        }
        
      }

     
      renderBaseRateTypes(row){
        return row.row.baseRate.baseRateVariable === 1?
              <div style={{ textAlign: "center" }}>Variable</div>
              :
              <div style={{ textAlign: "center" }}>Fija</div>
      }

      render(){
          const columns = [{
            Header: 'Mes inicial',
            accessor: 'initialMonth',
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          }, {
            Header: 'Mes final',
            accessor: 'finalMonth',
            Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
          }, {
            Header: 'TasaBase',
            accessor: 'baseRate',
            Cell: row => {
              return (
                (undefined !== row.row.baseRate) ? 
                    <div style={{ textAlign: "center" }}>
                      <Tooltip content={ RenderPercentageValue(row.row.baseRate.baseRatePercentage)}>
                        <span className="class-for-name">{row.row.baseRate.baseRateDescription}</span>
                      </Tooltip>
                </div>
                  :
                  <div style={{ textAlign: "center" }}>
                      <span className="class-for-name">-</span>
                </div>
              )
            }}, {
            Header: 'Spread',
            accessor: 'spread',
            Cell: row => <div style={{ textAlign: "center" }}>{RenderPercentageValue(row.value)}</div>
          }, {
            Header: 'Cuota base',
            accessor: 'fee',
            Cell: row => <div style={{ textAlign: "center" }}>{FormatNumber(row.value)}</div>
          }, {
            Header: 'Tipo de tasa',
            accessor: 'baseRate',
            Cell: row => {
              return (
                undefined !== row.row.baseRate ?
                  this.renderBaseRateTypes(row)
                :
                  <div style={{ textAlign: "center"}}>-</div> 
              )
            }}];
        
          return (
            (this.state.isLoading)?
              <ReactLoading type="bars" color="grey" height={30} width={100} />
            :
            (undefined !== this.state.distributions) && (this.state.distributions.length > 0)?
                <ReactTable
                data={this.state.distributions}
                columns={columns}
                showPagination={false}
                defaultPageSize={this.state.distributions.length}
              />
              :
              <div>No hay opciones de distribución de crédito para los valores seleccionados</div>
          );
      }
}